import React from "react";
import PropTypes from "prop-types";
import AdvancedSelectInput from "components/shared/Inputs/AdvancedSelectInput";

import localize from "lang/localize";

const propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  language: PropTypes.string,
};

const CategorySelectInput = (props) => {
  let name = props.name;
  let placeholder = props.placeholder;

  let placeholderOption = {
    value: 0,
    label: localize("category_all_text", props.language),
  };

  let defaultOption = placeholderOption;

  let options = props.options.map((opt) => ({
    value: opt.id,
    label: opt.title,
  }));

  if (props.selectedId) {
    for (const option of options) {
      if (parseInt(option.value) === parseInt(props.selectedId)) {
        defaultOption = option;
        break;
      }
    }
  }

  options = [placeholderOption, ...options];

  /*
   Since filtering is not done in the same page (i.e, directed to new page),
   it may not be appropriate to prepend with "All".
   By prepending "All", we will get a URL whereby the category id is 0,
   e.g. /project/<PROJECT_ID>/category/0
   */

  return (
    <AdvancedSelectInput
      name={name}
      className="fullwidth"
      defaultOption={defaultOption}
      options={options}
      onChange={props.onChange}
      placeholder={placeholder}
      height={200}
      isPopup={true}
    />
  );
};

CategorySelectInput.propTypes = propTypes;

export default CategorySelectInput;
