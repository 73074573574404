import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import Modal from "react-modal";

import AchievementsSlider from "components/shared/Achievements/AchievementsSlider/AchievementsSlider";
import BasicShareDialog from "components/shared/Dialogs/ShareDialog/BasicShareDialog";

import "library/font-awesome/all.min.css";
import "./ChallengeCompletedModal.css";
import QuizGrid from "./../QuizGrid";

import plus_points from "images/core/challenge/icon_plus_points.png";
import correct_points from "images/core/challenge/icon_quiz_right.png";
import wrong_points from "images/core/challenge/icon_quiz_wrong.png";
import prediction_points from "images/core/challenge/icon_prediction_complete.png";
import icon_information from "images/core/challenge/icon_information.png";
import icon_unlocked from "images/core/challenge/icon_unlocked.png";

import localize from "lang/localize";

import urlParse from "library/js/url";

import {
  HIDE_PERSONALITY_QUIZ_COMPLETION_FEEDBACK,
  JUMP_TO_NEXT_FOR_PERSONALITY_QUIZ,
} from "config";

const propTypes = {
  challengeCompletedArr: PropTypes.array.isRequired,
  showModal: PropTypes.bool.isRequired,
  isShareDialogOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleOpenShareDialog: PropTypes.func.isRequired,
  handleCloseShareDialog: PropTypes.func.isRequired,
  goToNextChallenge: PropTypes.func.isRequired,
  goToChallengeAFriend: PropTypes.func.isRequired,
  language: PropTypes.string,
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

/**
 * Display Challenge Feedback Popup
 */
class ChallengeCompletedModal extends Component {
  /**
   * Add anchors to text
   *
   * @param {string} text
   */
  addAnchors(text) {
    // add anchor tags to links
    return urlParse(text, true);
  }

  /**
   * Render the close button for the popup
   *
   * @param {number} lastChallengeCompletedId
   */
  renderCloseButton(challenge, lastChallengeCompletedId) {
    /* Wrap button in a <div> to avoid being flex-ed */
    return (
      <div>
        <button
          onClick={() => {
            this.props.handleCloseModal(lastChallengeCompletedId);
            /*
              If there are unlocked items, completion message, personality quiz results, etc,
              completion feedback will show even when HIDE_PERSONALITY_QUIZ_COMPLETION_FEEDBACK=true.
              Hence, the user will expect the next challenge/topic to be shown after closing the popup.
            */
            if (
              HIDE_PERSONALITY_QUIZ_COMPLETION_FEEDBACK &&
              JUMP_TO_NEXT_FOR_PERSONALITY_QUIZ &&
              challenge.challengeTypeId === 14 // personality quiz challenge
            ) {
              if (challenge.topicIdNext) {
                this.props.goToNextTopic(
                  challenge.topicIdNext,
                  lastChallengeCompletedId,
                );
              } else if (challenge.nextChallengeId) {
                this.props.goToNextChallenge(
                  challenge.nextChallengeId,
                  lastChallengeCompletedId,
                );
              }
            }
          }}
          className="close-button"
        >
          <i className="fa close-icon" aria-hidden="true" />
        </button>
      </div>
    );
  }

  /**
   * Render points earned
   *
   * @param {object} challenge
   * @param {number} challengeTypeId
   */
  renderPointsWrapper(challenge, challengeTypeId) {
    let pointsExist = challenge.points && challenge.points > 0;

    return (
      <div className="modal-contentblock">
        <div
          className={
            "flex align-items-center" +
            (pointsExist ? " justify-content-space-between" : "")
          }
        >
          <div className="flex align-items-center">
            {this.renderPointsFlavor(challenge, challengeTypeId)}
          </div>
          {this.renderPointsText(challenge.points, pointsExist)}
        </div>
      </div>
    );
  }

  /**
   * Render points earned with flavour (correct/wrong)
   *
   * @param {object} challenge
   * @param {number} challengeTypeId
   */
  renderPointsFlavor(challenge, challengeTypeId) {
    switch (challengeTypeId) {
      case 2:
      case 10: // quiz and repeatable quiz
        if (challenge.correctAnswer) {
          return (
            <Fragment>
              <div className="challenge-completion-image-container flex align-items-center">
                <img
                  src={correct_points}
                  alt={localize(
                    "feedback_quiz_right_text",
                    this.props.language,
                  )}
                />
              </div>
              <div className="challenge-completion-container">
                {localize("feedback_quiz_right_text", this.props.language)}
              </div>
            </Fragment>
          );
        } else {
          return (
            <Fragment>
              <div className="challenge-completion-image-container flex align-items-center">
                <img
                  src={wrong_points}
                  alt={localize(
                    "feedback_quiz_wrong_text",
                    this.props.language,
                  )}
                />
              </div>
              <div className="challenge-completion-container">
                {localize("feedback_quiz_wrong_text", this.props.language)}
              </div>
            </Fragment>
          );
        }

      case 3: // prediction
        return (
          <Fragment>
            <div className="challenge-completion-image-container flex align-items-center">
              <img
                src={prediction_points}
                alt={localize("feedback_prediction_text", this.props.language)}
              />
            </div>
            <div className="challenge-completion-container">
              {localize("feedback_prediction_text", this.props.language)}
            </div>
          </Fragment>
        );

      default:
        return (
          <Fragment>
            <div className="challenge-completion-image-container flex align-items-center">
              <img
                src={plus_points}
                alt={localize("feedback_default_text", this.props.language)}
              />
            </div>
            <div className="challenge-completion-container">
              {localize("feedback_default_text", this.props.language)}
            </div>
          </Fragment>
        );
    }
  }

  /**
   * Render unlocked content
   *
   * @param {object} challenge
   * @param {int} lastChallengeCompletedId
   */
  renderUnlocked(challenge, lastChallengeCompletedId) {
    if (challenge.unlockedChallenge || challenge.unlockedGame) {
      let unlockedHeader = localize(
        "feedback_unlock_new_content",
        this.props.language,
      );

      let unlockedContentList = "";
      let unlockedTopicList = "";

      if (challenge.unlockedChallenges != null) {
        unlockedContentList = (
          <div>
            {challenge.unlockedChallenges.map((content, index) => (
              <button
                className="button fullwidth"
                onClick={() => {
                  this.props.goToNextChallenge(
                    content.id,
                    lastChallengeCompletedId,
                  );
                }}
              >
                {content.title}
              </button>
            ))}
          </div>
        );
      }

      if (challenge.topicsUnlocked != null) {
        unlockedTopicList = (
          <div>
            {challenge.topicsUnlocked.map((content, index) => (
              <button
                className="button fullwidth"
                onClick={() => {
                  this.props.goToNextTopic(
                    content.id,
                    lastChallengeCompletedId,
                  );
                }}
              >
                {content.topicTitle}
              </button>
            ))}
          </div>
        );
      }

      return (
        <Fragment>
          <div className="modal-contentblock">
            <div className="flex align-items-center">
              <div className="challenge-completion-image-container flex align-items-center">
                {/* Unlock icon */}
                <img
                  src={icon_unlocked}
                  alt={localize("feedback_default_text", this.props.language)}
                />
              </div>
              <div className="challenge-completion-container">
                <p>
                  <strong>{unlockedHeader}</strong>
                </p>

                {unlockedContentList}
                {unlockedTopicList}
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return null;
    }
  }

  /**
   * Render points noun
   *
   * @param {number} points
   * @param {boolean} pointsExist
   */
  renderPointsText(points, pointsExist) {
    /* points does not exist, or points is 0 */
    if (pointsExist) {
      return (
        <div className="challenge-completion-container norightmargin">
          <strong>{"+" + points.abbreviateNumber()}</strong>
        </div>
      );
    } else {
      return null;
    }
  }

  /**
   * Render correct answer
   *
   * @param {object} challenge
   */
  renderCorrectAnswer(challenge) {
    if (
      typeof challenge.correctAnswerTitle === "string" &&
      challenge.correctAnswer === false
    ) {
      if (challenge.gridWidth === undefined || challenge.gridWidth === 0) {
        return this.renderCorrectAnswerText(challenge);
      } else {
        // If options are not available for rendering, use text instead.
        if (challenge.quizOptions !== undefined) {
          return this.renderCorrectAnswerGrid(challenge);
        } else {
          return this.renderCorrectAnswerText(challenge);
        }
      }
    } else {
      return null;
    }
  }

  /**
   * Render correct answer in text
   *
   * @param {object} challenge
   */
  renderCorrectAnswerText(challenge) {
    return (
      <div className="modal-contentblock">
        <div className="flex align-items-center">
          {localize("feedback_right_answer_text", this.props.language).format(
            challenge.correctAnswerTitle,
          )}
        </div>
      </div>
    );
  }

  /**
   * Render correct answer grid
   *
   * @param {object} challenge
   */
  renderCorrectAnswerGrid(challenge) {
    return (
      <div className="modal-contentblock">
        <QuizGrid
          gridWidth={challenge.gridWidth}
          quizOptions={challenge.quizOptions}
        />
      </div>
    );
  }

  /**
   * Render completion message
   *
   * @param {object} challenge
   */
  renderCompletionMessage(challenge) {
    let completionMessageAnchored = this.addAnchors(
      challenge.completionMessage,
    );

    return (
      <div className="modal-contentblock">
        <div className="flex align-items-center">
          <div className="challenge-completion-image-container flex align-items-center">
            <img src={icon_information} alt={challenge.completionMessage} />
          </div>
          <div
            className="challenge-completion-container responsive-img-description norightmargin"
            dangerouslySetInnerHTML={{ __html: completionMessageAnchored }}
          />
        </div>
      </div>
    );
  }

  /**
   * Render personality quiz result
   * (For personality quiz results only)
   *
   * @param {object} challenge
   * @returns
   */
  renderPersonality(challenge) {
    let tag = challenge.tag ? challenge.tag.tag : "";
    let tagImage = challenge.tag ? challenge.tag.image : "";
    let tagDescription = challenge.tag ? challenge.tag.description : "";

    return (
      <div className="modal-contentblock textcenter">
        <div className="personality-wrapper">
          {challenge.tag.tag && (
            <div>
              <h3>{localize("tag_result_text", this.props.language) + ": "}</h3>
              <h4>
                <span>{tag}</span>
              </h4>
            </div>
          )}
          {challenge.tag.image && (
            <p>
              <img src={tagImage} alt={tag} />
            </p>
          )}
          {challenge.tag.description && <p>{tagDescription}</p>}

          {challenge.tag.tagShareLink && (
            <p>
              <button
                className="button cta automargin"
                onClick={this.props.handleOpenShareDialog}
              >
                <span className="fas fa-share-alt rightmargin-10"></span>{" "}
                {localize("share_text", this.props.language)}
              </button>
            </p>
          )}
          {challenge.tag.tagShareLink &&
            this.props.isShareDialogOpen &&
            this.renderSharePersonalityDialog(challenge)}

          {/* Download result image if available */}
          {challenge.tag.image && (
            <p>
              <a
                href={tagImage}
                target="_blank"
                className="button link active inline"
                rel="noreferrer"
                download
              >
                <span className="fas fa-download rightmargin-10"></span>{" "}
                {localize("button_download", this.props.language)}
              </a>
            </p>
          )}
        </div>
      </div>
    );
  }

  /**
   * Render Share Dialog for Personality Quiz Result
   */
  renderSharePersonalityDialog(challenge) {
    // Sharable link is from API
    const sharableLink = challenge.tag.tagShareLink;
    const personalityImage = challenge.tag ? challenge.tag.image : "";

    return (
      <BasicShareDialog
        showDialog={this.props.isShareDialogOpen}
        handleCloseDialog={this.props.handleCloseShareDialog}
        sharableLink={sharableLink}
        imageUrl={personalityImage}
        language={this.props.language}
      />
    );
  }

  /**
   * Render points icon
   *
   * @param {object} challenge
   * @param {number} challengeTypeId
   */
  renderPointsImg(challenge, challengeTypeId) {
    switch (challengeTypeId) {
      case 2:
      case 10: // quiz and repeatable quiz
        if (challenge.correctAnswer) {
          return <img src={correct_points} alt="" />;
        } else {
          return <img src={wrong_points} alt="" />;
        }

      case 3: // prediction
        return <img src={prediction_points} alt="" />;

      default:
        return <img src={plus_points} alt="" />;
    }
  }

  /**
   * Render achievements earned
   *
   * @param {object} challenge
   */
  renderAchievements(challenge) {
    return (
      <div className="modal-contentblock textcenter">
        <h4>
          {localize("feedback_unlock_start", this.props.language) +
            challenge.items.length +
            " " +
            challenge.items.length.localize(
              "noun_achievement",
              "noun_achievements",
              this.props.language,
              false,
            )}
        </h4>
        <div className="achievements-container">
          <AchievementsSlider achievements={challenge.items} />
        </div>
      </div>
    );
  }

  /**
   * Render rewards unlocked
   *
   * @param {object} unlockedRewards
   */
  renderUnlockedRewards(unlockedRewards) {
    return (
      <div className="modal-contentblock textcenter">
        <h4>
          {localize("feedback_unlock_start", this.props.language) +
            unlockedRewards.length +
            " " +
            unlockedRewards.length.localize(
              "noun_reward",
              "noun_rewards",
              this.props.language,
              false,
            )}
        </h4>
        <div className="achievements-container">
          <AchievementsSlider achievements={unlockedRewards} />
        </div>
      </div>
    );
  }

  /**
   * Render CTA buttons
   */
  renderMainCTAButtons(
    challenge,
    nextChallengeId,
    lastChallengeCompletedId,
    topicIdNext,
  ) {
    if (
      challenge.allowRepeatCompletionNow === true &&
      challenge.correctAnswer === false
    ) {
      /* Render "Try Again" button */
      return (
        <div className="modal-close-container pure-g">
          <div className="pure-u-1-1 pure-u-md-1-3">
            <button
              className="button cta fullwidth nomargin topic-next-button"
              onClick={() => {
                window.location.reload(true);
              }}
            >
              {localize("button_try_again", this.props.language)}
            </button>
          </div>
        </div>
      );
    } else {
      if (topicIdNext) {
        /* Render "Next Topic" button */
        return (
          <div className="modal-close-container pure-g">
            {this.renderChallengeAFriendButtonContainer(
              challenge,
              lastChallengeCompletedId,
            )}
            <div className="pure-u-1-1 pure-u-md-1-3">
              <button
                className="button cta fullwidth nomargin topic-next-button"
                onClick={() => {
                  this.props.goToNextTopic(
                    topicIdNext,
                    lastChallengeCompletedId,
                  );
                }}
              >
                {localize("button_next_topic", this.props.language)}
              </button>
            </div>
          </div>
        );
      } else if (nextChallengeId) {
        /* Render "Next Challenge" button */
        return (
          <div className="modal-close-container pure-g">
            {this.renderChallengeAFriendButtonContainer(
              challenge,
              lastChallengeCompletedId,
            )}
            <div className="pure-u-1-1 pure-u-md-1-3">
              <button
                className="button cta fullwidth nomargin"
                onClick={() => {
                  this.props.goToNextChallenge(
                    nextChallengeId,
                    lastChallengeCompletedId,
                  );
                }}
                id="goToNextChallengeButton"
              >
                {localize(
                  "button_feedback_view_next_challenge",
                  this.props.language,
                )}
              </button>
            </div>
          </div>
        );
      } else {
        /* everything else - just view challenge & challenge refer button */
        return (
          <div className="modal-close-container">
            {this.renderChallengeAFriendButtonContainer(
              challenge,
              lastChallengeCompletedId,
            )}
          </div>
        );
      }
    }
  }

  /**
   * Render "challenge a friend" section
   *
   * @param {object} challenge
   * @param {number} lastChallengeCompletedId
   */
  renderChallengeAFriendButtonContainer(challenge, lastChallengeCompletedId) {
    if (challenge.challengeReferralLink) {
      return (
        <div className="pure-u-1-1 pure-u-md-1-3">
          <button
            className="button cta fullwidth"
            onClick={() => {
              this.props.goToChallengeAFriend(
                challenge.challengeId,
                lastChallengeCompletedId,
              );
            }}
          >
            {localize("challenge_referral_text", this.props.language)}
          </button>
        </div>
      );
    } else {
      return null;
    }
  }

  /**
   * Render the view
   */
  render() {
    if (
      this.props.challengeCompletedArr.length > 0 &&
      this.props.challengeCompletedArr[0].challengeCompleted
    ) {
      /* Initialising variables */
      const lastChallengeCompletedId = this.props.challengeCompletedArr[0].id;
      const challenge = this.props.challengeCompletedArr[0].challengeCompleted;
      const challengeTypeId = challenge.challengeTypeId;
      const nextChallengeId = challenge.nextChallengeId;
      const topicIdNext = challenge.topicIdNext;
      let showDialog = this.props.showModal;

      /**
       * Hide completion feedback if configured for personality quiz and there is nothing to show.
       *
       * Completion feedback should show under these conditions:
       * 1) Not personality quiz
       * 2) Completion message is available
       * 3) Personality quiz result is returned
       * 4) Achievement is unlocked
       * 5) Next topic is returned
       */
      if (
        HIDE_PERSONALITY_QUIZ_COMPLETION_FEEDBACK &&
        challengeTypeId === 14 && // personality quiz challenge
        !challenge.completionMessage && // has completion message
        !(challenge.items && challenge.items.length) && // unlocked achievements
        !challenge.tag && // personality quiz result
        !topicIdNext // next topic
      ) {
        if (nextChallengeId) {
          // Jump to next challenge immediately if configured
          if (JUMP_TO_NEXT_FOR_PERSONALITY_QUIZ) {
            this.props.goToNextChallenge(
              nextChallengeId,
              lastChallengeCompletedId,
            );
            showDialog = false;
          }
        }

        /*
         * Other Conditions:
         * 1) End of topic - Stay in same page
         * 2) Next challenge not available (unexpected error) - Stay in same page
         */
        showDialog = false;
      }

      return (
        <div>
          <Modal
            isOpen={showDialog}
            contentLabel={localize(
              "feedback_default_text",
              this.props.language,
            )}
            onRequestClose={() => {
              this.props.handleCloseModal(lastChallengeCompletedId);
            }}
            shouldCloseOnOverlayClick={false}
            className="modal-content challenge-completed-modal-content"
            overlayClassName="modal-overlay"
          >
            {this.renderCloseButton(challenge, lastChallengeCompletedId)}

            <div className="modal-body">
              {this.renderPointsWrapper(challenge, challengeTypeId)}

              {challenge.completionMessage &&
                this.renderCompletionMessage(challenge)}

              {this.renderCorrectAnswer(challenge)}

              {challenge.tag && this.renderPersonality(challenge)}

              {this.renderUnlocked(challenge, lastChallengeCompletedId)}

              {challenge.items &&
                challenge.items.length >= 1 &&
                this.renderAchievements(challenge)}

              {challenge.rewardsUnlocked &&
                challenge.rewardsUnlocked.length >= 1 &&
                this.renderUnlockedRewards(challenge.rewardsUnlocked)}
            </div>

            {this.renderMainCTAButtons(
              challenge,
              nextChallengeId,
              lastChallengeCompletedId,
              topicIdNext,
            )}
          </Modal>
        </div>
      );
    } else {
      return null;
    }
  }
}

ChallengeCompletedModal.propTypes = propTypes;

export default ChallengeCompletedModal;
