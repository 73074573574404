import React, { Component } from "react";
import PropTypes from "prop-types";

import "./TourSlider.css";

import Slider from "react-slick";

const propTypes = {
  tourScreens: PropTypes.array,
  currentSlideNo: PropTypes.number,
  completedTour: PropTypes.bool,
  apiCallComplete: PropTypes.bool,
  setCompletedTour: PropTypes.func.isRequired,
  setSlide: PropTypes.func.isRequired,
  handleCloseClick: PropTypes.func.isRequired,
  language: PropTypes.string,
};

/**
 * Renders the Tour Slider
 *
 * 1. If API support for tour screens is available (i.e. tourScreens is not null), tour screens will load.
 * 2. Otherwise, tour will simply redirect to the main page.
 * 3. To support OLDER tour screens (that are part of project folder), implement a custom component - refer to line 107 onwards.
 */
class TourSlider extends Component {
  /**
   * Constructor - called before the component is mounted
   */
  constructor() {
    super();
    this.slidesLength = 0;
  }

  /**
   * Handles any updates to the interface with changes in app state
   */
  componentDidUpdate() {
    // Close modal/redirect to main screen if API call has completed and there are still no tour screens
    if (this.props.apiCallComplete && this.slidesLength === 0) {
      this.props.handleCloseClick();
    }
  }

  /**
   * Define settings for tour screens slider
   */
  getSliderSettings() {
    const SETTINGS = {
      dots: true,
      adaptiveHeight: false,
      infinite: false,
      speed: 500,
      swipeToSlide: true,
      className: "tour-slider",
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
      afterChange: (index) => {
        this.props.setCompletedTour(
          index >= this.slidesLength - 1 && !this.props.completedTour,
        );
        // Use a different state to determine CTA button to show!
        // Do not use this.props.completedTour for showing/hiding CTA buttons
        this.props.setSlide(index >= this.slidesLength - 1);
      },
    };

    return SETTINGS;
  }

  /**
   * Render tour screens slider
   */
  render() {
    const SETTINGS = this.getSliderSettings();

    if (this.props.tourScreens !== null) {
      this.slidesLength = this.props.tourScreens.length;

      /* Tour screens available from API */
      return (
        <Slider {...SETTINGS}>
          {/* Iterates through the list of tour screens and renders it */}
          {this.props.tourScreens.map((tour) => (
            <div key={tour.order}>
              <div className="pure-g">
                <div className="pure-u-1-1">
                  {/* Mobile version */}
                  <div className="hide-from-md">
                    <img
                      src={tour.tourscreenImageMobile}
                      alt=""
                      className="imgresponsive"
                    />
                  </div>

                  {/* Desktop version */}
                  <div className="hide-below-md">
                    <img
                      src={tour.tourscreenImageDesktop}
                      alt=""
                      className="imgresponsive"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      );
    } else {
      return null;
    }
  }
}

/**
 * Support for older tour screens
 *
 * Customization note for apps:
 * 1. Override render() in a custom component (Copy and paste render() sample below.)
 * 2. Implement renderLegacyTourScreens(slider, settings) in a custom component to support older apps. (Sample code below.)
 */
/*
render() {
  const SETTINGS = this.getSliderSettings();

  /// No API support for tour screens yet, fall back to legacy tour screens implementation
  return renderLegacyTourScreens(this, SETTINGS);
}

function renderLegacyTourScreens(slider, settings) {
  // --- Code example to render legacy tour screens ---
  // Define the number of slides here
  slider.slidesLength = 5;

  // Copy all code from <Slider> </Slider> from legacy implementations into here:
  // Ensure there are no traces of this.props.projectId because tour screens are now app-centric
  return (
    <Slider {...settings}>
      <div>
        <div className="pure-g">
          <div className="pure-u-1-12" />
          <div className="pure-u-5-6">
            <h3 className="textcenter">
              {"Discover games"}
            </h3>
            <p className="textcenter">
              Choose from our handpicked featured games ranging from food
              discovery, travel to education.
            </p>
          </div>
          <div className="pure-u-1-12" />
        </div>
        <div className="pure-g">
          <div className="pure-u-1-4 pure-u-md-1-3" />
          <div className="pure-u-1-2 pure-u-md-1-3">
            <img
              src="https://gametize.com/images/custom/landing/index/hero-image-events.png"
              alt=""
              className="imgresponsive"
            />
          </div>
          <div className="pure-u-1-4 pure-u-md-1-3" />
        </div>
      </div>
      ... and so on.
    </Slider>
  );

  // If no legacy tour screens defined, redirect to main screen
  slider.props.handleCloseClick();
  return null;
}*/

/**
 * Render "next" arrow for tour slides
 */
function NextArrow(props) {
  const { className, onClick } = props;
  return <div className={className} onClick={onClick} />;
}

/**
 * Render "previous" arrow for tour slides
 */
function PrevArrow(props) {
  const { className, onClick } = props;
  return <div className={className} onClick={onClick} />;
}

TourSlider.propTypes = propTypes;

export default TourSlider;
