import React, { Component } from "react";
import { connect } from "react-redux";

import FloatingButtons from "./FloatingButtons";

import listenerServices from "services/listenerServices";
import sessionStorageService from "services/sessionStorageService";

export const mapStateToProps = (state, ownProps) => {
  return {
    project: state.floatingButtons
      ? state.floatingButtons.project || null
      : null,
    floatingButtonsState: state.floatingButtons
      ? state.floatingButtons.state || null
      : null,
    buttons: state.floatingButtons
      ? state.floatingButtons.buttons || null
      : null,
    language: state.language,
    isLoggedIn: state.sessionKey != null,
  };
};

export class FloatingButtonsContainer extends Component {
  constructor() {
    super();
    this.state = {
      isMobilePage: true, // for defining mobile Project page
    };
  }

  componentDidMount() {
    /*
      Attaching listener for window resizes for rendering
      Floating Buttons in mobile Project page
    */
    listenerServices.addListener("resize", this.setMobilePage);

    /*
      Perform the first check for determining if a page is in
      mobile view after component is mounted
    */
    this.setMobilePage();
  }

  componentWillUnmount() {
    /* Unmount listener for window resizes */
    listenerServices.removeListener("resize", this.setMobilePage);
  }

  setMobilePage = () => {
    const IS_640_DESKTOP_PROJECT =
      sessionStorageService.getItem("640_desktop_project") === "true";
    // 40em corresponds with $md-640 as our .scss variable, or 640px
    // 48em corresponds with $md as our .scss variable, or 768px
    let match = IS_640_DESKTOP_PROJECT
      ? window.matchMedia("(min-width: 40em)")
      : window.matchMedia("(min-width: 48em)");

    if (match.matches) {
      this.setState({ isMobilePage: false });
    } else {
      this.setState({ isMobilePage: true });
    }
  };

  render() {
    const HAS_BUTTONS = !!this.props.buttons;
    const HAS_VALID_STATE = !!this.props.floatingButtonsState;
    const HAS_PROJECT = !!(this.props.project && this.props.project.id);
    const PROJECT_ID = this.props.project
      ? this.props.project.id || null
      : null;

    if (
      // IS_MOBILE_PROJECT_PAGE ||
      !HAS_BUTTONS ||
      !HAS_VALID_STATE ||
      !HAS_PROJECT
    ) {
      return null;
    } else {
      return (
        <FloatingButtons
          buttons={this.props.buttons}
          projectId={PROJECT_ID}
          isLoggedIn={this.props.isLoggedIn}
          language={this.props.language}
          discussionsEnabled={this.props.project.projectDiscussionEnabled}
        />
      );
    }
  }
}

export default connect(mapStateToProps)(FloatingButtonsContainer);
