import React, { Component } from "react";
import PropTypes from "prop-types";

import Modal from "react-modal";

import localize from "lang/localize";

import "./SelectCategoryDialog.css";
import CategorySelectInput from "./CategorySelectInput";

const propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleCloseSelectCategoryDialog: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  categoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onCategoryChange: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  language: PropTypes.string,
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

class SelectCategoryDialog extends Component {
  render() {
    return (
      <Modal
        isOpen={this.props.showModal}
        contentLabel={localize(
          "general_search_placeholder_text",
          this.props.language,
        )}
        onRequestClose={this.props.handleCloseSelectCategoryDialog}
        shouldCloseOnOverlayClick={false}
        className="dialog select-category-dialog"
        overlayClassName="dialog-overlay"
      >
        <div className="dialog-content">
          <div className="dialog-header">
            <h5 className="dialog-title">
              {localize("icon_categories", this.props.language)}
            </h5>
          </div>
          <div className="dialog-body">
            <div className="pure-g pure-form">
              <CategorySelectInput
                name="categories"
                options={this.props.categories}
                placeholder={localize("category_all_text", this.props.language)}
                onChange={this.props.onCategoryChange}
                selectedId={this.props.categoryId}
                language={this.props.language}
              />
            </div>
          </div>
          <div className="dialog-footer">
            <div className="textright">
              <button
                className="button inline"
                onClick={this.props.handleCloseSelectCategoryDialog}
                id="cancelButton"
              >
                {localize("web_confirm_cancel_text", this.props.language)}
              </button>
              <button
                className="button inline cta capitalize"
                id="selectCategoryButton"
                onClick={(event) => {
                  this.props.handleCloseSelectCategoryDialog();
                  this.props.handleSubmit(event);
                }}
              >
                {localize("button_quest_continue", this.props.language)}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

SelectCategoryDialog.propTypes = propTypes;

export default SelectCategoryDialog;
