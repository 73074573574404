import React, { Component } from "react";
import PropTypes from "prop-types";

import LoginContainer from "components/Login/LoginContainer";
import {
  LOGIN,
  PROJECT_LOGIN,
  LOGIN_HOME,
  PROJECT_LOGIN_HOME,
} from "App/Routes";

import { ENABLE_LOGIN_HOME } from "config";
import localize from "lang/localize";

const propTypes = {
  showLogin: PropTypes.bool,
  message: PropTypes.string,
  fullHeight: PropTypes.bool,
  language: PropTypes.string,
  projectId: PropTypes.number,
};

/**
 * Page segment for login prompt
 */
class NotLoggedIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogin: props.showLogin,
      message: props.message,
      fullHeight: props.fullHeight,
    };
  }

  /**
   * Render CTA to login
   */
  renderLoginLink() {
    if (this.props.projectId && ENABLE_LOGIN_HOME) {
      return PROJECT_LOGIN_HOME.format(this.props.projectId);
    } else if (this.props.projectId) {
      return PROJECT_LOGIN.format(this.props.projectId);
    } else if (ENABLE_LOGIN_HOME) {
      return LOGIN_HOME;
    } else {
      return LOGIN;
    }
  }

  /**
   * Render the component
   */
  render() {
    if (this.props.showLogin && this.props.projectId) {
      return (
        <div id="logincontainer">
          <LoginContainer id={this.props.projectId} />
        </div>
      );
    } else if (this.props.showLogin) {
      return (
        <div id="logincontainer">
          <LoginContainer />
        </div>
      );
    } else {
      return (
        <div
          className={
            "container verticalpadding " +
            (this.props.fullHeight ? "fullheight-content-middle" : "")
          }
        >
          <div className="innerblock">
            <div className="textcenter">
              {this.props.fullHeight ? (
                <div>
                  <i className="far fa-grin-wink font-size-xxxl bottommargin-20"></i>
                  <h5
                    id="message"
                    className="flex justify-content-center bottommargin-40"
                  >
                    {this.props.message
                      ? this.props.message
                      : localize(
                          "require_login_view_page",
                          this.props.language,
                        )}
                  </h5>
                </div>
              ) : (
                <h5 id="message" className="flex justify-content-center">
                  <i className="far fa-grin-wink font-size-xl rightmargin-10"></i>
                  <span>
                    {this.props.message
                      ? this.props.message
                      : localize(
                          "require_login_view_page",
                          this.props.language,
                        )}
                  </span>
                </h5>
              )}
              <div className="topmargin">
                <a
                  className="link button cta inline"
                  href={this.renderLoginLink()}
                >
                  {localize("button_login", this.props.language)}
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

NotLoggedIn.propTypes = propTypes;

export default NotLoggedIn;
