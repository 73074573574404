import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

const defaultProps = {
  selected: false,
  disabled: false,
};

/**
 * Renders a single quiz option button
 * @param {*} props
 */
const QuizInput = (props) => {
  let image = props.image;
  let title = props.title;
  let isGrid = props.isGrid;

  if (isGrid === true) {
    // Render as a grid square
    return (
      <div
        className={
          "button fullwidth gridquizinput" +
          (props.selected ? " selected" : "") +
          (props.disabled ? " pseudo-readonly cursor-not-allowed" : "")
        }
        onClick={() => props.onClick(props.id)}
      >
        {props.image ? (
          <div className="square-image-wrapper">
            <div className="square-image">
              <img src={image} alt={title} />
            </div>
          </div>
        ) : (
          <div className="padding textcenter">{props.title}</div>
        )}

        <span className="gridquizinput-selected"></span>
      </div>
    );
  } else {
    // Render as a standard quiz option
    return (
      <button
        className={
          "button " +
          (props.isConfirmationChallenge
            ? "confirmation-button cta inline"
            : "fullwidth quizinput") +
          (props.selected ? " selected" : "") +
          (props.disabled ? " pseudo-readonly cursor-not-allowed" : "")
        }
        onClick={() => props.onClick(props.id)}
      >
        {props.image && (
          <div className="pure-u-8-24">
            <img className="automargin pure-img" src={image} alt={title} />
          </div>
        )}
        <div className={props.image == null ? "pure-u-24-24" : "pure-u-16-24"}>
          {title}
        </div>
      </button>
    );
  }
};

QuizInput.propTypes = propTypes;
QuizInput.defaultProps = defaultProps;

export default QuizInput;
