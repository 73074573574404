import React, { Component } from "react";
import PropTypes from "prop-types";

import PersonalityModal from "./PersonalityModal";

const propTypes = {
  text: PropTypes.string,
  personality: PropTypes.object.isRequired,
  topicId: PropTypes.number,
};

class PersonalityModalButton extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      showShareDialog: false,
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenShareDialog = this.handleOpenShareDialog.bind(this);
    this.handleCloseShareDialog = this.handleCloseShareDialog.bind(this);
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  /**
   * Open "Share result" popup dialog
   *
   * @param {object} event - Event
   */
  handleOpenShareDialog(event) {
    event.preventDefault();

    this.setState({ showShareDialog: true });
  }

  /**
   * Close "Share result" popup dialog
   */
  handleCloseShareDialog() {
    this.setState({ showShareDialog: false });
  }

  render() {
    return (
      <div>
        <button
          className="button cta automargin"
          onClick={this.handleOpenModal}
        >
          {this.props.text}
        </button>
        <PersonalityModal
          isOpen={this.state.showModal}
          isShareDialogOpen={this.state.showShareDialog}
          onRequestClose={this.handleCloseModal}
          handleOpenShareDialog={this.handleOpenShareDialog}
          handleCloseShareDialog={this.handleCloseShareDialog}
          personality={this.props.personality}
          topicId={this.props.topicId}
        />
      </div>
    );
  }
}

PersonalityModalButton.propTypes = propTypes;

export default PersonalityModalButton;
