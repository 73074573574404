import React, { Component } from "react";
import PropTypes from "prop-types";

import "./DurationInput.css";

const propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onMFChangeDuration: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  handleDisabledClick: PropTypes.func,
};

const defaultProps = {
  disabled: false,
};

class DurationInput extends Component {
  constructor() {
    super();
    this.state = {
      totalSeconds: 0,
      hour: 0,
      minute: 0,
    };
  }

  setHour(event) {
    let index = parseInt(event.currentTarget.name.split("-")[0], 10);
    this.setState({ hour: parseInt(event.currentTarget.value, 10) }, () => {
      this.setTotalSeconds(index);
    });
  }

  setMinute(event) {
    let index = parseInt(event.currentTarget.name.split("-")[0], 10);
    this.setState({ minute: parseInt(event.currentTarget.value, 10) }, () => {
      this.setTotalSeconds(index);
    });
  }

  setTotalSeconds(index) {
    let totalSeconds = this.state.minute * 60 + this.state.hour * 60 * 60;
    this.setState({ totalSeconds: totalSeconds.toString() }, () => {
      this.props.onMFChangeDuration(index, this.state.totalSeconds);
    });
  }

  render() {
    const timeDefault = 0;
    let minuteArr = [];
    let hourArr = [];

    for (let minute = timeDefault; minute < 60; minute += 5) {
      minuteArr.push(minute);
    }
    for (let hour = timeDefault; hour < 24; hour++) {
      hourArr.push(hour);
    }

    return (
      <div className="duration-input">
        <div className="input flex align-items-center">
          <div className="fullwidth disable-check-input-container">
            {this.props.disabled && (
              <div
                className="input-disabled-overlay cursor-not-allowed"
                onClick={this.props.handleDisabledClick}
              />
            )}
            {/* No need to change to AdvancedSelectInput since there is only 1 line */}
            <select
              id={this.props.name + "-hour"}
              name={this.props.name + "-hour"}
              onChange={(event) => this.setHour(event)}
              disabled={this.props.disabled}
              className={
                "duration-select" +
                (this.props.disabled ? "pseudo-readonly" : "")
              }
            >
              {hourArr.map((hour) => {
                return (
                  <option key={hour} value={hour}>
                    {hour}
                  </option>
                );
              })}
            </select>
            <label htmlFor={this.props.name + "-hour"} className="smalltext">
              Hours
            </label>
          </div>
        </div>
        <div className="input flex align-items-center">
          <div className="fullwidth disable-check-input-container">
            {this.props.disabled && (
              <div
                className="input-disabled-overlay cursor-not-allowed"
                onClick={this.props.handleDisabledClick}
              />
            )}
            {/* No need to change to AdvancedSelectInput since there is only 1 line */}
            <select
              id={this.props.name + "-minute"}
              name={this.props.name + "-minute"}
              onChange={(event) => this.setMinute(event)}
              disabled={this.props.disabled}
              className={
                "duration-select" +
                (this.props.disabled ? "pseudo-readonly" : "")
              }
            >
              {minuteArr.map((minute) => {
                return (
                  <option key={minute} value={minute}>
                    {minute}
                  </option>
                );
              })}
            </select>
            <label htmlFor={this.props.name + "-minute"} className="smalltext">
              Minutes
            </label>
          </div>
        </div>
      </div>
    );
  }
}

DurationInput.propTypes = propTypes;
DurationInput.defaultProps = defaultProps;

export default DurationInput;
