import React, { Component } from "react";
import PropTypes from "prop-types";

import Link from "components/shared/Link/Link";
import CollectRedeemedDialog from "./CollectRedeemedDialog/CollectRedeemedDialog";
import ActionConfirmationDialogContainer from "components/shared/Dialogs/ActionConfirmationDialog/ActionConfirmationDialogContainer";

import localize from "lang/localize";

const propTypes = {
  id: PropTypes.number.isRequired,
  transactionId: PropTypes.number.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  img: PropTypes.string,
  descriptionAfterRedeem: PropTypes.string,
  descriptionAfterCollect: PropTypes.string,
  option: PropTypes.string,
  redeemedAtFormatted: PropTypes.string,
  usedAtFormatted: PropTypes.string,
  code: PropTypes.string,
  type: PropTypes.string,
  collectionCancelEnabled: PropTypes.bool,
  collectionEnabled: PropTypes.bool,
  handleClick: PropTypes.func,
  handleCancelAction: PropTypes.func,
  language: PropTypes.string,
  projectId: PropTypes.number,
  showAlertWithTimeout: PropTypes.func.isRequired,

  // Collect Reward Dialog
  showCollectRedeemedDialog: PropTypes.bool.isRequired,
  handleOpenCollectRedeemedDialog: PropTypes.func.isRequired,
  handleCloseCollectRedeemedDialog: PropTypes.func.isRequired,

  // Confirmation Dialog: Cancel Redemption
  showConfirmCancelRedeemDialog: PropTypes.bool.isRequired,
  handleOpenConfirmCancelRedeemDialog: PropTypes.func.isRequired,
  handleCloseConfirmCancelRedeemDialog: PropTypes.func.isRequired,
};

const defaultProps = {
  usedAtFormatted: null,
  redeemedAtFormatted: null,
};

class Reward extends Component {
  /**
   * Render reward status (e.g collection time)
   */
  renderRewardStatus() {
    if (this.props.redeemedAtFormatted) {
      // Render collection date.
      return (
        <div
          className="last-used-text smalltext flex align-items-center bottommargin-10"
          id="unlockedAt"
        >
          <i className="fas fa-history rightmargin-5"></i>
          {this.props.redeemedAtFormatted}
        </div>
      );
    } else {
      // Display nothing
      return null;
    }
  }

  /**
   * Render "Collect" button for reward
   */
  renderButton() {
    if (this.props.usedAtFormatted) {
      if (!this.props.collectionEnabled) {
        return null;
      }

      // Popup still opens to show reward information.
      return (
        <div className="reward-collect">
          <button
            className="button nomargin inactive"
            onClick={(e) => {
              e.preventDefault();
              this.props.handleOpenCollectRedeemedDialog();
            }}
          >
            {localize("item_used_at_text", this.props.language)}
          </button>
        </div>
      );
    } else {
      // Render button to collect reward.
      return (
        <div className="reward-collect">
          <button
            className="button nomargin cta"
            onClick={(e) => {
              e.preventDefault();
              this.props.handleOpenCollectRedeemedDialog();
            }}
          >
            {localize("button_reward_collect", this.props.language)}
          </button>
        </div>
      );
    }
  }

  /**
   * Render cancel redemption link
   */
  renderCancelRedeemLink() {
    // Render link only if reward is not collected or if allowed to cancel after collection
    if (!this.props.usedAtFormatted || this.props.collectionCancelEnabled) {
      return (
        <div className="reward-cancel">
          <Link
            className="link link-color"
            id={"tx" + this.props.transactionId}
            to="#"
            data-id={this.props.transactionId}
            onClick={(e) => {
              e.preventDefault();
              this.props.handleOpenConfirmCancelRedeemDialog();
            }}
          >
            {localize(
              this.props.usedAtFormatted
                ? "cancel_reward_collect"
                : "cancel_reward_redeem",
              this.props.language,
            )}
          </Link>
        </div>
      );
    } else {
      // Do not display
      return null;
    }
  }

  /**
   * Render "Collect Reward" confirmation dialog
   */
  renderCollectRedeemedDialog() {
    return (
      <CollectRedeemedDialog
        itemTitle={this.props.title}
        itemId={this.props.id}
        itemImg={this.props.img}
        itemDescription={this.props.description}
        itemDescriptionAfterRedeem={this.props.descriptionAfterRedeem}
        itemDescriptionAfterCollect={this.props.descriptionAfterCollect}
        itemOption={this.props.option}
        itemRedeemedAtFormatted={this.props.redeemedAtFormatted}
        itemUsedAtFormatted={this.props.usedAtFormatted}
        itemCode={this.props.code}
        itemType={this.props.type}
        showAlertWithTimeout={this.props.showAlertWithTimeout}
        handleClick={this.props.handleClick}
        language={this.props.language}
        // Collect Reward Dialog
        showCollectRedeemedDialog={this.props.showCollectRedeemedDialog}
        collectionCancelEnabled={this.props.collectionCancelEnabled}
        collectionEnabled={this.props.collectionEnabled}
        handleCloseCollectRedeemedDialog={
          this.props.handleCloseCollectRedeemedDialog
        }
        // Confirmation Dialog: Cancel Redemption
        handleOpenConfirmCancelRedeemDialog={
          this.props.handleOpenConfirmCancelRedeemDialog
        }
      />
    );
  }

  /**
   * Render "Confirm Cancel Reward Redemption" Dialog
   */
  renderConfirmCancelRedeemDialog() {
    return (
      <ActionConfirmationDialogContainer
        showModal={this.props.showConfirmCancelRedeemDialog}
        confirmText={localize(
          this.props.usedAtFormatted
            ? "confirm_cancel_reward_collect"
            : "confirm_cancel_reward_redeem",
          this.props.language,
        )}
        warnText={
          this.props.usedAtFormatted &&
          this.props.collectionCancelEnabled &&
          localize("confirm_cancel_reward_collect_hint", this.props.language)
        }
        handleCloseActionConfirmationDialog={
          this.props.handleCloseConfirmCancelRedeemDialog
        }
        handleAction={(event) =>
          this.handleConfirmCancelRedeem(event, this.props.id)
        }
      />
    );
  }

  /**
   * Handle cancel redemption confirmation
   */
  handleConfirmCancelRedeem(event, id) {
    // Executes the cancellation
    this.props.handleCancelAction(event, id);

    // Close the confirmation dialog
    this.props.handleCloseConfirmCancelRedeemDialog();
  }

  /**
   * Render the Rewards component (State: Redeemed)
   */
  render() {
    let img = this.props.img;
    let title = this.props.title;

    return (
      <div className="reward-card card-link-wrapper bottompadding">
        <Link
          to="#"
          className="card-link-overlay"
          onClick={(e) => {
            e.preventDefault();
            this.props.handleOpenCollectRedeemedDialog();
          }}
        />
        <div className="pure-g">
          <div className="pure-u-6-24">
            <span className="square-image-wrapper">
              <span className="square-image circle">
                <img src={img} alt={title} />
              </span>
            </span>
          </div>
          <div className="pure-u-3-24" />
          <div className="pure-u-15-24">
            <div className="nomargin">
              <strong dangerouslySetInnerHTML={{ __html: title }} />
              {this.props.description && (
                <div className="responsive-img-description">
                  <div
                    dangerouslySetInnerHTML={{ __html: this.props.description }}
                  />
                </div>
              )}
            </div>
            {this.renderRewardStatus()}

            <div className="reward-redemption-cta">
              {this.renderButton()}
              {this.renderCancelRedeemLink()}
            </div>
          </div>
          <div className="pure-u-1-24" />
        </div>
        {this.props.showCollectRedeemedDialog &&
          this.renderCollectRedeemedDialog()}
        {this.props.showConfirmCancelRedeemDialog &&
          this.renderConfirmCancelRedeemDialog()}
      </div>
    );
  }
}

Reward.propTypes = propTypes;
Reward.defaultProps = defaultProps;

export default Reward;
