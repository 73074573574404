import React, { Component } from "react";
import PropTypes from "prop-types";

import Dropdown from "@trendmicro/react-dropdown";
import "@trendmicro/react-buttons/dist/react-buttons.css";
import "@trendmicro/react-dropdown/dist/react-dropdown.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  CHALLENGE_ENQUIRY,
  CHALLENGE_COMMENTS,
  CHALLENGE_A_FRIEND,
} from "App/Routes";
import LoginDialogContainer from "components/shared/Dialogs/LoginDialog/LoginDialogContainer";
import Link from "components/shared/Link/Link";
import "./Challenge.css";

import {
  ENABLE_CHALLENGE_OPTIONS_BUTTON,
  ENABLE_CONTENT_ENQUIRY,
  USE_ENQUIRY_PAGE,
  CONTACT_EMAIL_DEFAULT,
  CONTACT_EMAIL_TITLE_DEFAULT,
  ENABLE_CHALLENGE_COMMENTS,
  ENABLE_CHALLENGE_BOOKMARK_BUTTON,
  ENABLE_CHALLENGE_LIKES,
} from "config";
import localize from "lang/localize";

const propTypes = {
  user: PropTypes.object.isRequired,
  sessionKey: PropTypes.string,
  challengeTypeId: PropTypes.number.isRequired,
  // stats
  commentNo: PropTypes.number,
  claimNo: PropTypes.number,
  challengeType: PropTypes.string.isRequired,
  points: PropTypes.number,
  challengeReferralLink: PropTypes.string,
  isLiked: PropTypes.bool,
  likeNo: PropTypes.number,
  isBookmarked: PropTypes.bool,
  handleBookmark: PropTypes.func,
  handleLike: PropTypes.func,
  // login dialogs
  showLoginDialog: PropTypes.bool,
  handleOpenLoginDialog: PropTypes.func,
  handleCloseLoginDialog: PropTypes.func,
  // language
  language: PropTypes.string,
  // contact email
  contactEmail: PropTypes.string,
  // locked content handling
  locked: PropTypes.bool,
  // context
  context: PropTypes.string,
};

const defaultProps = {
  contactEmail: CONTACT_EMAIL_DEFAULT,
};

class ChallengeMeta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 1,
    };
    this.sliderRef = React.createRef();
  }

  /**
   * Render challenge statistics (comments/like count... etc)
   */
  renderStats() {
    if (
      this.props.challengeTypeId === 4 &&
      (ENABLE_CHALLENGE_LIKES || ENABLE_CHALLENGE_COMMENTS)
    ) {
      return (
        <span className="smalltext rightmargin-10" id="stats">
          {ENABLE_CHALLENGE_COMMENTS &&
            this.props.commentNo.localize(
              "comment_text",
              "comments_text",
              this.props.language,
              false,
            )}
          {/* rendering spaces below, &nbsp; doesn't render well with JSX */}
          {ENABLE_CHALLENGE_COMMENTS & ENABLE_CHALLENGE_LIKES
            ? "&nbsp;&nbsp;&nbsp;"
            : null}

          {ENABLE_CHALLENGE_LIKES &&
            this.props.likeNo.localize(
              "like_text",
              "likes_text",
              this.props.language,
              false,
            )}
        </span>
      );
    } else if (this.props.challengeTypeId === 4) {
      return null;
    } else if (ENABLE_CHALLENGE_COMMENTS) {
      return (
        <span className="smalltext rightmargin-10" id="stats">
          {this.props.commentNo.localize(
            "comment_text",
            "comments_text",
            this.props.language,
            false,
          )}
          &nbsp;&nbsp;&nbsp;
          {this.props.claimNo.localize(
            "challenge_completed_text",
            "challenges_completed_text",
            this.props.language,
            false,
          )}
        </span>
      );
    } else {
      return (
        <span className="smalltext rightmargin-10" id="stats">
          {this.props.claimNo.localize(
            "challenge_completed_text",
            "challenges_completed_text",
            this.props.language,
            false,
          )}
        </span>
      );
    }
  }

  /**
   * Render comment button
   */
  renderChallengeCommentsButton() {
    if (ENABLE_CHALLENGE_COMMENTS) {
      return (
        <Link
          to={CHALLENGE_COMMENTS.format(this.props.id) + "#comments"}
          id="commentButton"
          className="button icon inline-block"
        >
          <span className="comment-icon" />
        </Link>
      );
    } else {
      return null;
    }
  }

  /**
   * Render all action buttons
   */
  renderButtons() {
    return (
      <div>
        {this.renderBookmarkButton()}
        {this.props.challengeTypeId === 4 && this.renderLikeButton()}
        {this.renderChallengeCommentsButton()}
        {this.renderMenuDropdownButton()}
      </div>
    );
  }

  /**
   * Render menu dropdown buttons
   */
  renderMenuDropdownButton() {
    if (
      this.props.sessionKey &&
      ENABLE_CHALLENGE_OPTIONS_BUTTON &&
      (ENABLE_CONTENT_ENQUIRY || this.props.challengeReferralLink)
    ) {
      return (
        <div className="dropdown-button-group">
          <Dropdown
            dropup={true}
            onSelect={(eventKey, event) => {
              event.preventDefault();
            }}
            pullRight={true}
          >
            <Dropdown.Toggle
              btnStyle="flat"
              noCaret={true}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <span className="more-icon" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {this.props.challengeReferralLink && (
                <Link to={CHALLENGE_A_FRIEND.format(this.props.id)}>
                  {localize("challenge_referral_text", this.props.language)}
                </Link>
              )}
              {ENABLE_CONTENT_ENQUIRY &&
                (USE_ENQUIRY_PAGE ? (
                  <Link to={CHALLENGE_ENQUIRY.format(this.props.id)}>
                    {localize("report_challenge", this.props.language)}
                  </Link>
                ) : (
                  <a
                    href={
                      "mailto:" +
                      this.props.contactEmail +
                      "?subject=" +
                      (CONTACT_EMAIL_TITLE_DEFAULT
                        ? CONTACT_EMAIL_TITLE_DEFAULT
                        : "I want to make a report on Gametize (Challenge ID: " +
                          this.props.id +
                          ")")
                    }
                  >
                    {localize("report_challenge", this.props.language)}
                  </a>
                ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
    } else {
      return null;
    }
  }

  /**
   * Render bookmark button
   */
  renderBookmarkButton() {
    if (!ENABLE_CHALLENGE_BOOKMARK_BUTTON) {
      return null;
    } else if (!!this.props.user.id) {
      return (
        <button
          id="bookmarkButton"
          className={
            "button icon inline" +
            (this.props.isBookmarked ? " bookmarked" : "")
          }
          onClick={(event) =>
            this.props.handleBookmark(
              event,
              this.props.id,
              this.props.isBookmarked,
            )
          }
        >
          <span className="bookmark-icon" />
        </button>
      );
    } else {
      return (
        <div className="inline">
          <button
            id="loginBeforeBookmarkButton"
            className={
              "button icon inline" +
              (this.props.isBookmarked ? " bookmarked" : "")
            }
            onClick={this.props.handleOpenLoginDialog}
          >
            <span className="bookmark-icon" />
          </button>
          {this.props.showLoginDialog && this.renderLoginDialog()}
        </div>
      );
    }
  }

  /**
   * Render like button
   */
  renderLikeButton() {
    if (!ENABLE_CHALLENGE_LIKES) {
      return null;
    } else if (!!this.props.user.id) {
      return (
        <button
          id="likeButton"
          className={
            "button icon inline" + (this.props.isLiked ? " liked" : "")
          }
          onClick={this.props.handleLike}
        >
          <span className="like-icon" />
        </button>
      );
    } else {
      return (
        <div className="inline">
          <button
            id="loginBeforeLikeButton"
            className={
              "button icon inline" + (this.props.isLiked ? " liked" : "")
            }
            onClick={this.props.handleOpenLoginDialog}
          >
            <span className="like-icon" />
          </button>
          {this.props.showLoginDialog && this.renderLoginDialog()}
        </div>
      );
    }
  }

  /**
   * Render prompt login popup dialog
   */
  renderLoginDialog() {
    return (
      <LoginDialogContainer
        showModal={this.props.showLoginDialog}
        handleCloseLoginDialog={this.props.handleCloseLoginDialog}
      />
    );
  }

  /**
   * Render view
   */
  render() {
    if (this.props.locked) {
      return null;
    } else {
      return (
        <div className="topmargin-20">
          <div className="pure-g innerblock challenge-meta-wrap">
            {this.props.context !== "comments" && this.renderStats()}
            {this.props.context !== "comments" && this.renderButtons()}
          </div>
        </div>
      );
    }
  }
}

ChallengeMeta.propTypes = propTypes;
ChallengeMeta.defaultProps = defaultProps;

export default ChallengeMeta;
