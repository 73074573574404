import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Router from "router";

import Loading from "components/shared/Loading";
import NewPostPage from "./NewPostPage";

import { PROJECT_DISCUSSIONS } from "App/Routes";
import {
  showAlertWithTimeout,
  setButtons,
  setProject,
  setBoard,
} from "actions";
import { USE_API2_PROJECT_INFO } from "config";
import {
  GET_BOARDS,
  GET_BOARDS_POSTS,
  CREATE_POST,
  GET_PROJECT_BUTTONS,
  GET_PROJECT_API2,
} from "services/api";
import getApiGenerator from "services/getApiGenerator";
import getApiGenerator2 from "services/getApiGenerator2";
import pushApiGenerator2 from "services/pushApiGenerator2";

const propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  id2: PropTypes.string,
};

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    language: state.language,
    projectId: state.projectId,
    userId: state.user ? state.user.id : null,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showAlertWithTimeout: (alert) => {
      dispatch(showAlertWithTimeout(alert));
    },
    setButtons: (buttons) => {
      dispatch(setButtons(buttons));
    },
    setProject: (project) => {
      dispatch(setProject(project));
    },
    setBoard: (board) => {
      dispatch(setBoard(board));
    },
  };
};

export class NewPostContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // id refers to projectId, id2 refers to boardId
      id: props.id,
      id2: props.id2,
      comments: null,
      more: false,
      page: 1,
      titleInput: "",
      input: "",
      // character limit
      charLimit: 0,
      // discussions
      currentBoard: null,
      boards: null,
      posts: null,
      likeNo: 0,
      restrictSubmission: false,
      selectedBoard: this.props.id2,
      fileUploadLimit: 0,
      fileTotalLimit: 0,
      submitted: false,
    };

    this.getBoards = this.getBoards.bind(this);
  }

  componentDidMount() {
    this.getBoards();
    if (USE_API2_PROJECT_INFO) {
      this.getProjectInfo(this.state.id);
    }
    window.addEventListener("scroll", this.handleMore);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleMore);
  }

  getBoards() {
    getApiGenerator2(
      GET_BOARDS.format(this.state.id),
      {
        page: 1,
        lean: true,
        limit: 100, // Use a high limit to display all
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            boards: [],
          });
        }
      } else {
        // Use first board as board selection if user does not have any active filter
        if (this.state.id2 === undefined) {
          if (res.body.data !== null && res.body.data.length > 0) {
            this.setState({
              id2: res.body.data[0].id,
              selectedBoard: res.body.data[0].id,
            });
          }
        }

        this.setState({
          boards: res.body.data,
        });

        if (!USE_API2_PROJECT_INFO) {
          // This is already called in componentDidMount() after Platform 4.5.0
          this.getProjectInfo(this.state.id);
        }
      }
    });
  }

  getProjectInfo(projectId) {
    if (!USE_API2_PROJECT_INFO) {
      // fallback method
      this.getBoardPosts();
      this.getProjectButtons(this.state.id);
      return;
    }

    getApiGenerator2(
      // API is only available in Platform 4.5.0
      GET_PROJECT_API2.format(projectId),
      {},
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body) {
          this.setState({
            fileUploadLimit: res.body.fileUploadLimit,
            fileTotalLimit: res.body.fileTotalLimit,
            charLimit: res.body.charLimit,
          });

          if (res.body.buttons) {
            this.props.setButtons(res.body.buttons);

            // remap new params to old params
            let projectInfo = res.body;

            projectInfo["title"] = projectInfo["projectTitle"];
            projectInfo["description"] = projectInfo["projectDescription"];
            projectInfo["backgroundImage"] =
              projectInfo["projectBackgroundImage"];
            projectInfo["bannerImage"] = projectInfo["projectBannerImage"];

            this.props.setProject(res.body);
          } else {
            // fallback method
            this.getProjectButtons(this.state.id);
          }
        } else {
          // fallback method
          this.getBoardPosts();
          this.getProjectButtons(this.state.id);
        }
      } else {
        this.setState({
          fileUploadLimit: res.body.fileUploadLimit,
          fileTotalLimit: res.body.fileTotalLimit,
          charLimit: res.body.charLimit,
        });

        this.getProjectButtons(this.state.id);
      }
    });
  }

  getProjectButtons(projectId) {
    getApiGenerator(
      GET_PROJECT_BUTTONS.format(projectId),
      {},
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        this.props.setButtons(null);
      } else {
        const BUTTONS =
          res.body.data && res.body.data.length > 0 ? res.body.data : null;

        this.props.setButtons(BUTTONS);
        this.props.setProject(res.body.game);
      }
    });
  }

  // need to get charLimit, fileUploadLimit, fileTotalLimit
  getBoardPosts() {
    if (!this.state.selectedBoard) {
      return;
    }

    getApiGenerator2(
      GET_BOARDS_POSTS.format(this.state.selectedBoard),
      { page: 1, limit: 1 }, // Use only a page and 1 item to retrieve only configs
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        // no posts in board, but these limits are still returned if board exists
        this.setState({
          fileUploadLimit: res.body.fileUploadLimit,
          fileTotalLimit: res.body.fileTotalLimit,
          charLimit: res.body.charLimit,
        });
      } else {
        this.setState({
          fileUploadLimit: res.body.fileUploadLimit,
          fileTotalLimit: res.body.fileTotalLimit,
          charLimit: res.body.charLimit,
        });
      }

      this.props.setBoard(res.body.board);
    });
  }

  handleTitleInputChange = (event) => {
    this.setState({ titleInput: event.target.value });
  };

  handleInputChange = (event) => {
    this.setState({ input: event.target.value });
  };

  handleBoardInputChange = (name, option) => {
    // react-select returns the object rather than the event
    this.setState({
      selectedBoard: option.value,
      selectedOption: option,
    });
  };

  handleSubmit = (images) => {
    this.createPost(images);
  };

  createPost = (images = null) => {
    this.setState({ submitted: true });

    let query = {
      session_key: this.props.sessionKey,
      title: this.state.titleInput,
      content: this.state.input,
    };

    let req = pushApiGenerator2(
      CREATE_POST.format(this.state.selectedBoard),
      query,
      this.props.sessionKey,
    );
    if (images !== null) {
      Array.from(images).forEach((image) => {
        req.field("file", image);
        req.field("file_name", image.name);
      });
    }
    req.end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error) {
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error",
          });
        }
        this.setState({ submitted: false });
      } else {
        this.setState({
          titleInput: "",
          input: "",
          submitted: false,
        });
        Router.navigate(PROJECT_DISCUSSIONS.format(this.props.id));
      }
    });
  };

  renderNewPostPage() {
    if (this.state.boards) {
      return (
        <NewPostPage
          sessionKey={this.props.sessionKey}
          isLoadingMore={this.state.isLoadingMore}
          more={this.state.more}
          type={this.props.type}
          language={this.props.language}
          projectId={this.state.id}
          userId={this.props.userId}
          removePost={this.removePost}
          charLimit={
            this.state.charLimit !== undefined ? this.state.charLimit : 0
          }
          posts={this.state.posts}
          boards={this.state.boards}
          currentBoard={this.state.currentBoard}
          handleLike={this.handleLike}
          handleBookmark={this.handleBookmark}
          restrictSubmission={this.restrictSubmission}
          handleTitleInputChange={this.handleTitleInputChange}
          handleInputChange={this.handleInputChange}
          handleBoardInputChange={this.handleBoardInputChange}
          handleSubmit={this.handleSubmit}
          id={this.props.id}
          id2={this.props.id2}
          selectedOption={this.state.selectedOption}
          showInput={!!this.props.sessionKey}
          titleInput={this.state.titleInput}
          input={this.state.input}
          submitted={this.state.submitted}
          handleMore={this.handleMore}
          showAlertWithTimeout={this.props.showAlertWithTimeout}
          fileTotalLimit={this.state.fileTotalLimit}
          fileUploadLimit={this.state.fileUploadLimit}
        />
      );
    } else if (!this.state.boards) {
      /* Boards not loaded yet */
      return <Loading />;
    }
  }

  render() {
    return this.renderNewPostPage();
  }
}

NewPostContainer.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(NewPostContainer);
