import React, { Component } from "react";
import { connect } from "react-redux";
import debounce from "lodash.debounce";

import UsersPage from "./UsersPage";
import Loading from "components/shared/Loading";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";

import { GET_PROJECT_USERS, GET_TOPICS, FOLLOW_USER } from "services/api";
import { setProject, setButtons, showAlertWithTimeout } from "actions";
import getApiGenerator from "services/getApiGenerator";
import listenerServices from "services/listenerServices";
import localize from "lang/localize";

import { LOAD_MORE_COUNT } from "config";

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    projectId: state.projectId,
    userId: state.user ? state.user.id : null,
    language: state.language,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setProject: (project) => {
      dispatch(setProject(project));
    },
    setButtons: (buttons) => {
      dispatch(setButtons(buttons));
    },
    showAlertWithTimeout: (alert) => {
      dispatch(showAlertWithTimeout(alert));
    },
  };
};

export class UsersContainer extends Component {
  constructor() {
    super();
    this.state = {
      players: null,
      more: false,
      page: 1,
      searchInput: "",
      beganSearch: false,
      topics: null,
      error: null,
      code: null,
      isLoadingMore: false,
    };
    this.handleMore = this.handleMore.bind(this);
    this.getPlayers = this.getPlayers.bind(this);
    this.handleFollow = this.handleFollow.bind(this);
  }

  componentDidMount() {
    if (this.props.projectId) {
      /* Retrieve Project information */
      this.getTopics();
      this.getPlayers(this.state.page);
    }
    window.addEventListener("scroll", this.handleMore);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleMore);
  }

  handleSearchChange(event) {
    if (!this.state.beganSearch) {
      this.setState({ beganSearch: true });
    }

    this.setState({ searchInput: event.target.value }, () =>
      this.getPlayers(1, this.state.searchInput),
    );
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  async handleFollow(id) {
    await new Promise((resolve) => {
      if (id && this.props.sessionKey) {
        getApiGenerator(
          FOLLOW_USER,
          {
            type_id: id,
            project_id: this.props.projectId,
          },
          this.props.sessionKey,
        ).end((err, res) => {
          if (err || res.body.code !== 200) {
            if (res.body.error) {
              this.props.showAlertWithTimeout({
                text: res.body.error,
                type: "error",
              });
              this.setState({
                code: 500,
                error: res.body.error,
              });
            }
          } else {
            this.setState({
              code: res.body.code,
              error: "",
            });
            resolve();
          }
        });
      }
    });
  }

  handleMore = debounce(() => {
    const {
      getPlayers,
      state: { more, isLoadingMore, page, search },
    } = this;
    if (!more) {
      return;
    } else if (!isLoadingMore && more) {
      if (listenerServices.isAtScrollThreshold()) {
        this.setState(() => ({
          isLoadingMore: true,
        }));
        getPlayers(page, search);
      }
    }
  }, 100);

  getTopics() {
    getApiGenerator(
      GET_TOPICS.format(this.props.projectId),
      {
        page: 1,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            topics: [],
            code: 500,
            error: res.body.error,
          });
        }
      } else {
        this.setState({
          topics: res.body.data,
          code: res.body.code,
          error: "",
        });

        const BUTTONS =
          res.body.buttons && res.body.buttons.length > 0
            ? res.body.buttons
            : null;

        this.props.setProject(res.body.game);
        this.props.setButtons(BUTTONS);
      }
    });
  }

  getPlayers(page, search = null) {
    getApiGenerator(
      GET_PROJECT_USERS.format(this.props.projectId),
      {
        page: page,
        limit: LOAD_MORE_COUNT,
        keywords: search,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            players: [],
            more: false,
            page: 1,
            isLoadingMore: false,
          });
        }
      } else {
        if (page === 1) {
          this.setState({
            players: res.body.data,
            more: res.body.more,
            page: page + 1,
            isLoadingMore: false,
          });
        } else {
          this.setState({
            players: this.state.players
              ? this.state.players.slice().concat(res.body.data)
              : res.body.data,
            more: res.body.more,
            page: this.state.page + 1,
            isLoadingMore: false,
          });
        }
      }
    });
  }

  render() {
    if (
      /* Project ID is available, but Project is private */
      this.props.projectId &&
      this.state.code === 500 &&
      this.state.error &&
      this.state.error.indexOf("private") !== -1
    ) {
      return (
        <GenericErrorPage
          message={localize(
            "bundle_private_logged_in_text",
            this.props.language,
          )}
          language={this.props.language}
        />
      );
    } else if (
      /* Project ID is available, but Project does not exist */
      this.props.projectId &&
      Array.isArray(this.state.topics) &&
      (this.state.error.indexOf("not exist") !== -1 ||
        this.state.error.indexOf("not published") !== -1)
    ) {
      return (
        <GenericErrorPage
          message={localize("bundle_not_found_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else if (this.state.players && this.state.players.length !== 0) {
      return (
        <UsersPage
          isLoadingMore={this.state.isLoadingMore}
          projectId={this.props.projectId}
          userId={this.props.userId}
          players={this.state.players}
          more={this.state.more}
          handleMore={() => this.handleMore()}
          handleSubmit={this.handleSubmit.bind(this)}
          handleSearchChange={this.handleSearchChange.bind(this)}
          language={this.props.language}
          handleFollow={this.handleFollow}
          error={this.state.error}
        />
      );
    } else if (this.state.players && this.state.beganSearch) {
      // can't find searched players
      return (
        <UsersPage
          isLoadingMore={this.state.isLoadingMore}
          projectId={this.props.projectId}
          userId={this.props.userId}
          players={this.state.players}
          more={this.state.more}
          handleMore={() => this.handleMore()}
          handleSubmit={this.handleSubmit.bind(this)}
          handleSearchChange={this.handleSearchChange.bind(this)}
          searchedPlayersExist={false}
          language={this.props.language}
          handleFollow={this.handleFollow}
          error={this.state.error}
        />
      );
    } else if (this.state.players) {
      return (
        <GenericErrorPage
          message={localize("empty_players_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersContainer);
