import React, { Component } from "react";
import PropTypes from "prop-types";

import BasicShareDialog from "components/shared/Dialogs/ShareDialog/BasicShareDialog";

const propTypes = {
  showDialog: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  projectId: PropTypes.number.isRequired,
  project: PropTypes.object,
  language: PropTypes.string,
};

/**
 * Pop-up Modal for Project sharing
 */
class ShareGameDialog extends Component {
  render() {
    const BASE_PLACEHOLDER_PROJECT_IMAGE =
      "https://gametize.com/images/core/business/placeholders/placeholder_project.png";

    let sharableLink = this.props.project.projectShareLink || "";
    let sharableImage =
      this.props.project && this.props.project.bannerImage
        ? this.props.project.bannerImage
        : BASE_PLACEHOLDER_PROJECT_IMAGE;

    return (
      <BasicShareDialog
        showDialog={this.props.showDialog}
        handleCloseDialog={this.props.handleCloseDialog}
        sharableLink={sharableLink}
        imageUrl={sharableImage}
        language={this.props.language}
      />
    );
  }
}

ShareGameDialog.propTypes = propTypes;

export default ShareGameDialog;
