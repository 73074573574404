import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Router from "router";

import LoginDialogContainer from "components/shared/Dialogs/LoginDialog/LoginDialogContainer";

import {
  PROJECT_TEAM_CREATE,
  TEAM,
  PROJECT_TEAM,
  PROJECT_TEAM_INBOX,
} from "App/Routes";
import SearchBar from "components/shared/SearchBar/SearchBar";
import Team from "./Team";
import Link from "components/shared/Link/Link";
import localize from "lang/localize";

import "./Teams.css";
import Loading from "components/shared/Loading";

import { SINGLE_PROJECT_APP, ENABLE_INBOX, ENABLE_TEAM_INBOX } from "config";

const propTypes = {
  teams: PropTypes.array.isRequired,
  teamCreateEnabled: PropTypes.bool.isRequired,
  teamCreatedInProject: PropTypes.bool.isRequired,
  user: PropTypes.object,
  team: PropTypes.string,
  teamId: PropTypes.number,
  teamImg: PropTypes.string,
  more: PropTypes.bool.isRequired,
  handleMore: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  language: PropTypes.string,
  projectId: PropTypes.number,
  isLoadingMore: PropTypes.bool,
  //login dialogs
  showLoginDialog: PropTypes.bool.isRequired,
  handleOpenLoginDialog: PropTypes.func.isRequired,
  handleCloseLoginDialog: PropTypes.func.isRequired,
  sessionKey: PropTypes.string,
};

/**
 * Team Page - Displays list of teams.
 */
class TeamsPage extends Component {
  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    let bodyDOM = document.body; // <body> tag

    // Set page identifier class to body DOM
    if (!bodyDOM.classList.contains("teamsPage")) {
      bodyDOM.className += " teamsPage";
    }

    // Add other page classes to body DOM
    if (!bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.className += " page-loggedin";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    let bodyDOM = document.body; // <body> tag

    // Remove page identifier class from body DOM
    if (bodyDOM.classList.contains("teamsPage")) {
      bodyDOM.classList.remove("teamsPage");
    }

    // Remove other page classes from body DOM
    if (bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.classList.remove("page-loggedin");
    }
  }

  /**
   * Render the user's current team information
   */
  renderUserTeam() {
    if (this.props.team) {
      let teamImg = this.props.teamImg;
      let team = this.props.team;

      return (
        <Link
          to={
            this.props.projectId && !SINGLE_PROJECT_APP
              ? PROJECT_TEAM.format(this.props.projectId, this.props.teamId)
              : TEAM.format(this.props.teamId)
          }
        >
          <div
            id="team"
            className="container highlightbg topbar flex align-items-center justify-content-space-between verticalpadding-5"
          >
            <div className="flex align-items-center">
              <div className="rightmargin-15">
                {/* Profile Header (Mobile View) */}
                <div className="hide-from-md">
                  <strong className="font-size-xs text-align-nowrap">
                    {localize("title_my_team", this.props.language)}:
                  </strong>
                </div>

                {/* Profile Header (Desktop View) */}
                <div className="hide-below-md">
                  <strong>
                    {localize("title_my_team", this.props.language)}:
                  </strong>
                </div>
              </div>
              <img
                className="iconxs circle border-gray-lighter border-thin rightmargin-10"
                src={teamImg}
                alt={team}
              />
              <div className="team-name-header">
                <div dangerouslySetInnerHTML={{ __html: team }} />
              </div>
            </div>
            {ENABLE_INBOX && ENABLE_TEAM_INBOX && (
              <div className="inboxTeam leftmargin-15">
                {/* Profile Header (Mobile View) */}
                <div className="hide-from-md">
                  <div
                    id="nextButton"
                    className="button icon cta-inverted nomargin"
                    onClick={(e) => {
                      e.preventDefault();
                      Router.navigate(
                        PROJECT_TEAM_INBOX.format(this.props.projectId),
                      );
                    }}
                  >
                    <span className="fas fa-inbox" />
                  </div>
                </div>

                {/* Profile Header (Desktop View) */}
                <div className="hide-below-md">
                  <div
                    id="nextButton"
                    className="button small cta-inverted nomargin"
                    onClick={(e) => {
                      e.preventDefault();
                      Router.navigate(
                        PROJECT_TEAM_INBOX.format(this.props.projectId),
                      );
                    }}
                  >
                    <span className="fas fa-inbox rightmargin-5" />
                    {localize("noun_inbox_team", this.props.language)}
                  </div>
                </div>
              </div>
            )}
          </div>
        </Link>
      );
    } else {
      return null;
    }
  }

  /**
   * Render button to create team
   */
  renderCreateTeamButton() {
    if (this.props.sessionKey) {
      if (this.props.teamCreateEnabled) {
        // Existing user has not created a team before
        if (!this.props.teamCreatedInProject) {
          return (
            <Fragment>
              <div className="row">
                <div className="hide-below-sm pure-u-sm-3-4" />
                <div className="pure-u-1-1 pure-u-sm-1-4">
                  <Link
                    to={PROJECT_TEAM_CREATE.format(this.props.projectId)}
                    className="button cta"
                  >
                    <span className="fas fa-plus test" />
                    &nbsp;&nbsp;
                    {localize("new_team_text", this.props.language)}
                  </Link>
                </div>
              </div>
            </Fragment>
          );
        } else {
          return (
            // Show greyed out button button
            <Fragment>
              <div className="row">
                <div className="hide-below-sm pure-u-sm-2-3" />
                <div className="pure-u-1-1 pure-u-sm-1-3">
                  <div className="button">
                    <span className="fas fa-plus" />
                    &nbsp;&nbsp;
                    {localize("new_team_text", this.props.language)}
                    <br />
                    <small>
                      <em>
                        (
                        {localize(
                          "new_team_disabled_text",
                          this.props.language,
                        )}
                        )
                      </em>
                    </small>
                  </div>
                </div>
              </div>
            </Fragment>
          );
        }
      } else {
        // Don't show anything if setting is disabled
        return null;
      }
    } else {
      return (
        <Fragment>
          <div className="row">
            <div className="hide-below-sm pure-u-sm-3-4" />
            <div className="pure-u-1-1 pure-u-sm-1-4">
              <button
                className="button link fullwidth cta"
                onClick={this.props.handleOpenLoginDialog}
              >
                <span className="fas fa-plus test" />
                &nbsp;&nbsp;
                {localize("new_team_text", this.props.language)}
              </button>
              {this.props.showLoginDialog && this.renderLoginDialog()}
            </div>
          </div>
        </Fragment>
      );
    }
  }

  /**
   * Render prompt login popup dialog
   */
  renderLoginDialog() {
    return (
      <LoginDialogContainer
        showModal={this.props.showLoginDialog}
        handleCloseLoginDialog={this.props.handleCloseLoginDialog}
      />
    );
  }

  /**
   * Render Team search bar
   */
  renderSearchBar() {
    return (
      <Fragment>
        <div className="verticalpadding-5" />
        <SearchBar
          handleSubmit={this.props.handleSubmit}
          handleSearchChange={this.props.handleSearchChange}
          placeholder={localize(
            "general_search_placeholder_text",
            this.props.language,
          )}
          language={this.props.language}
        />
      </Fragment>
    );
  }

  /**
   * Render list of Teams
   */
  renderTeams() {
    if (this.props.teams.length >= 1) {
      return this.props.teams.map((team) => (
        <Team
          key={team.id}
          id={team.id}
          name={team.name}
          userNo={team.userNo}
          img={team.image}
          user={this.props.user}
          language={this.props.language}
          projectId={this.props.projectId}
          joined={!!team.joined}
        />
      ));
    } else {
      return (
        <p className="textcenter">
          {localize("empty_teams_text", this.props.language)}
        </p>
      );
    }
  }

  /**
   * Page render
   */
  render() {
    return (
      <Fragment>
        {this.renderUserTeam()}
        <div className="teams container verticalpadding">
          <div className="innerblock pure-g bottompadding-floating">
            <div className="pure-u-md-2-24 pure-u-0-24" />
            <div className="pure-u-md-20-24 pure-u-1">
              {this.renderCreateTeamButton()}
              {this.renderSearchBar()}
              {this.renderTeams()}
              {this.props.isLoadingMore && <Loading />}
              {/* {this.props.more && (
                <button
                  className="button automargin"
                  onClick={this.props.handleMore}
                >
                  {localize("more_text", this.props.language)}
                </button>
              )} */}
            </div>
            <div className="pure-u-md-2-24 pure-u-0-24" />
          </div>
        </div>
      </Fragment>
    );
  }
}

TeamsPage.propTypes = propTypes;

export default TeamsPage;
