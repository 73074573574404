import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import debounce from "lodash.debounce";

import ActivityPage from "./ActivityPage";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";
import Loading from "components/shared/Loading";

import { LOAD_MORE_COUNT } from "config";
import {
  setProject,
  setButtons,
  setTopic,
  showAlertWithTimeout,
  addProfileDrawerPoints,
  showAwardableActionWithTimeout,
  showAchievements,
} from "actions";
import {
  GET_PROJECT_ACTIVITY,
  GET_CHALLENGE_ACTIVITY,
  GET_CLAIM_ACTIVITY,
  GET_PROJECT_BUTTONS,
} from "services/api";
import {
  startChallengeTimeTracking,
  endChallengeTimeTracking,
} from "services/challengeServices";
import urlServices from "services/urlServices";
import { likeClaim } from "services/challengeServices";
import getApiGenerator from "services/getApiGenerator";
import listenerServices from "services/listenerServices";
import localize from "lang/localize";

const propTypes = {
  type: PropTypes.oneOf(["project", "challenge", "claim"]),
  id: PropTypes.string,
};

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    projectId: state.projectId,
    userId: state.user ? state.user.id : null,
    language: state.language,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setProject: (project) => {
      dispatch(setProject(project));
    },
    setButtons: (buttons) => {
      dispatch(setButtons(buttons));
    },
    setTopic: (topic) => {
      dispatch(setTopic(topic));
    },
    showAlertWithTimeout: (alert) => {
      dispatch(showAlertWithTimeout(alert));
    },
    addProfileDrawerPoints: (points) => {
      dispatch(addProfileDrawerPoints(points));
    },
    showAwardableActionWithTimeout: (awardableAction) => {
      dispatch(showAwardableActionWithTimeout(awardableAction));
    },
    showAchievements: (achievements) => {
      dispatch(showAchievements(achievements));
    },
  };
};

export class ActivityContainer extends Component {
  constructor(props) {
    super();
    this.state = {
      claims: null,
      media: null,
      filterType: "media",
      page: 1,
      more: false,
      currentBoard: "activity",
      isLoadingMore: false,
      // challenge time tracking
      isChallengeActivity: false,
    };

    this.removeClaim = this.removeClaim.bind(this);
    this.handleMore = this.handleMore.bind(this);
    this.getActivity = this.getActivity.bind(this);
    this.getMedia = this.getMedia.bind(this);
  }

  componentDidMount() {
    /*
      switch to media tab and display photos/videos if parameter
      for photos only, use ?tab=image
      for videos only, use ?tab=video
    */
    const filter = urlServices.getUrlParamValueByKey("filter");
    if (filter === "image" || filter === "video") {
      if (this.props.type !== "claim") {
        this.setState({ currentBoard: "media", filterType: filter }, () => {
          this.getMedia(1);
        });
      }
    } else {
      this.getActivity();
    }

    window.addEventListener("scroll", this.handleMore);

    /* start challenge time tracking if challenge activity */
    if (window.location.href.includes("challenge") && this.props.sessionKey) {
      this.setState({ isChallengeActivity: true });
      startChallengeTimeTracking(
        this.props.id,
        this.props.sessionKey,
        this.props.showAlertWithTimeout,
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleMore);

    if (this.state.isChallengeActivity && this.props.sessionKey) {
      endChallengeTimeTracking(
        this.props.id,
        this.props.sessionKey,
        this.props.showAlertWithTimeout,
        window.location.pathname,
      );
    }
  }

  removeClaim(claimId) {
    this.setState({
      claims: this.state.claims.filter((element) => element.id !== claimId),
    });
  }

  getURL(type, id, projectId) {
    if (type === "project") {
      return GET_PROJECT_ACTIVITY.format(projectId);
    } else if (type === "challenge") {
      return GET_CHALLENGE_ACTIVITY.format(id);
    } else if (type === "claim") {
      return GET_CLAIM_ACTIVITY.format(id);
    } else {
      return;
    }
  }

  getActivity(page) {
    const url = this.getURL(
      this.props.type,
      this.props.id,
      this.props.projectId,
    );

    let query = {};

    if (this.props.type === "claim") {
      // Completion page does not have page or limit
      query = {};
    } else if (this.state.currentBoard === "following") {
      query = { page: page, limit: LOAD_MORE_COUNT, type: "following" };
    } else {
      // Sort support to have UI support in the future
      let orderBy = null;

      if (this.props.type === "challenge") {
        let pinnedMode = urlServices.getUrlParamValueByKey("pinned");

        if (pinnedMode && pinnedMode === "mine") {
          orderBy = "my_completion_first";
        }
      }

      if (orderBy) {
        query = {
          page: page,
          limit: LOAD_MORE_COUNT,
          order: orderBy,
        };
      } else {
        query = { page: page, limit: LOAD_MORE_COUNT };
      }
    }

    getApiGenerator(url, query, this.props.sessionKey).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            claims: [],
            more: false,
            page: 1,
            isLoadingMore: false,
          });
        }
      } else {
        if (this.props.type === "claim") {
          const claims = [res.body];
          this.setState({
            claims: claims,
            isLoadingMore: false,
          });
        } else {
          this.setState({
            claims: this.state.claims
              ? this.state.claims.slice().concat(res.body.data)
              : res.body.data,
            page: this.state.page + 1,
            more: res.body.more,
            isLoadingMore: false,
          });
        }
        this.props.setProject(res.body.game);

        if (res.body.game && res.body.game.id) {
          this.getProjectButtons(res.body.game.id);
        }

        if (res.body.quest) {
          this.props.setTopic(res.body.quest);
        }
      }
    });
  }

  getMedia(page) {
    const url = this.getURL(
      this.props.type,
      this.props.id,
      this.props.projectId,
    );
    let query = {
      page: page,
      limit: LOAD_MORE_COUNT * 2,
      filter: this.state.filterType,
    };
    getApiGenerator(url, query, this.props.sessionKey).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            media: [],
            more: false,
            page: 1,
            isLoadingMore: false,
          });
        }
      } else {
        if (this.props.type === "claim") {
          const media = [res.body];
          this.setState({
            media: media,
            isLoadingMore: false,
          });
        } else {
          this.setState({
            media: this.state.media
              ? this.state.media.slice().concat(res.body.data)
              : res.body.data,
            page: this.state.page + 1,
            more: res.body.more,
            isLoadingMore: false,
          });
        }
        this.props.setProject(res.body.game);

        if (res.body.game && res.body.game.id) {
          this.getProjectButtons(res.body.game.id);
        }

        if (res.body.quest) {
          this.props.setTopic(res.body.quest);
        }
      }
    });
  }

  getProjectButtons(projectId) {
    getApiGenerator(
      GET_PROJECT_BUTTONS.format(projectId),
      {},
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        this.props.setButtons(null);
      } else {
        const BUTTONS =
          res.body.data && res.body.data.length > 0 ? res.body.data : null;

        this.props.setButtons(BUTTONS);
      }
    });
  }

  handleMore = debounce(() => {
    const {
      getActivity,
      getMedia,
      state: { more, isLoadingMore, page },
    } = this;
    if (!more) {
      return;
    } else if (!isLoadingMore && more) {
      if (listenerServices.isAtScrollThreshold()) {
        this.setState(() => ({
          isLoadingMore: true,
        }));
        if (this.state.currentBoard === "media") {
          getMedia(page);
        } else {
          getActivity(page);
        }
      }
    }
  }, 100);

  handleLike = (event, id) => {
    likeClaim(event, id, this.props.sessionKey).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error) {
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error",
          });
        }
      } else {
        if (res.body.actionPoints && res.body.actionPoints > 0) {
          this.props.addProfileDrawerPoints(res.body.actionPoints);
          this.props.showAwardableActionWithTimeout({
            numberStr: "" + res.body.actionPoints.abbreviateNumber(),
            unit: res.body.actionPoints.localize(
              "point_just_text",
              "points_just_text",
              this.props.language,
              false,
            ),
          });
        }
        if (res.body.items) {
          this.props.showAchievements(res.body.items);
        }
        const claims = this.state.claims.slice();
        const claim = claims.filter((claim) => claim.id === id)[0];
        claim.ratedLike = res.body.rated;
        claim.likeNo = res.body.likeNo;
        this.setState({
          claims: claims,
        });
      }
    });
  };

  handleComments = (id, commentNo) => {
    const claims = this.state.claims.slice();
    const claim = claims.filter((claim) => claim.id === id)[0];
    claim.commentNo = commentNo;
    this.setState({
      claims: claims,
    });
  };

  handleToggleBoard = (event) => {
    this.setState(
      {
        currentBoard: event.currentTarget.id,
        page: 1,
        more: true,
        claims: null,
        media: null,
      },
      () => {
        if (this.state.currentBoard === "media") {
          this.getMedia(1);
        } else {
          this.getActivity(1);
        }
      },
    );
  };

  renderBoardNav() {
    return (
      <div className="container toppadding-10">
        <nav className="nav-buttons activity-nav-buttons">
          <button
            id="activity"
            className={
              "button " +
              (this.state.currentBoard === "activity" ? "active" : "inactive")
            }
            onClick={this.handleToggleBoard}
          >
            <span>
              <i className="fas fa-list-alt"></i>
              {localize("button_home_activity", this.props.language)}
            </span>
          </button>
          <button
            id="media"
            className={
              "button " +
              (this.state.currentBoard === "media" ? "active" : "inactive")
            }
            onClick={this.handleToggleBoard}
          >
            <span>
              <i className="fas fa-photo-video"></i>
              {this.state.filterType === "media" &&
                localize("nav_bar_title_media_text", this.props.language)}
              {this.state.filterType === "image" &&
                localize("button_home_photos", this.props.language)}
              {this.state.filterType === "video" &&
                localize("nav_bar_title_video_text", this.props.language)}
            </span>
          </button>
          <button
            id="following"
            className={
              "button " +
              (this.state.currentBoard === "following" ? "active" : "inactive")
            }
            onClick={this.handleToggleBoard}
          >
            <span>
              <i className="fas fa-user-friends"></i>
              {localize("button_following_activity", this.props.language)}
            </span>
          </button>
        </nav>
      </div>
    );
  }

  renderActivityPage() {
    if (
      (this.state.claims &&
        this.state.claims.length !== 0 &&
        this.state.currentBoard === "activity") ||
      (this.state.media &&
        this.state.media.length !== 0 &&
        this.state.currentBoard === "media") ||
      (this.state.claims &&
        this.state.claims.length !== 0 &&
        this.state.currentBoard === "following")
    ) {
      return (
        <ActivityPage
          isLoadingMore={this.state.isLoadingMore}
          type={this.props.type}
          projectId={this.props.projectId || 0}
          userId={this.props.userId}
          claims={this.state.claims}
          media={this.state.media}
          more={this.state.more}
          handleMore={this.handleMore}
          board={this.state.currentBoard}
          handleToggleBoard={this.handleToggleBoard}
          handleLike={this.handleLike}
          removeClaim={this.removeClaim}
          handleComments={this.handleComments}
          language={this.props.language}
        />
      );
    } else if (this.state.claims && this.state.currentBoard === "activity") {
      return (
        <GenericErrorPage
          message={localize("empty_claim_table_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else if (this.state.media && this.state.currentBoard === "media") {
      return (
        <GenericErrorPage
          message={localize("empty_media_table_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else if (this.state.claims && this.state.currentBoard === "following") {
      return (
        <GenericErrorPage
          message={localize("empty_claim_table_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.type !== "claim" && this.renderBoardNav()}
        {this.renderActivityPage()}
      </React.Fragment>
    );
  }
}

ActivityContainer.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(ActivityContainer);
