import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import SearchBar from "components/shared/SearchBar/SearchBar";
import AdvancedSelectInput from "components/shared/Inputs/AdvancedSelectInput";

import Modal from "react-modal";

import localize from "lang/localize";

const propTypes = {
  showBoardFilterDialog: PropTypes.bool.isRequired,
  handleCloseBoardFilterDialog: PropTypes.func.isRequired,
  handleSearchInput: PropTypes.func.isRequired,
  handleBoardFilters: PropTypes.func.isRequired,
  handleFilterReset: PropTypes.func.isRequired,
  currentBoard: PropTypes.object, // current board (filtered/initial)
  defaultBoard: PropTypes.object, // initial board
  selectedOption: PropTypes.object, // selected option (board)
  search: PropTypes.string,
  sort: PropTypes.string,
  language: PropTypes.string,
  projectId: PropTypes.string,
  boards: PropTypes.array,
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

class BoardFilterDialog extends Component {
  /**
   * Constructor
   */
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showBoardFilterDialog,
      activeBoard: this.props.currentBoard ? this.props.currentBoard.id : 0,
      activeSort: this.props.sort,
      activeSearch: this.props.search,
    };
  }

  handleReset = () => {
    if (document.getElementById("searchInput")) {
      // defaultValue cannot be changed after mount, so we use native JS codes here
      document.getElementById("searchInput").value = "";
    }

    this.setState({
      activeBoard: this.props.currentBoard ? this.props.currentBoard.id : 0,
      activeSort: null,
      activeSearch: null,
    });

    this.props.handleFilterReset();
  };

  handleSearchChange = (event) => {
    this.props.handleSearchInput(event);
  };

  handleSortToggle = (sort) => {
    // Use state to update value in UI
    this.setState({ activeSort: sort });
    this.props.handleSortToggle(sort);
  };

  handleBoardChange = (option) => {
    this.props.handleBoardChange(option);
  };

  handleBoardFilters = (event) => {
    this.props.handleBoardFilters(event);
  };

  /**
   * Handle close dialog
   */
  handleCloseDialog = () => {
    this.props.handleFilterReset();
    this.props.handleCloseBoardFilterDialog();
  };

  /**
   * Display search bar
   */
  renderSearchBar() {
    return (
      <Fragment>
        <div className="verticalpadding-5" />
        <SearchBar
          handleSearchChange={this.handleSearchChange}
          placeholder={localize(
            "general_search_placeholder_text",
            this.props.language,
          )}
          language={this.props.language}
          // default value cannot be changed by state
          defaultValue={this.state.activeSearch || ""}
          button={null}
        />
      </Fragment>
    );
  }

  /**
   * Display search form
   */
  renderSearch() {
    return (
      <div className="discussions-board-search">
        <div className="discussions-board-filter-title">
          <strong>
            {localize("general_search_placeholder_text", this.props.language)}:
          </strong>
        </div>
        <div className="discussions-board-search-input" id="postSearch">
          {this.renderSearchBar()}
        </div>
      </div>
    );
  }

  /**
   * Display chips for sorting
   */
  renderSort() {
    return (
      <div className="discussions-board-sort-section">
        <strong className="discussions-board-sort-title">
          {localize("title_sort", this.props.language)}
        </strong>

        <div className="discussions-board-sort">
          <button
            id="discussions-board-sort-latest"
            className={
              "discussions-board-sort-chip button inline" +
              (!!!this.state.activeSort ||
              (!!this.state.activeSort && this.state.activeSort === "latest")
                ? " active"
                : "")
            }
            onClick={() => {
              this.handleSortToggle("latest");
            }}
          >
            {localize("sort_latest", this.props.language)}
          </button>
          <button
            id="discussions-board-sort-votes"
            className={
              "discussions-board-sort-chip button inline" +
              (!!this.state.activeSort && this.state.activeSort === "most_voted"
                ? " active"
                : "")
            }
            onClick={() => {
              this.handleSortToggle("most_voted");
            }}
          >
            {localize("sort_top_rated", this.props.language)}
          </button>
          <button
            id="discussions-board-sort-commented"
            className={
              "discussions-board-sort-chip button inline" +
              (!!this.state.activeSort &&
              this.state.activeSort === "most_bookmarked"
                ? " active"
                : "")
            }
            onClick={() => {
              this.handleSortToggle("most_bookmarked");
            }}
          >
            {localize("sort_popular", this.props.language)}
          </button>
        </div>
      </div>
    );
  }

  /**
   * Display board filter
   */
  renderBoardFilter() {
    return (
      <div className="discussions-board-filter-section">
        <div className="discussions-board-filter-header flex">
          <div className="discussions-board-filter-header-title flex">
            <strong>{localize("filter_option", this.props.language)}</strong>
          </div>
          {/* TODO: Implement reset function - hyperlink to main discussions page */}
          <div
            className="discussions-board-filter-header-reset cursor-pointer link"
            onClick={() => {
              this.handleReset();
            }}
          >
            {localize("button_reset", this.props.language)}
          </div>
        </div>
        <div className="discussions-board-filter">
          <div className="discussions-board-filter-title">
            <strong>
              {localize("discussion_board", this.props.language)}:
            </strong>
          </div>
          <div className="discussions-board-filter-list">
            {this.renderBoardList()}
          </div>
        </div>
      </div>
    );
  }

  /**
   * Display dropdown list of boards
   */
  renderBoardList() {
    return (
      <Fragment>
        <div className="verticalpadding-5" />
        {this.props.boards &&
          Array.isArray(this.props.boards) &&
          this.props.boards.length > 0 && (
            <div className="pure-g pure-form">
              <AdvancedSelectInput
                name="boardSelection"
                className="boardFilter bottommargin dialog-discussions-board-filter"
                // default option cannot be changed by state
                defaultOption={this.props.defaultBoard}
                selectedOption={
                  !!this.props.selectedOption
                    ? this.props.selectedOption
                    : this.props.defaultBoard
                      ? {
                          value: this.props.defaultBoard.id,
                          label: this.props.defaultBoard.boardTitle,
                        }
                      : null
                }
                options={this.props.boards.map((board) => ({
                  value: board.id,
                  label: board.boardTitle,
                }))}
                placeholder={localize(
                  "discussion_board_select",
                  this.props.language,
                )}
                onChange={this.props.handleBoardChange}
                disabled={!!this.props.defaultBoard}
                isPopup={true}
                //clearable={true}
              />
            </div>
          )}
      </Fragment>
    );
  }

  /**
   * Renders the modal with dialog box.
   */
  render() {
    return (
      <Modal
        isOpen={this.state.showModal}
        onRequestClose={this.handleCloseDialog}
        shouldCloseOnOverlayClick={false}
        className="dialog discussions-board-filter-dialog"
        overlayClassName="dialog-overlay"
      >
        <div className="dialog-content">
          <div className="dialog-header">
            <button
              onClick={this.handleCloseDialog}
              className="close-button-container"
            >
              <i className="fa close-button" aria-hidden="true" />
            </button>
          </div>
          <div className="dialog-body discussions-board-filter-form">
            {this.renderSort()}
            {this.renderBoardFilter()}
            {this.renderSearch()}
            <div className="discussions-discussions-board-filters-submit">
              <button
                className="button cta fullwidth"
                onClick={this.handleBoardFilters}
              >
                {localize("button_apply", this.props.language)}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

BoardFilterDialog.propTypes = propTypes;

export default BoardFilterDialog;
