import React, { Component } from "react";
import PropTypes from "prop-types";

import ViewSDKClient from "./ViewSDKClient";

import { PDF_DOWNLOAD_LINK } from "config";

const propTypes = {
  id: PropTypes.string,
  url: PropTypes.string,
  encryptedUrl: PropTypes.string,
  filename: PropTypes.string,
  popup: PropTypes.bool,
};

// API Docs for Adobe PDF Embed API: https://developer.adobe.com/document-services/apis/pdf-embed/
class AdobePdfViewer extends Component {
  componentDidMount = () => {
    const viewSDKClient = new ViewSDKClient();
    const ENCODED_LINK = encodeURIComponent(this.props.url);
    const fileUrl = this.props.encryptedUrl
      ? this.props.encryptedUrl
      : PDF_DOWNLOAD_LINK.format(ENCODED_LINK);

    viewSDKClient.ready().then(() => {
      /* Invoke file preview */
      viewSDKClient.previewFile(
        this.props.id ? this.props.id : "adobePdfView",
        fileUrl,
        this.props.filename,
        {
          /*
            Embed Modes
            ------------------------------------------------------
            SIZED_CONTAINER:
            - No zoom buttons in desktop
            - Only viewable in fullscreen mode in mobile (user agent detection)
            - Fullscreen button doesn't work in iOS!
            - Fullscreen mode is in landscape only for Android.

            IN_LINE:
            - Not scrollable, need to set taller height

            FULL_WINDOW:
            - Works better for desktop as there are more menus
            - Works better for Android compared to SIZED_CONTAINER
            ------------------------------------------------------

            Existing issues:
            1) Android: fullscreen mode is in landscape ONLY
            2) iOS: fullscreen button doesn't work
            3) Unable to use expand icon and "handleOpenEmbedDialog" since this is not an iframe!

            Other options:
            https://github.com/adobe/pdf-embed-api-samples/blob/master/More%20Samples/React%20Samples/src/samples/ViewerCustomization.js
          */
          embedMode: this.props.popup ? "LIGHT_BOX" : "FULL_WINDOW",
          defaultViewMode: "SINGLE_PAGE", // optimal display for mobile, not supported for desktop
          showZoomControl: true, // irrelevant for mobile
          showFullScreen: false, // always hide because we have our own
          showThumbnails: false, // irrelevant for mobile
          showAnnotationTools: false, // irrelevant for mobile
          showBookmarks: false, // irrelevant for mobile
          includePDFAnnotations: false, // irrelevant for mobile
          showDownloadPDF: true, // download option doesn't seem to show up in mobile
          showPrintPDF: false, // unsure if we should allow print, keep it simple for now
        },
      );
    });
  };

  render() {
    return (
      <div
        id={this.props.id ? this.props.id : "adobePdfView"}
        className="adobe-pdf-viewer-wrapper"
      />
    );
  }
}

AdobePdfViewer.propTypes = propTypes;

export default AdobePdfViewer;
