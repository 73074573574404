import React, { Component } from "react";
import PropTypes from "prop-types";

import onClickOutside from "react-onclickoutside";

let classnames = require("classnames");

const propTypes = {
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  iconResting: PropTypes.string,
  iconActive: PropTypes.string,
  label: PropTypes.string,
};

const defaultProps = {
  href: "#",
  onClick: function () {},
  iconResting: "",
  iconActive: "",
  label: null,
};

class MainButton extends Component {
  render() {
    /* Mfb Default: Mfb icons */
    // let iconResting = classnames('mfb-component__main-icon--resting', this.props.iconResting);
    // let iconActive = classnames('mfb-component__main-icon--active', this.props.iconActive);

    /* New: Font Awesome icons */
    // let iconResting = classnames("fa", this.props.iconResting);
    // let iconActive = classnames("fa", this.props.iconActive);

    let mainClass = classnames(
      "mfb-component__button--main",
      this.props.className,
    );

    if (this.props.label) {
      return (
        <a
          href={this.props.href}
          style={this.props.style}
          className={mainClass}
          onClick={this.props.onClick}
          data-mfb-label={this.props.label}
        >
          <div className="floating-menu-icon floating-menu-icon-color">
            <span />
            <span className="fas fa-times font-size-lg" />
          </div>
        </a>
      );
    } else {
      return (
        <a
          href={this.props.href}
          style={this.props.style}
          className={mainClass}
          onClick={this.props.onClick}
        >
          <div className="floating-menu-icon floating-menu-icon-color">
            <span />
            <span className="fas fa-times font-size-lg" />
          </div>
        </a>
      );
    }
  }
}

MainButton.propTypes = propTypes;
MainButton.defaultProps = defaultProps;

export default onClickOutside(MainButton);
