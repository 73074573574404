import React, { Component } from "react";
import { connect } from "react-redux";

import ChallengeCompletedModal from "./ChallengeCompletedModal";

import { clearChallengeCompleted } from "actions";

/* for routing after challenge completed */
import Router from "router";
import { TOPIC, CHALLENGE, CHALLENGE_A_FRIEND } from "App/Routes";

export const mapStateToProps = (state, ownProps) => {
  return {
    challengeCompletedArr: state.challengeCompleted,
    language: state.language,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    clearChallengeCompleted: (id) => {
      dispatch(clearChallengeCompleted(id));
    },
  };
};

export class ChallengeCompletedModalContainer extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      showShareDialog: false,
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.goToNextChallenge = this.goToNextChallenge.bind(this);
    this.goToChallengeAFriend = this.goToChallengeAFriend.bind(this);
    this.goToNextTopic = this.goToNextTopic.bind(this);
    this.handleOpenShareDialog = this.handleOpenShareDialog.bind(this);
    this.handleCloseShareDialog = this.handleCloseShareDialog.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.challengeCompletedArr !== prevProps.challengeCompletedArr) {
      this.handleOpenModal();
    }
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal(challengeCompletedId) {
    this.setState({ showModal: false }, () => {
      this.props.clearChallengeCompleted(challengeCompletedId);
    });
  }

  /**
   * Open "Share result" popup dialog
   *
   * @param {object} event - Event
   */
  handleOpenShareDialog(event) {
    event.preventDefault();

    this.setState({ showShareDialog: true });
  }

  /**
   * Close "Share result" popup dialog
   */
  handleCloseShareDialog() {
    this.setState({ showShareDialog: false });
  }

  goToNextTopic(topicIdNext, lastChallengeCompletedId) {
    // 1. handleCloseModal must be at the end, otherwise there will be errors unmounting
    // 2. Since there may be race issues with routing and closing of popup, use a timeout
    setTimeout(() => {
      Router.navigate(TOPIC.format(topicIdNext));
    }, 100);
    this.handleCloseModal(lastChallengeCompletedId);
  }

  goToNextChallenge(nextChallengeId, lastChallengeCompletedId) {
    // 1. handleCloseModal must be at the end, otherwise there will be errors unmounting
    // 2. Since there may be race issues with routing and closing of popup, use a timeout
    setTimeout(() => {
      Router.navigate(CHALLENGE.format(nextChallengeId));
    }, 100);
    this.handleCloseModal(lastChallengeCompletedId);
  }

  goToChallengeAFriend(challengeId, lastChallengeCompletedId) {
    // 1. handleCloseModal must be at the end, otherwise there will be errors unmounting
    // 2. Since there may be race issues with routing and closing of popup, use a timeout
    setTimeout(() => {
      Router.navigate(CHALLENGE_A_FRIEND.format(challengeId));
    }, 100);
    this.handleCloseModal(lastChallengeCompletedId);
  }

  render() {
    return (
      <ChallengeCompletedModal
        challengeCompletedArr={this.props.challengeCompletedArr}
        showModal={this.state.showModal}
        handleCloseModal={this.handleCloseModal}
        goToNextChallenge={this.goToNextChallenge}
        goToChallengeAFriend={this.goToChallengeAFriend}
        language={this.props.language}
        goToNextTopic={this.goToNextTopic}
        isShareDialogOpen={this.state.showShareDialog}
        handleOpenShareDialog={this.handleOpenShareDialog}
        handleCloseShareDialog={this.handleCloseShareDialog}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChallengeCompletedModalContainer);
