import React from "react";
import { connect } from "react-redux";

import {
  mapStateToProps,
  mapDispatchToProps,
  LoginContainer,
} from "./LoginContainer";
import LoginSSOPage from "./LoginSSOPage";

export class LoginSSOContainer extends LoginContainer {
  render() {
    return (
      <LoginSSOPage
        topbarlessBackButtonRoute={this.getTopbarlessBackButtonRoute()}
        language={this.props.language}
        /* OAuth */
        responseFacebook={this.responseFacebook}
        responseFacebookError={this.responseFacebookError}
        responseMicrosoft={this.responseMicrosoft}
        responseGoogle={this.responseGoogle}
        responseGoogleError={this.responseGoogleError}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginSSOContainer);
