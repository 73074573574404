import React, { Component } from "react";
import PropTypes from "prop-types";

import { PROJECT_USER, USER, PROJECT_PROFILE, PROFILE } from "App/Routes";
import Link from "components/shared/Link/Link";

const propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  userRankStatus: PropTypes.string,
  userRankStatusImage: PropTypes.string,
  language: PropTypes.string,
  projectId: PropTypes.number,
  userId: PropTypes.number,
};
// should use default pic

class Member extends Component {
  getMemberLink() {
    if (this.props.id === this.props.userId && this.props.projectId) {
      return PROJECT_PROFILE.format(this.props.projectId);
    } else if (this.props.id === this.props.userId) {
      return PROFILE;
    } else if (this.props.projectId) {
      return PROJECT_USER.format(this.props.projectId, this.props.id);
    } else {
      return USER.format(this.props.id);
    }
  }

  render() {
    let image = this.props.image;
    let name = this.props.name;
    let link = this.getMemberLink();

    return (
      <Link
        className="pure-u-1 flex align-items-center bottompadding team-member-container"
        to={link}
      >
        <div className="pure-u-2-24" />
        <div className="pure-u-4-24 team-member-image-container">
          <div className="floatright rightmargin">
            <img className="iconsmall" src={image} alt={name} />
            {this.props.userRankStatusImage && (
              <div id="rankStatus" className="photo-icon-wrap">
                <img
                  className="rank-status"
                  src={this.props.userRankStatusImage}
                  alt={this.props.userRankStatus}
                />
              </div>
            )}
          </div>
        </div>
        <div className="pure-u-16-24 team-member-name-points">
          <p className="nomargin pure-u-1-2 pure-u-md-1-1 team-member-name">
            <strong>{name}</strong>
          </p>
          <p className="nomargin pure-u-1-2 pure-u-md-1-1 team-member-points">
            {this.props.points.localize(
              "point_text",
              "points_text",
              this.props.language,
              true,
            )}
          </p>
        </div>
        <div className="pure-u-2-24" />
      </Link>
    );
  }
}

Member.propTypes = propTypes;

export default Member;
