import React, { Component } from "react";
import PropTypes from "prop-types";

import Reward from "./Reward";

const propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  descriptionAfterRedeem: PropTypes.string,
  img: PropTypes.string,
  points: PropTypes.number,
  available: PropTypes.number,
  owned: PropTypes.number,
  ownedLimit: PropTypes.number,
  usedAtFormatted: PropTypes.string,
  handleClick: PropTypes.func,
  adminOnly: PropTypes.bool,
  options: PropTypes.array,
  type: PropTypes.string,
  language: PropTypes.string,
  projectId: PropTypes.number,
  loggedIn: PropTypes.bool.isRequired,
  userPoints: PropTypes.number,
  unlockable: PropTypes.bool,
  locked: PropTypes.bool,
};

class RewardContainer extends Component {
  constructor() {
    super();
    this.state = {
      showRedeemLoginDialog: false,
      showRedeemRewardDialog: false,
    };

    this.handleOpenRedeemLoginDialog =
      this.handleOpenRedeemLoginDialog.bind(this);
    this.handleCloseRedeemLoginDialog =
      this.handleCloseRedeemLoginDialog.bind(this);
    this.handleOpenRedeemRewardDialog =
      this.handleOpenRedeemRewardDialog.bind(this);
    this.handleCloseRedeemRewardDialog =
      this.handleCloseRedeemRewardDialog.bind(this);
  }

  handleOpenRedeemLoginDialog(e) {
    e.preventDefault();

    this.setState({
      showRedeemLoginDialog: true,
    });
  }

  handleCloseRedeemLoginDialog() {
    this.setState({
      showRedeemLoginDialog: false,
    });
  }

  handleOpenRedeemRewardDialog(e) {
    e.preventDefault();

    this.setState({
      showRedeemRewardDialog: true,
    });
  }

  handleCloseRedeemRewardDialog() {
    this.setState({
      showRedeemRewardDialog: false,
    });
  }

  render() {
    return (
      <Reward
        id={this.props.id}
        title={this.props.title}
        description={this.props.description}
        descriptionAfterRedeem={this.props.descriptionAfterRedeem}
        img={this.props.img}
        points={this.props.points}
        available={this.props.available}
        owned={this.props.owned}
        ownedLimit={this.props.ownedLimit}
        usedAtFormatted={this.props.usedAtFormatted}
        handleClick={this.props.handleClick}
        adminOnly={this.props.adminOnly}
        options={this.props.options}
        type={this.props.type}
        language={this.props.language}
        projectId={this.props.projectId}
        loggedIn={this.props.loggedIn}
        userPoints={this.props.userPoints}
        unlockable={this.props.unlockable}
        locked={this.props.locked}
        // login dialogs
        showRedeemLoginDialog={this.state.showRedeemLoginDialog}
        handleOpenRedeemLoginDialog={this.handleOpenRedeemLoginDialog}
        handleCloseRedeemLoginDialog={this.handleCloseRedeemLoginDialog}
        // redeem reward dialog
        showRedeemRewardDialog={this.state.showRedeemRewardDialog}
        handleOpenRedeemRewardDialog={this.handleOpenRedeemRewardDialog}
        handleCloseRedeemRewardDialog={this.handleCloseRedeemRewardDialog}
      />
    );
  }
}

RewardContainer.propTypes = propTypes;

export default RewardContainer;
