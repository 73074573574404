import PropTypes from "prop-types";
import "./Certificates.css";

const propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  img: PropTypes.string,
  userId: PropTypes.number,
  revoked: PropTypes.bool,
  encryptionKey: PropTypes.string,
  url: PropTypes.string,
  language: PropTypes.string,
  projectId: PropTypes.number,
  sessionKey: PropTypes.string,
};

const Certificate = (props) => {
  return (
    <div className="card">
      <a href={props.url} target="_blank" rel="noreferrer" className="link">
        <div className="pure-g bottommargin-10">
          <div className="pure-u-1-4">
            <span className="square-image-wrapper">
              <span className="square-image circle">
                <img src={props.img} alt={props.title} />
                {props.revoked && <div className="circle revokeoverlay" />}
              </span>
            </span>
          </div>
          <div className="pure-u-1-24" />
          <div className="pure-u-17-24 relative">
            <p className="center">{props.title}</p>
          </div>
        </div>
      </a>
    </div>
  );
};

Certificate.propTypes = propTypes;

export default Certificate;
