import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import debounce from "lodash.debounce";

import TeamPage from "./TeamPage";
import Loading from "components/shared/Loading";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";

import { LOAD_MORE_COUNT } from "config";
import { setProject, setButtons, showAlertWithTimeout } from "actions";
import { GET_TEAM, GET_TOPICS, JOIN_TEAM, UNJOIN_TEAM } from "services/api";
import { PROJECT_TEAMS } from "App/Routes";
import Router from "router";
import getApiGenerator from "services/getApiGenerator";
import listenerServices from "services/listenerServices";
import localize from "lang/localize";

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    language: state.language,
    projectId: state.projectId,
    user: state.user,
    userId: state.user ? state.user.id : null,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setProject: (project) => {
      dispatch(setProject(project));
    },
    setButtons: (buttons) => {
      dispatch(setButtons(buttons));
    },
    showAlertWithTimeout: (alert) => {
      dispatch(showAlertWithTimeout(alert));
    },
  };
};

const propTypes = {
  id: PropTypes.string.isRequired,
};

export class TeamContainer extends Component {
  constructor() {
    super();
    this.state = {
      team: null,
      members: null,
      more: false,
      page: 1,
      joined: false,
      teamCreator: false,
      teamCreateEnabled: false,
      teamJoinEnabled: false,
      teamJoinedInProject: false,
      searchInput: "",
      beganSearch: false,
      topics: null,
      teamError: null,
      projectError: null,
      projectErrorCode: null,
      isLoadingMore: false,
      showLoginDialog: false,
    };

    this.handleOpenLoginDialog = this.handleOpenLoginDialog.bind(this);
    this.handleCloseLoginDialog = this.handleCloseLoginDialog.bind(this);
    this.handleMore = this.handleMore.bind(this);
    this.getTeam = this.getTeam.bind(this);
  }

  componentDidMount() {
    /* Retrieve Project information */
    if (this.props.projectId) {
      this.getTopics();
    }

    this.getTeam(this.state.page);
    window.addEventListener("scroll", this.handleMore);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleMore);
  }

  handleMore = debounce(() => {
    const {
      getTeam,
      state: { more, isLoadingMore, page },
    } = this;
    if (!more) {
      return;
    } else if (!isLoadingMore && more) {
      if (listenerServices.isAtScrollThreshold()) {
        this.setState(() => ({
          isLoadingMore: true,
        }));
        getTeam(page);
      }
    }
  }, 100);

  handleSearchChange(event) {
    if (!this.state.beganSearch) {
      this.setState({ beganSearch: true });
    }

    this.setState({ searchInput: event.target.value }, () =>
      this.getTeam(1, this.state.searchInput),
    );
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  handleLeaveTeam(event) {
    event.preventDefault();

    if (
      !!this.state.team &&
      !!this.state.team.id &&
      !!this.state.teamJoinEnabled
    ) {
      getApiGenerator(
        UNJOIN_TEAM,
        {
          type_id: this.state.team.id,
        },
        this.props.sessionKey,
      ).end((err, res) => {
        if (err || res.body.code !== 200) {
          if (res.body.error) {
            this.props.showAlertWithTimeout({
              text: res.body.error,
              type: "error",
            });
          }
        } else {
          Router.navigate(PROJECT_TEAMS.format(this.props.projectId));
          this.props.showAlertWithTimeout({
            text: localize("team_leave_success_text", this.props.language),
            type: "success",
          });
        }
      });
    }
  }

  handleJoinTeam(event) {
    event.preventDefault();

    if (
      !!this.state.team &&
      !!this.state.team.id &&
      !!this.state.teamJoinEnabled
    ) {
      getApiGenerator(
        JOIN_TEAM,
        {
          type_id: this.state.team.id,
        },
        this.props.sessionKey,
      ).end((err, res) => {
        if (err || res.body.code !== 200) {
          if (res.body.error) {
            this.props.showAlertWithTimeout({
              text: res.body.error,
              type: "error",
            });
          }
        } else {
          /* refresh page */
          this.getTeam(
            this.state.page === 1 ? this.state.page : this.state.page - 1,
          );
          this.props.showAlertWithTimeout({
            text: localize("team_join_success_text", this.props.language),
            type: "success",
          });
        }
      });
    }
  }

  getTeam(page, keywords = null) {
    getApiGenerator(
      GET_TEAM.format(this.props.id),
      {
        page: page,
        keywords: keywords,
        limit: LOAD_MORE_COUNT,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            team: res.body.team || null,
            teamCreator: !!res.body.teamCreator,
            teamCreateEnabled: res.body.game
              ? !!res.body.game.teamCreateEnabled
              : false,
            teamJoinEnabled: res.body.game
              ? !!res.body.game.teamJoinEnabled
              : false,
            members: [],
            more: false,
            teamJoinedInProject: !!res.body.teamJoinedInProject,
            page: 1,
            teamError: res.body.error,
            isLoadingMore: false,
          });
        }
      } else {
        if (page === 1) {
          this.setState({
            team: res.body.team,
            teamCreator: !!res.body.teamCreator,
            teamCreateEnabled: res.body.game
              ? !!res.body.game.teamCreateEnabled
              : false,
            teamJoinEnabled: res.body.game
              ? !!res.body.game.teamJoinEnabled
              : false,
            members: res.body.data,
            more: res.body.more,
            joined: res.body.joined !== undefined ? res.body.joined : false,
            teamJoinedInProject: !!res.body.teamJoinedInProject,
            page: page + 1,
            isLoadingMore: false,
          });
        } else {
          this.setState({
            team: res.body.team,
            teamCreator: !!res.body.teamCreator,
            teamCreateEnabled: res.body.game
              ? !!res.body.game.teamCreateEnabled
              : false,
            teamJoinEnabled: res.body.game
              ? !!res.body.game.teamJoinEnabled
              : false,
            members: this.state.members
              ? this.state.members.slice().concat(res.body.data)
              : res.body.data,
            more: res.body.more,
            joined: res.body.joined !== undefined ? res.body.joined : false,
            teamJoinedInProject: !!res.body.teamJoinedInProject,
            page: this.state.page + 1,
            isLoadingMore: false,
          });
        }
      }
    });
  }

  getTopics() {
    getApiGenerator(
      GET_TOPICS.format(this.props.projectId),
      {
        page: 1,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            topics: [],
            projectErrorCode: 500,
            projectError: res.body.error,
          });
        }
      } else {
        this.setState({
          topics: res.body.data,
          projectErrorCode: res.body.code,
          projectError: "",
        });

        const BUTTONS =
          res.body.buttons && res.body.buttons.length > 0
            ? res.body.buttons
            : null;

        this.props.setProject(res.body.game);
        this.props.setButtons(BUTTONS);
      }
    });
  }

  handleOpenLoginDialog() {
    this.setState({
      showLoginDialog: true,
    });
  }

  handleCloseLoginDialog() {
    this.setState({
      showLoginDialog: false,
    });
  }

  render() {
    if (
      /* Project ID is available, but Project is private */
      this.props.projectId &&
      this.state.projectErrorCode === 500 &&
      this.state.projectError.indexOf("private") !== -1
    ) {
      return (
        <GenericErrorPage
          message={localize(
            "bundle_private_logged_in_text",
            this.props.language,
          )}
          language={this.props.language}
        />
      );
    } else if (
      /* Project ID is available, but Project does not exist */
      this.props.projectId &&
      Array.isArray(this.state.topics) &&
      (this.state.projectError.indexOf("not exist") !== -1 ||
        this.state.projectError.indexOf("not published") !== -1)
    ) {
      return (
        <GenericErrorPage
          message={localize("bundle_not_found_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else if (!this.state.team && this.state.teamError) {
      return (
        <GenericErrorPage
          message={localize("empty_teams_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else if (this.state.team && this.state.members) {
      return (
        <TeamPage
          isLoadingMore={this.state.isLoadingMore}
          teamId={this.state.team.id}
          teamName={this.state.team.name}
          teamDescription={this.state.team.description}
          teamImage={this.state.team.image}
          members={this.state.members}
          points={
            this.state.team && this.state.team.points
              ? this.state.team.points
              : 0
          }
          more={this.state.more}
          joined={this.state.joined}
          teamCreator={this.state.teamCreator}
          teamCreateEnabled={this.state.teamCreateEnabled}
          teamJoinEnabled={this.state.teamJoinEnabled}
          teamJoinedInProject={this.state.teamJoinedInProject}
          handleMore={() => this.handleMore()}
          handleSearchChange={this.handleSearchChange.bind(this)}
          handleSubmit={this.handleSubmit.bind(this)}
          handleJoinTeam={this.handleJoinTeam.bind(this)}
          handleLeaveTeam={this.handleLeaveTeam.bind(this)}
          language={this.props.language}
          projectId={this.props.projectId}
          user={this.props.user}
          userId={this.props.userId}
          // login dialogs
          showLoginDialog={this.state.showLoginDialog}
          handleOpenLoginDialog={this.handleOpenLoginDialog}
          handleCloseLoginDialog={this.handleCloseLoginDialog}
          // Session key to check if user logged in
          sessionKey={this.props.sessionKey}
        />
      );
    } else {
      return <Loading />;
    }
  }
}

TeamContainer.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(TeamContainer);
