import React, { Component } from "react";
import PropTypes from "prop-types";

import QRModal from "./QRModal/QRModal";

import deviceServices from "services/deviceServices";
import localize from "lang/localize";

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  showQRModal: PropTypes.bool.isRequired,
  handleOpenQRModal: PropTypes.func.isRequired,
  handleCloseQRModal: PropTypes.func.isRequired,
  language: PropTypes.string,
};

class QRChallenge extends Component {
  renderQRModal() {
    return (
      <QRModal
        showQRModal={this.props.showQRModal}
        handleCloseQRModal={this.props.handleCloseQRModal}
        handleSubmit={this.props.handleSubmit}
        language={this.props.language}
      />
    );
  }

  renderButtonOrDisclaimer() {
    if (deviceServices.isIOS() && !deviceServices.isSafari()) {
      return (
        <div className="alert warning nomargin">
          {localize("alert_ios_qr_fail", this.props.language)}
        </div>
      );
    } else if (this.props.isLoggedIn) {
      return (
        <div>
          <button
            className="button automargin pure-u-1-1 pure-u-md-6-24 cta "
            onClick={this.props.handleOpenQRModal}
            id="openQRModalButton"
          >
            {localize("challenge_type_qr_text", this.props.language)}
          </button>
          {this.renderQRModal()}
        </div>
      );
    } else {
      return (
        <button
          className="button automargin pure-u-1-1 pure-u-md-6-24 cta "
          id="loginBeforeOpenQRModalButton"
        >
          {localize("challenge_type_qr_text", this.props.language)}
        </button>
      );
    }
  }

  render() {
    return <div className="textcenter">{this.renderButtonOrDisclaimer()}</div>;
  }
}

QRChallenge.propTypes = propTypes;

export default QRChallenge;
