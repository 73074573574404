import React, { Component } from "react";
import PropTypes from "prop-types";
import AdvancedSelectInput from "components/shared/Inputs/AdvancedSelectInput";

import {
  AVAILABLE_LANGUAGES,
  PLATFORM_AVAILABLE_LANGUAGES_STRINGS,
} from "config";
import localize from "lang/localize";

const propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.string,
  language: PropTypes.string,
};

class LanguageSelectInput extends Component {
  render() {
    const options = AVAILABLE_LANGUAGES.map(function (option) {
      if (
        PLATFORM_AVAILABLE_LANGUAGES_STRINGS.indexOf(
          option.lang.toLowerCase(),
        ) >= 0
      ) {
        return {
          value: option.lang.toLowerCase(),
          label: option.text + " (" + option.lang + ")",
        };
      } else {
        return null;
      }
    });

    let defaultOption = null;

    if (!!this.props.selectedValue && options) {
      for (const option of options) {
        if (option.value === this.props.selectedValue) {
          defaultOption = option;
          break;
        }
      }
    }

    return (
      <form className="pure-form">
        <label htmlFor={this.props.name}>
          {localize(
            "dialog_language_selection_placeholder",
            this.props.language,
          ) + ":"}
        </label>
        <AdvancedSelectInput
          name={this.props.name}
          defaultOption={defaultOption}
          options={options}
          height={200}
          isPopup={true}
          onChange={(name, option) => {
            this.props.onChange(option.value);
          }}
        />
      </form>
    );
  }
}

LanguageSelectInput.propTypes = propTypes;

export default LanguageSelectInput;
