import React from "react";
import PropTypes from "prop-types";

import Link from "components/shared/Link/Link";

import { CHALLENGE_MY_ACTIVITY } from "App/Routes";
import localize from "lang/localize";

const propTypes = {
  id: PropTypes.number.isRequired,
  sessionKey: PropTypes.string,
  language: PropTypes.string,
};

const viewMyCompleted = (props) => {
  if (props.sessionKey != null) {
    return (
      <Link to={CHALLENGE_MY_ACTIVITY.format(props.id)}>
        <div className="container verticalpadding">
          <div className="innerblock">
            <div className="pure-u-1-24" />
            <div className="pure-u-22-24">
              <div className="pure-g">
                <div className="pure-u-23-24">
                  {localize("challenge_profile_my_claims_text", props.language)}
                </div>
                <div className="pure-u-1-24">
                  <span className="floatright">
                    <span className="right-caret alignmiddle" />
                  </span>
                </div>
              </div>
            </div>
            <div className="pure-u-1-24" />
          </div>
        </div>
        <div className="challenge-page-divider" />
      </Link>
    );
  } else {
    return null;
  }
};

viewMyCompleted.propTypes = propTypes;

export default viewMyCompleted;
