import React, { Component } from "react";
import PropTypes from "prop-types";

import Link from "components/shared/Link/Link";
import LoginDialogContainer from "components/shared/Dialogs/LoginDialog/LoginDialogContainer";
import RedeemRewardDialogContainer from "./RedeemRewardDialog/RedeemRewardDialogContainer";

import localize from "lang/localize";

const propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  descriptionAfterRedeem: PropTypes.string,
  img: PropTypes.string,
  points: PropTypes.number,
  available: PropTypes.number,
  owned: PropTypes.number,
  ownedLimit: PropTypes.number,
  usedAtFormatted: PropTypes.string,
  handleClick: PropTypes.func,
  adminOnly: PropTypes.bool,
  options: PropTypes.array,
  type: PropTypes.string,
  language: PropTypes.string,
  projectId: PropTypes.number,
  loggedIn: PropTypes.bool.isRequired,
  userPoints: PropTypes.number,
  unlockable: PropTypes.bool,
  locked: PropTypes.bool,
  // login dialogs
  showRedeemLoginDialog: PropTypes.bool.isRequired,
  handleOpenRedeemLoginDialog: PropTypes.func.isRequired,
  handleCloseRedeemLoginDialog: PropTypes.func.isRequired,
  // redeem reward dialog
  showRedeemRewardDialog: PropTypes.bool.isRequired,
  handleOpenRedeemRewardDialog: PropTypes.func.isRequired,
  handleCloseRedeemRewardDialog: PropTypes.func.isRequired,
};

const defaultProps = {
  owned: 0,
  available: null,
  ownedLimit: null,
  showPoints: false,
  usedAtFormatted: null,
};

class Reward extends Component {
  /**
   * Render login prompt dialog.
   */
  renderRedeemLoginDialog() {
    return (
      <LoginDialogContainer
        showModal={this.props.showRedeemLoginDialog}
        handleCloseLoginDialog={this.props.handleCloseRedeemLoginDialog}
      />
    );
  }

  /**
   * Render "Redeem Reward" confirmation dialog
   */
  renderRedeemRewardDialog() {
    let buttonValues = this.getButtonValues();

    return (
      <RedeemRewardDialogContainer
        showRedeemRewardDialog={this.props.showRedeemRewardDialog}
        handleCloseRedeemRewardDialog={this.props.handleCloseRedeemRewardDialog}
        handleClick={this.props.handleClick}
        rewardTitle={this.props.title}
        rewardPoints={this.props.points}
        rewardId={this.props.id}
        rewardImg={this.props.img}
        rewardDescription={this.props.description}
        rewardDescriptionAfterRedeem={this.props.descriptionAfterRedeem}
        rewardOwned={this.props.owned}
        rewardOptions={this.props.options}
        rewardType={this.props.type}
        userPoints={this.props.userPoints}
        unlockable={this.props.unlockable}
        locked={this.props.locked}
        buttonClass={buttonValues.buttonClass}
      />
    );
  }

  /**
   * Get appropriate button text and button class for action button
   */
  getButtonValues() {
    if (this.props.available === 0) {
      return {
        buttonText: localize(
          "buy_not_available_text_button",
          this.props.language,
        ),
        buttonClass: "button inactive soldout",
      };
    } else if (this.props.adminOnly) {
      return {
        buttonText: localize("non_redeemable_item_text", this.props.language),
        buttonClass: "button inactive adminOnly",
      };
    } else if (this.props.unlockable && this.props.locked) {
      return {
        buttonText: localize("locked_text", this.props.language),
        buttonClass: "button inactive locked",
      };
    } else if (this.props.owned === this.props.ownedLimit) {
      return {
        buttonText: localize("buy_limit_text_button", this.props.language),
        buttonClass: "button inactive limit",
      };
    } else if (this.props.usedAtFormatted) {
      return {
        buttonText: localize("item_used_at_text", this.props.language),
        buttonClass: "button inactive collected",
      };
    } else if (this.props.userPoints < this.props.points) {
      return {
        buttonText: localize("insufficient_points", this.props.language),
        buttonClass: "button inactive insufficientPoints",
      };
    } else {
      return {
        buttonText: localize("button_reward_redeem", this.props.language),
        buttonClass: "button cta redeem",
      };
    }
  }

  /**
   * Render "Redeem" action button
   */
  renderButton() {
    let buttonValues = this.getButtonValues();

    if (this.props.loggedIn) {
      return (
        <div className="topmargin-10">
          <button
            className={buttonValues.buttonClass}
            id={"redeemBtn" + this.props.id}
            onClick={(e) => {
              this.props.handleOpenRedeemRewardDialog(e);
            }}
          >
            {/* TODO: Standardise UI: Use icon for all conditions */}
            {/* {this.props.unlockable && this.props.locked && (
              <i className="fas fa-lock textcenter rightmargin"></i>
            )} */}
            {buttonValues.buttonText}
          </button>
          {this.props.showRedeemRewardDialog && this.renderRedeemRewardDialog()}
        </div>
      );
    } else {
      return (
        <div className="topmargin-10">
          <button
            className={buttonValues.buttonClass}
            onClick={(e) => {
              this.props.handleOpenRedeemLoginDialog(e);
            }}
          >
            {buttonValues.buttonText}
          </button>
          {this.props.showRedeemLoginDialog && this.renderRedeemLoginDialog()}
        </div>
      );
    }
  }

  /**
   * Render the Rewards component (State: Available Rewards)
   */
  render() {
    let img = this.props.img;
    let title = this.props.title;

    return (
      <div className="reward-card card-link-wrapper bottompadding">
        <Link
          to="#"
          className="card-link-overlay"
          onClick={(e) => {
            e.preventDefault();
            this.props.handleOpenRedeemRewardDialog(e);
          }}
        />
        <div className="pure-g">
          <div className="pure-u-6-24">
            <span className="square-image-wrapper">
              <span className="square-image circle">
                <img src={img} alt={title} />
              </span>
            </span>
          </div>
          <div className="pure-u-3-24" />
          <div className="pure-u-15-24">
            <strong dangerouslySetInnerHTML={{ __html: title }} />
            {this.props.description && (
              <div className="responsive-img-description">
                <p
                  dangerouslySetInnerHTML={{ __html: this.props.description }}
                />
              </div>
            )}
            <div className="smalltext">
              <span id="points">
                <i className="fas fa-star rightmargin-5"></i>
                {localize("store_price_text", this.props.language).format(
                  this.props.points,
                )}
              </span>
              <span id="left">
                {" "}
                {this.props.available !== null &&
                  localize(
                    "store_quantity_left_text",
                    this.props.language,
                  ).format(this.props.available)}
              </span>
            </div>
            <div className="smalltext">
              <i className="fas fa-shopping-bag rightmargin-5"></i>
              {localize("item_owned_text", this.props.language).format(
                this.props.owned,
              )}{" "}
              {this.props.ownedLimit &&
                localize("store_quantity_max_text", this.props.language).format(
                  this.props.ownedLimit,
                )}
            </div>
            {this.renderButton()}
          </div>
        </div>
      </div>
    );
  }
}

Reward.propTypes = propTypes;
Reward.defaultProps = defaultProps;

export default Reward;
