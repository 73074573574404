import React from "react";

import FeaturedProjectCard from "./FeaturedProjectCard";
import ProjectsListPage from "./ProjectsListPage";

import localize from "lang/localize";

class FeaturedProjectsPage extends ProjectsListPage {
  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    let bodyDOM = document.body; // <body> tag

    // Set page identifier class to body DOM
    if (!bodyDOM.classList.contains("featuredProjectsPage")) {
      bodyDOM.className += " featuredProjectsPage";
    }

    // Add other page classes to body DOM
    if (!bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.className += " page-loggedin";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    let bodyDOM = document.body; // <body> tag

    // Remove page identifier class from body DOM
    if (bodyDOM.classList.contains("featuredProjectsPage")) {
      bodyDOM.classList.remove("featuredProjectsPage");
    }

    // Remove other page classes from body DOM
    if (bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.classList.remove("page-loggedin");
    }
  }

  renderProjects() {
    const projects = this.props.projects;
    return projects.map((project, index) => (
      <FeaturedProjectCard
        key={index}
        text={project.title}
        img={project.bannerImage}
        id={project.id}
        description={project.description}
        project={project}
        language={this.props.language}
      />
    ));
  }

  render() {
    return (
      <div className="featured-project-list project-list container verticalpadding">
        <div>
          {this.renderProjects()}
          {this.props.more && (
            <div className="innerblock project-cards-wrapper">
              <div className="more-button-container">
                <button
                  className="button automargin"
                  onClick={this.props.handleMore}
                >
                  {localize("more_text", this.props.language)}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default FeaturedProjectsPage;
