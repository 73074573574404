import React from "react";
import PropTypes from "prop-types";
import ImageInput from "./ImageInput";

import "./ImageInput.css";

import localize from "lang/localize";

const propTypes = {
  imagename: PropTypes.string,
  onDrop: PropTypes.func.isRequired,
  resetFiles: PropTypes.func.isRequired,
  showAlertWithTimeout: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  handleDisabledClick: PropTypes.func,
  language: PropTypes.string,
  fileUploadLimit: PropTypes.number,
  fileTotalLimit: PropTypes.number,
  images: PropTypes.array,
};

const defaultProps = {
  disabled: false,
};

class MultipleImageInput extends ImageInput {
  constructor() {
    super();
    this.state = {
      preview: [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.images !== prevProps.images) {
      if (this.props.images == null) {
        // clear images
        this.setState({ preview: [] });
      }
    }
  }

  checkFiles(event, file) {
    if (["image/png", "image/gif", "image/jpeg"].indexOf(file.type) === -1) {
      this.props.resetFiles(); /* reset files in props */
      this.refs.imageInputForm.reset(); /* reset filename in <input type="file"> */
      this.createErrorAlert(
        localize("alert_image_type_fail", this.props.language),
      );
    } else if (file.size > 8500000) {
      this.props.resetFiles(); /* reset files in props */
      this.refs.imageInputForm.reset(); /* reset filename in <input type="file"> */
      this.createErrorAlert(
        localize("alert_image_size_fail", this.props.language),
      );
    } else if (event.target.files.length > this.props.fileUploadLimit) {
      this.props.resetFiles(); /* reset files in props */
      this.refs.imageInputForm.reset(); /* reset filename in <input type="file"> */
      this.createErrorAlert("Exceeded file upload limit");
    } else if (this.state.preview.length + 1 > this.props.fileTotalLimit) {
      this.refs.imageInputForm.reset();
      this.createErrorAlert("Exceeded file total upload limit");
    } else {
      this.props.onDrop(event.target.files);
      Array.from(event.target.files).forEach((file) => {
        this.readPreviewImage(file);
      });
    }
  }

  readPreviewImage(input) {
    const reader = new FileReader();
    reader.onload = () =>
      this.setState({ preview: [...this.state.preview, reader.result] });
    reader.readAsDataURL(input);
  }

  render() {
    return (
      <div>
        <form ref="imageInputForm">
          <div className="pure-g hide-below-md">
            <div className="image-input-label-container pure-u-1-5">
              <label
                htmlFor="imageInput"
                className={
                  "image-input-label" +
                  (this.props.disabled
                    ? " cursor-not-allowed disabled"
                    : " cursor-pointer")
                }
                onClick={
                  this.props.disabled
                    ? this.props.handleDisabledClick
                    : () => {}
                }
              >
                <i className="fas fa-camera" />
                <p
                  className={
                    "image-input-label-text " +
                    (this.props.imagename ? "text-truncated" : "")
                  }
                >
                  {this.props.imagename
                    ? this.props.imagename
                    : localize(
                        "button_claim_challenge_upload_photo",
                        this.props.language,
                      )}
                </p>
              </label>
            </div>
            <div className="pure-u-4-5 discussions-embeds-container bottommargin-20">
              <div className="pure-g">
                {this.state.preview.map((item, index) => {
                  return (
                    <div
                      className={`embed-item embed-item-${index + 1}`}
                      key={`postImg${index + 1}`}
                    >
                      <span className="square-image">
                        <img
                          src={item}
                          alt={this.props.imagename}
                          key={index}
                        />
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
            <input
              className="image-input"
              type="file"
              onChange={(e) => {
                if (e.target.files[0]) {
                  this.checkFiles(e, e.target.files[0]);
                }
              }}
              id="imageInput"
              accept="image/png, image/gif, image/jpeg"
              disabled={this.props.disabled}
              // multiple upload doesn't work in mobile
              multiple={false}
            />
          </div>
          <div className="pure-g hide-from-md">
            <div className="image-input-label-container pure-u-1-1">
              <label
                htmlFor="imageInput"
                className={
                  "image-input-label" +
                  (this.props.disabled
                    ? " cursor-not-allowed disabled"
                    : " cursor-pointer")
                }
                onClick={
                  this.props.disabled
                    ? this.props.handleDisabledClick
                    : () => {}
                }
              >
                <i className="fas fa-camera" />
                <p
                  className={
                    "image-input-label-text " +
                    (this.props.imagename ? "text-truncated" : "")
                  }
                >
                  {this.props.imagename
                    ? this.props.imagename
                    : localize(
                        "button_claim_challenge_upload_photo",
                        this.props.language,
                      )}
                </p>
              </label>
            </div>
            <div className="pure-u-1-1 discussions-embeds-container bottommargin-20">
              {this.state.preview.map((item, index) => {
                return (
                  <img
                    src={item}
                    className="pure-u-1-4 embed-item"
                    alt={this.props.imagename}
                    key={index}
                  />
                );
              })}
            </div>
            <input
              className="image-input"
              type="file"
              onChange={(e) => {
                if (e.target.files[0]) {
                  this.checkFiles(e, e.target.files[0]);
                }
              }}
              id="imageInput"
              accept="image/png, image/gif, image/jpeg"
              disabled={this.props.disabled}
              // multiple upload doesn't work in mobile
              multiple={false}
            />
          </div>
        </form>
      </div>
    );
  }
}

MultipleImageInput.propTypes = propTypes;
MultipleImageInput.defaultProps = defaultProps;

export default MultipleImageInput;
