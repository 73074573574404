import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { PROJECT_HOME, TOPIC } from "App/Routes";
import ResetActivityDialog from "./ResetActivityDialog";

import Router from "router";
import { RESET_TOPIC_ACTIVITY } from "services/api";
import { showAlertWithTimeout } from "actions";
import getApiGenerator from "services/getApiGenerator";

const propTypes = {
  context: PropTypes.string.isRequired,
  showDialog: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  topicTitle: PropTypes.string.isRequired,
  topicId: PropTypes.number.isRequired,
};

const defaultProps = {
  showModal: false,
};

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    language: state.language,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showAlertWithTimeout: (alert) => {
      dispatch(showAlertWithTimeout(alert));
    },
  };
};

/**
 * Pop-up Modal to confirm activity reset
 */
class ResetActivityDialogContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: props.showDialog,
    };

    this.handleConfirm = this.handleConfirm.bind(this);
  }

  resetActivity() {
    getApiGenerator(
      RESET_TOPIC_ACTIVITY,
      {
        type_id: this.props.topicId,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        this.props.handleCloseDialog();

        if (res.body.error) {
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error",
          });
        }
      } else {
        this.props.handleCloseDialog();
        this.handleRefreshWithHash();
      }
    });
  }

  handleConfirm() {
    this.resetActivity();
  }

  handleRefreshWithHash() {
    if (this.props.context === "project") {
      /*
        replace does not work well with hashing, so an additional reload is required.
        use #topicreset hash to show success popup in ProjectContainer (see componentDidMount())
      */
      Router.replaceAndNavigate(
        PROJECT_HOME.format(this.props.projectId) +
          "#topicreset" +
          this.props.topicId,
      );
      window.location.reload(true);
    } else if (this.props.context === "topic") {
      /*
        replace does not work well with hashing, so an additional reload is required.
        use #topicreset hash to show success popup in ProjectContainer (see componentDidMount())
      */
      Router.replaceAndNavigate(
        TOPIC.format(this.props.topicId) + "#topicreset",
      );
      window.location.reload(true);
    }
  }

  render() {
    return (
      <ResetActivityDialog
        showDialog={this.props.showDialog}
        handleCloseDialog={this.props.handleCloseDialog}
        handleConfirm={this.handleConfirm}
        topicTitle={this.props.topicTitle}
        topicId={this.props.topicId}
        language={this.props.language}
      />
    );
  }
}

ResetActivityDialogContainer.propTypes = propTypes;
ResetActivityDialogContainer.defaultProps = defaultProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetActivityDialogContainer);
