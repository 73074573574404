import React, { Component } from "react";
import { connect } from "react-redux";

import RegisterEmbedPage from "./RegisterEmbedPage";

import { ENABLE_TOUR } from "config";

import sessionStorageService from "services/sessionStorageService";
import loginServices from "services/loginServices";

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    projectId: state.projectId,
    userId: state.user ? state.user.id : null,
    language: state.language,
  };
};

export class RegisterContainer extends Component {
  componentDidMount() {
    if (this.props.sessionKey && this.props.userId) {
      let loginRedirectUrl = sessionStorageService.getItem("loginRedirectUrl");

      loginServices.postLoginReplaceAndNavigate(
        this.props.projectId,
        loginRedirectUrl,
        ENABLE_TOUR, // assume new user, so tour screen is needed if enabled
      );
    }
  }

  render() {
    return <RegisterEmbedPage language={this.props.language} />;
  }
}

export default connect(mapStateToProps)(RegisterContainer);
