import React, { Component } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import PostIframe from "../../PostIframe/PostIframe";
import deviceServices from "services/deviceServices";

import "./MiniGameDialog.css";

const propTypes = {
  title: PropTypes.string,
  hiddenFields: PropTypes.object,
  url: PropTypes.string,
  showDialog: PropTypes.bool,
  handleOpenDialog: PropTypes.func,
  handleCloseDialog: PropTypes.func,
  challengeMinigameTitle: PropTypes.string,
};

const defaultProps = {
  title: "MiniGame",
};

/**
 * Pop-up Modal to render a embedded Mini Game view
 */
class MiniGameDialog extends Component {
  render() {
    return (
      <Modal
        isOpen={this.props.showDialog}
        contentLabel={this.props.title}
        onRequestClose={this.props.handleCloseDialog}
        shouldCloseOnOverlayClick={false}
        className="dialog minigame-dialog"
        overlayClassName="dialog-overlay"
      >
        <div className="dialog-content">
          <div className="dialog-header">
            <h5 className="dialog-title capitalize">
              {this.props.challengeMinigameTitle}
            </h5>
            <button
              onClick={this.props.handleCloseDialog}
              className="close-button-container"
            >
              <i className="fa close-button" aria-hidden="true" />
            </button>
          </div>
          <div className="dialog-body">
            <div
              className={deviceServices.isIOS() ? " iframe-scroll-wrapper" : ""}
            >
              <PostIframe
                title={this.props.title}
                hiddenFields={this.props.hiddenFields}
                url={this.props.url}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

MiniGameDialog.propTypes = propTypes;
MiniGameDialog.defaultProps = defaultProps;

export default MiniGameDialog;
