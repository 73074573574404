import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";

import PostIframe from "../shared/PostIframe/PostIframe";

import {
  REGISTER_PAGE_LINK,
  ENABLE_LOGIN_AFTER_REGISTER,
  CALLBACK_ID_DEFAULT,
  SECRET_KEY,
  ENABLE_PLATFORM_LANG_URL_PARAM,
} from "config";
import deviceServices from "services/deviceServices";
// import urlServices from "services/urlServices";
import localize from "lang/localize";

const propTypes = {
  language: PropTypes.string,
};

class RegisterEmbedPage extends Component {
  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    // Set page identifier class to .component-container
    let mainComponent = ReactDOM.findDOMNode(this).parentNode;
    if (!mainComponent.classList.contains("registerPage")) {
      mainComponent.className += " registerPage";
    }

    // Add other page classes to body DOM
    let bodyDOM = document.body; // <body> tag
    if (!bodyDOM.classList.contains("page-not-loggedin")) {
      bodyDOM.className += " page-not-loggedin";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    // Remove page identifier class from .component-container
    let mainComponent = ReactDOM.findDOMNode(this).parentNode;
    if (mainComponent.classList.contains("registerPage")) {
      mainComponent.classList.remove("registerPage");
    }

    // Remove other page classes from body DOM
    let bodyDOM = document.body;
    if (bodyDOM.classList.contains("page-not-loggedin")) {
      bodyDOM.classList.remove("page-not-loggedin");
    }
  }

  render() {
    if (ENABLE_LOGIN_AFTER_REGISTER && CALLBACK_ID_DEFAULT) {
    }

    return (
      <div
        className={
          "fullscreen-frame-wrap" +
          (deviceServices.isIOS() ? " iframe-scroll-wrapper" : "")
        }
      >
        {/* <iframe
          src={
            REGISTER_PAGE_LINK +
            urlServices.getEmbedLangParam(this.props.language, false) +
            "&api_key=" +
            SECRET_KEY +
            "&callback_id=" +
            CALLBACK_ID_DEFAULT
          }
          title={localize("login_signup_web_view_title", this.props.language)}
          frameBorder="0"
          className="fullscreen-frame"
        /> */}
        <PostIframe
          title={localize("login_signup_web_view_title", this.props.language)}
          hiddenFields={{
            api_key: ENABLE_LOGIN_AFTER_REGISTER ? SECRET_KEY : "",
            callback_id: ENABLE_LOGIN_AFTER_REGISTER ? CALLBACK_ID_DEFAULT : "",
            lang: ENABLE_PLATFORM_LANG_URL_PARAM ? this.props.language : "",
          }}
          url={REGISTER_PAGE_LINK}
        />
      </div>
    );
  }
}

RegisterEmbedPage.propTypes = propTypes;

export default RegisterEmbedPage;
