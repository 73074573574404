import React, { Component } from "react";
import PropTypes from "prop-types";

import Select from "react-select";

const MIN_MENU_HEIGHT = 150;

const propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  options: PropTypes.array.isRequired,
  defaultOption: PropTypes.object,
  selectedOption: PropTypes.object,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  searchable: PropTypes.bool,
  clearable: PropTypes.bool,
  isPopup: PropTypes.bool,
  height: PropTypes.number,
  zIndex: PropTypes.number,
  className: PropTypes.string,
};

const defaultProps = {
  disabled: false,
  searchable: false,
  clearable: false,
  height: MIN_MENU_HEIGHT,
  className: "",
  onChange: null,
  isPopup: false,
};

class AdvancedSelectInput extends Component {
  render() {
    return (
      <Select
        id={this.props.name}
        name={this.props.name}
        className={
          "advanced-select" +
          (this.props.className ? " " + this.props.className : "")
        }
        classNamePrefix="advanced-select"
        defaultOption={this.props.defaultOption}
        value={
          this.props.selectedOption
            ? this.props.selectedOption
            : this.props.defaultOption
        }
        isSearchable={this.props.searchable}
        options={this.props.options}
        placeholder={this.props.placeholder}
        // react-select returns the object rather than the event
        // onChange={this.props.onChange}
        // option is always the first parameter
        onChange={this.props.onChange.bind(this, this.props.name)}
        isDisabled={this.props.disabled}
        isClearable={this.props.clearable}
        maxMenuHeight={
          this.props.height >= MIN_MENU_HEIGHT
            ? this.props.height
            : MIN_MENU_HEIGHT
        }
        menuPortalTarget={document.body}
        // Ensure it is visible in popup
        // dialog-overlay has z-index of 32
        styles={{
          menuPortal: (base) => ({
            ...base,
            zIndex: this.props.zIndex
              ? this.props.zIndex
              : this.props.isPopup
                ? 35
                : 5,
          }),
        }}
        // menuIsOpen="true" // open the menu to help with styling
      />
    );
  }
}

AdvancedSelectInput.propTypes = propTypes;
AdvancedSelectInput.defaultProps = defaultProps;

export default AdvancedSelectInput;
