import React from "react";
import { connect } from "react-redux";

import {
  mapStateToProps,
  mapDispatchToProps,
  LoginContainer,
} from "./LoginContainer";
import LoginHomePage from "./LoginHomePage";

export class LoginHomeContainer extends LoginContainer {
  render() {
    return (
      <LoginHomePage
        projectId={this.props.projectId}
        language={this.props.language}
        /* Guest Login Dialog */
        guestEnabled={this.props.guestEnabled}
        showGuestLoginDialog={this.state.showGuestLoginDialog}
        handleOpenGuestLoginDialog={this.handleOpenGuestLoginDialog}
        handleCloseGuestLoginDialog={this.handleCloseGuestLoginDialog}
        /* OAuth */
        responseFacebook={this.responseFacebook}
        responseFacebookError={this.responseFacebookError}
        responseMicrosoft={this.responseMicrosoft}
        responseGoogle={this.responseGoogle}
        responseGoogleError={this.responseGoogleError}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginHomeContainer);
