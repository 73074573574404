import React from "react";
import { connect } from "react-redux";
import {
  ProjectsListContainer,
  mapStateToProps,
} from "./ProjectsListContainer";
import getApiGenerator from "services/getApiGenerator";

import SearchProjectsPage from "./SearchProjectsPage";

import { LOAD_MORE_COUNT, MASTERS_ID } from "config";
import localStorageService from "services/localStorageService";
import { DEFAULT_PACKAGE } from "services/localStorageService";

class SearchProjectsContainer extends ProjectsListContainer {
  /* Follows state of ProjectsListContainer + searchInput */
  constructor() {
    super();
    this.state = {
      packageId: MASTERS_ID,
      projects: null,
      more: false,
      page: 1,
      user: null,
      searchInput: "",
    };
  }

  componentDidMount() {
    this.getProjects(this.state.page, this.props.keywords);
  }

  getProjects(page, keywords) {
    let packageId = localStorageService.getItem(DEFAULT_PACKAGE);
    if (packageId) {
      this.setState({ packageId: packageId });
    } else {
      packageId = this.state.packageId;
    }

    getApiGenerator(
      "masters.json",
      {
        master_id: packageId,
        type: "all",
        page: page,
        limit: LOAD_MORE_COUNT,
        keywords: keywords,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({ projects: [] });
        }
      } else {
        this.setState({
          projects: this.state.projects
            ? this.state.projects.slice().concat(res.body.data)
            : res.body.data,
          more: res.body.more,
          page: this.state.page + 1,
        });
      }
    });
  }

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();

      this.handleSubmit(event);
    }
  };

  handleSearchChange = (event) => {
    let value = event.target.value;

    this.setState({ searchInput: value });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({ projects: null, page: 1 }, () => {
      this.getProjects(this.state.page, this.state.searchInput);
    });
  };

  render() {
    return (
      <SearchProjectsPage
        projects={this.state.projects}
        more={this.state.more}
        keywords={this.props.keywords}
        handleMore={() => this.handleMore()}
        handleKeyPress={this.handleKeyPress}
        handleSearchChange={this.handleSearchChange}
        handleSubmit={this.handleSubmit}
        language={this.props.language}
      />
    );
  }
}

export default connect(mapStateToProps)(SearchProjectsContainer);
