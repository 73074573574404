import React, { Component } from "react";
import { connect } from "react-redux";

import ProfileDrawer from "components/ProfileDrawer/ProfileDrawer";

import getApiGenerator from "services/getApiGenerator";
import { GET_NOTIFICATIONS_UNREAD, GET_USER_WIDGET } from "services/api";
import { setProfileDrawer, setUser, setTeam } from "actions";
import { SINGLE_PROJECT_APP, ENABLE_INBOX } from "config";
import listenerServices from "services/listenerServices";
import sessionStorageService from "services/sessionStorageService";

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setProfileDrawer: (info) => {
      dispatch(setProfileDrawer(info));
    },
    setUser: (user) => {
      dispatch(setUser(user));
    },
    setTeam: (team) => {
      dispatch(setTeam(team));
    },
  };
};

export const mapStateToProps = (state, ownProps) => {
  return {
    /* For API calls */
    sessionKey: state.sessionKey,

    /* For user information */
    user: state.user,

    /* For rendering correct project links */
    projectId: state.projectId,

    /* For checking if profile drawer should even be rendered */
    project: state.project,

    /* Rendering notifications */
    notifications: state.profileDrawer.notifications,

    /* For page changes */
    newPage: state.profileDrawer.newPage,

    /* For rendering points */
    points: state.profileDrawer.points,

    /* For determining project points and notifications */
    profileDrawer: state.profileDrawer,
    language: state.language,
  };
};

export class ProfileDrawerContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: null,
      isMobilePage: true, // for defining mobile Project page
    };
  }

  componentDidMount() {
    /*
      Attaching listener for window resizes for rendering
      Profile Drawer in mobile Project page
    */
    listenerServices.addListener("resize", this.setMobilePage);

    /* perform the first check after component is mounted */
    this.setMobilePage();
  }

  componentWillUnmount() {
    /* Unmount listener for window resizes */
    listenerServices.removeListener("resize", this.setMobilePage);
  }

  componentDidUpdate(prevProps, prevState) {
    let projChange = false;

    if (this.props.projectId !== this.state.projectId) {
      this.setState({ projectId: this.props.projectId });

      projChange = true;
    }

    // on each page change, get points and unread notifications
    if ((projChange || this.props.newPage) && this.props.sessionKey) {
      this.getNotifications(this.props.projectId);
      this.getUser(this.props.projectId);
      this.props.setProfileDrawer({ newPage: false });
    }
  }

  /**
   * Determine if page is rendered in mobile size
   */
  setMobilePage = () => {
    const IS_640_DESKTOP_PROJECT =
      sessionStorageService.getItem("640_desktop_project") === "true";
    // 40em corresponds with $md-640 as our .scss variable, or 640px
    // 48em corresponds with $md as our .scss variable, or 768px
    let match = IS_640_DESKTOP_PROJECT
      ? window.matchMedia("(min-width: 40em)")
      : window.matchMedia("(min-width: 48em)");

    if (match.matches) {
      this.setState({ isMobilePage: false });
    } else {
      this.setState({ isMobilePage: true });
    }
  };

  /**
   * Retrieve latest notification count for current project
   *
   * @param int project_id
   * @returns
   */
  getNotifications(project_id) {
    if (this.props.user.id === undefined) {
      return false;
    }
    getApiGenerator(
      GET_NOTIFICATIONS_UNREAD,
      { bundle_id: project_id },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
      } else {
        this.props.setProfileDrawer({ notifications: res.body.unread });
      }
    });
  }

  /**
   * Retrieve latest user information for current project
   *
   * @param int project_id
   * @returns
   */
  getUser(project_id) {
    if (this.props.user.id === undefined) {
      return false;
    }
    getApiGenerator(
      GET_USER_WIDGET.format(this.props.user.id),
      { bundle_id: project_id },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
      } else {
        this.props.setProfileDrawer({ points: res.body.points });
        this.props.setUser(res.body);
        if (res.body.team && res.body.team.id) {
          this.props.setTeam(res.body.team);
        }
      }
    });
  }

  /**
   * Check if user is currently logged in.
   *
   * @returns boolean
   */
  isLoggedIn() {
    if (this.props.user && this.props.sessionKey) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Render the component.
   */
  render() {
    if (this.props.profileDrawer.state === "no-profile-drawer") {
      return null;
    } else {
      return (
        <ProfileDrawer
          user={this.props.user}
          projectId={this.props.projectId}
          project={this.props.project}
          notifications={this.props.notifications || 0}
          points={this.props.points || 0}
          loggedIn={this.isLoggedIn()}
          singleProjectApp={SINGLE_PROJECT_APP}
          // passing in ENABLE_INBOX as a prop for Jest tests
          enableInbox={ENABLE_INBOX}
          language={this.props.language}
        />
      );
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileDrawerContainer);
