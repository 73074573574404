import React, { Component } from "react";
import PropTypes from "prop-types";

import Modal from "react-modal";

import localize from "lang/localize";

import "./ActionConfirmationDialog.css";

const propTypes = {
  showModal: PropTypes.bool.isRequired,
  language: PropTypes.string,
  // Confirmation text to display
  confirmText: PropTypes.string,
  // Additional warning message to display
  warnText: PropTypes.string,
  // Action to execute when user cancels the action
  handleCloseActionConfirmationDialog: PropTypes.func.isRequired,
  // Action to execute when user confirms the action
  handleAction: PropTypes.func.isRequired,
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

/**
 * Confirmation Dialog
 * To be implemented every time a deletion action is taken, or
 * any action that cannot be undone.
 */
class ActionConfirmationDialog extends Component {
  /**
   * Renders the confirmation dialog
   */
  render() {
    return (
      <Modal
        isOpen={this.props.showModal}
        contentLabel={localize("delete_submission", this.props.language) + "?"}
        onRequestClose={this.props.handleCloseActionConfirmationDialog}
        shouldCloseOnOverlayClick={false}
        className="dialog delete-completion-dialog"
        overlayClassName="dialog-overlay"
      >
        <div className="dialog-content">
          <div className="dialog-header">
            <i className="fas fa-exclamation-circle font-size-xxxl"></i>
          </div>
          <div className="dialog-body text-align-center">
            {this.props.confirmText}
            {this.props.warnText && (
              <div className="warning">{this.props.warnText}</div>
            )}
          </div>
          <div className="dialog-footer">
            <div className="textright">
              <button
                className="button inline"
                onClick={this.props.handleCloseActionConfirmationDialog}
                id="cancelActionButton"
              >
                {localize("web_confirm_cancel_text", this.props.language)}
              </button>
              <button
                className="button inline danger"
                onClick={this.props.handleAction}
                id="confirmActionButton"
              >
                {localize("web_confirm_yes_text", this.props.language)}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

ActionConfirmationDialog.propTypes = propTypes;

export default ActionConfirmationDialog;
