import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TumblrIcon,
  TumblrShareButton,
  TwitterIcon,
  TwitterShareButton,
  WeiboIcon,
  WeiboShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import Modal from "react-modal";

import localize from "lang/localize";

const propTypes = {
  showDialog: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  sharableLink: PropTypes.string.isRequired, // page with meta tags
  title: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  language: PropTypes.string,
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

/**
 * Pop-up Modal for Project sharing
 */
class BasicShareDialog extends Component {
  constructor() {
    super();
    this.state = {
      showSharableLinkCopied: false,
      showLinkCopied: false,
    };
  }

  renderHeaderTitle() {
    return localize("share_to_text", this.props.language);
  }

  handleCopyLink = (e) => {
    this.input.select();
    document.execCommand("copy");
    this.setState({ showLinkCopied: true }, () => {
      setTimeout(() => {
        this.setState({ showLinkCopied: false });
      }, 1250);
    });
  };

  handleCopySharableLink = (e) => {
    this.sharableInput.select();
    document.execCommand("copy");
    this.setState({ showSharableLinkCopied: true }, () => {
      setTimeout(() => {
        this.setState({ showSharableLinkCopied: false });
      }, 1250);
    });
  };

  renderSocialShare() {
    if (!this.props.sharableLink) {
      return null;
    }

    return (
      <>
        <li>
          <FacebookShareButton url={this.props.sharableLink}>
            <FacebookIcon size={24} round={true} />
            <span>Facebook</span>
          </FacebookShareButton>
        </li>
        <li>
          <LinkedinShareButton url={this.props.sharableLink}>
            <LinkedinIcon size={24} round={true} />
            <span>LinkedIn</span>
          </LinkedinShareButton>
        </li>
        <li>
          <RedditShareButton url={this.props.sharableLink}>
            <RedditIcon size={24} round={true} />
            <span>Reddit</span>
          </RedditShareButton>
        </li>
        <li>
          <TelegramShareButton url={this.props.sharableLink}>
            <TelegramIcon size={24} round={true} />
            <span>Telegram</span>
          </TelegramShareButton>
        </li>
        <li>
          <TumblrShareButton url={this.props.sharableLink}>
            <TumblrIcon size={24} round={true} />
            <span>Tumblr</span>
          </TumblrShareButton>
        </li>
        <li>
          <TwitterShareButton url={this.props.sharableLink}>
            <TwitterIcon size={24} round={true} />
            <span>Twitter</span>
          </TwitterShareButton>
        </li>
        <li>
          <WeiboShareButton url={this.props.sharableLink}>
            <WeiboIcon size={24} round={true} />
            <span>Weibo</span>
          </WeiboShareButton>
        </li>
        <li>
          <WhatsappShareButton url={this.props.sharableLink}>
            <WhatsappIcon size={24} round={true} />
            <span>Whatsapp</span>
          </WhatsappShareButton>
        </li>
      </>
    );
  }

  renderClipboardShare() {
    if (!this.props.sharableLink) {
      return null;
    }

    return (
      <li>
        <button
          onClick={this.handleCopySharableLink}
          disabled={this.state.showSharableLinkCopied}
        >
          <div className="circle-icon-wrapper">
            <i className="fas fa-copy"></i>
          </div>
          <div className="share-link">
            <p className="nomargin line-height-1">
              {this.state.showSharableLinkCopied
                ? localize("copied_link_text", this.props.language)
                : localize("copy_sharable_text_link", this.props.language)}
            </p>
            <p className="nomargin line-height-1">
              <small>{this.props.sharableLink}</small>
            </p>
          </div>
          <input
            className="copy-input"
            type="text"
            ref={(sharableInput) => (this.sharableInput = sharableInput)}
            defaultValue={this.props.sharableLink}
            readOnly
          />
        </button>
      </li>
    );
  }

  renderEmailShare() {
    if (!this.props.sharableLink) {
      return null;
    }

    return (
      <li>
        <EmailShareButton url={this.props.sharableLink}>
          <EmailIcon size={24} round={true} />
          <span>
            {localize("login_view_email_placeholder_text", this.props.language)}
          </span>
        </EmailShareButton>
      </li>
    );
  }

  render() {
    return (
      <Modal
        isOpen={this.props.showDialog}
        contentLabel={localize("share_text", this.props.language)}
        onRequestClose={this.props.handleCloseDialog}
        shouldCloseOnOverlayClick={true}
        className="dialog list-dialog share-dialog"
        overlayClassName="dialog-overlay"
      >
        <div className="dialog-content">
          <div className="dialog-header">
            <h5 className="dialog-title">{this.renderHeaderTitle()}</h5>
          </div>
          <div className="dialog-body">
            <ul className="dialog-list">
              {this.renderClipboardShare()}
              {this.renderEmailShare()}
              {this.renderSocialShare()}
            </ul>
          </div>
          <div className="dialog-footer">
            <div className="textright">
              <button
                className="button inline"
                onClick={this.props.handleCloseDialog}
                id="cancelButton"
              >
                {localize("web_alert_close_text", this.props.language)}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

BasicShareDialog.propTypes = propTypes;

export default BasicShareDialog;
