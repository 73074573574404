import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";

export const DEFAULT_PACKAGE = "default_package_id";
export const DEFAULT_PROJECT = "default_project_id";

const localStorageService = {
  loadState: function () {
    try {
      const encrypted = localStorage.getItem("state");
      if (encrypted === null) {
        return undefined;
      }
      const serializedState = AES.decrypt(
        encrypted.toString(),
        "e1W^@M?jm#w=deZ4[JqLN4|0y/vN?(",
      );
      const state = JSON.parse(serializedState.toString(Utf8));
      return state;
    } catch (err) {
      return undefined;
    }
  },

  saveState: function (state) {
    try {
      const serializedState = JSON.stringify(state);
      const encrypted = AES.encrypt(
        serializedState,
        "e1W^@M?jm#w=deZ4[JqLN4|0y/vN?(",
      );
      localStorage.setItem("state", encrypted);
    } catch (err) {
      return undefined;
    }
  },

  removeItem: function (key) {
    try {
      localStorage.removeItem(key);
    } catch (err) {
      return undefined;
    }
  },

  setItem: function (key, value) {
    try {
      localStorage.setItem(key, value);
    } catch (err) {
      return undefined;
    }
  },

  getItem: function (key) {
    try {
      return localStorage.getItem(key);
    } catch (err) {
      return undefined;
    }
  },
};

export default localStorageService;
