import React, { Component } from "react";
import PropTypes from "prop-types";

import "./LoginDialog.css";
import {
  REGISTER,
  PROJECT_REGISTER,
  LOGIN,
  PROJECT_LOGIN,
  LOGIN_HOME,
  PROJECT_LOGIN_HOME,
} from "App/Routes";
import Link from "components/shared/Link/Link";

import Modal from "react-modal";

import { ENABLE_LOGIN_HOME } from "config";
import localize from "lang/localize";

const propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleCloseLoginDialog: PropTypes.func.isRequired,
  language: PropTypes.string,
  projectId: PropTypes.number,
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

/**
 * Pop-up Modal to render the login prompt
 */
class LoginDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showModal,
    };
  }

  /**
   * Render CTA to login
   */
  renderLoginLink() {
    if (this.props.projectId && ENABLE_LOGIN_HOME) {
      return PROJECT_LOGIN_HOME.format(this.props.projectId);
    } else if (this.props.projectId) {
      return PROJECT_LOGIN.format(this.props.projectId);
    } else if (ENABLE_LOGIN_HOME) {
      return LOGIN_HOME;
    } else {
      return LOGIN;
    }
  }

  /**
   * Render the component
   */
  render() {
    return (
      <Modal
        isOpen={this.state.showModal}
        contentLabel={localize("nav_bar_title_login_text", this.props.language)}
        onRequestClose={this.props.handleCloseLoginDialog}
        shouldCloseOnOverlayClick={false}
        className="dialog login-dialog"
        overlayClassName="dialog-overlay"
      >
        <div className="dialog-content">
          <div className="dialog-header">
            <i className="far fa-grin-wink font-size-xxxl"></i>
          </div>
          <div className="dialog-body">
            {localize("dialog_register_text", this.props.language)}
            <Link
              to={
                this.props.projectId
                  ? PROJECT_REGISTER.format(this.props.projectId)
                  : REGISTER
              }
            >
              {localize("dialog_register_text_end", this.props.language)}
            </Link>
          </div>
          <div className="dialog-footer">
            <div className="textright">
              <button
                className="button inline"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.handleCloseLoginDialog();
                }}
              >
                {localize("web_confirm_cancel_text", this.props.language)}
              </button>
              <Link to={this.renderLoginLink()} className="button inline cta">
                {localize("nav_bar_title_login_text", this.props.language)}
              </Link>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

LoginDialog.propTypes = propTypes;

export default LoginDialog;
