import React from "react";
import PropTypes from "prop-types";

import deviceServices from "services/deviceServices";
import localize from "lang/localize";
import PostIframe from "../../shared/PostIframe/PostIframe";

const propTypes = {
  sessionKey: PropTypes.string.isRequired,
  projectReferralLink: PropTypes.string,
  language: PropTypes.string,
};

const ProjectReferAFriendPage = (props) => {
  return (
    <div
      className={
        "fullscreen-frame-wrap" +
        (deviceServices.isIOS() ? " iframe-scroll-wrapper" : "")
      }
    >
      <PostIframe
        title={localize("project_referral_text", props.language)}
        hiddenFields={{
          session_key: props.sessionKey,
          lang: props.language,
        }}
        url={props.projectReferralLink}
      />
    </div>
  );
};

ProjectReferAFriendPage.propTypes = propTypes;

export default ProjectReferAFriendPage;
