import { ENABLE_TOUR } from "config";
import { LOGIN_TOUR, PROJECT_HOME, HOME } from "App/Routes";
import Router from "router";

const loginServices = {
  /* post login replace and navigate, additional allowTour flag to determine if tours should be bypassed */
  postLoginReplaceAndNavigate: function (
    projectId,
    loginRedirectUrl,
    allowTour = true,
  ) {
    if (ENABLE_TOUR && allowTour) {
      Router.replaceAndNavigate(LOGIN_TOUR);
    } else if (loginRedirectUrl && loginRedirectUrl !== "/") {
      Router.replaceAndNavigate(loginRedirectUrl);
    } else if (projectId) {
      Router.replaceAndNavigate(PROJECT_HOME.format(projectId));
    } else {
      Router.replaceAndNavigate(HOME);
    }
  },
};

export default loginServices;
