import React, { Component } from "react";
import PropTypes from "prop-types";

import "./MyProjectCard.css";
import { PROJECT_HOME, PROJECT_REMOVE_GAME, PROJECT_SHARE } from "App/Routes";
import Link from "components/shared/Link/Link";
import UnjoinProjectDialogContainer from "./UnjoinProjectDialog/UnjoinProjectDialogContainer";
import ShareGameDialog from "components/shared/Dialogs/ShareGameDialog/ShareGameDialog";
import placeholder_project from "images/core/placeholders/placeholder_project.png";

import Dotdotdot from "react-dotdotdot";
import Dropdown from "@trendmicro/react-dropdown";
import "@trendmicro/react-buttons/dist/react-buttons.css";
import "@trendmicro/react-dropdown/dist/react-dropdown.css";

import localize from "lang/localize";

const propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  img: PropTypes.string.isRequired,
  showUnjoinProjectDialog: PropTypes.bool.isRequired,
  handleOpenUnjoinProjectDialog: PropTypes.func.isRequired,
  handleCloseUnjoinProjectDialog: PropTypes.func.isRequired,
  showShareGameDialog: PropTypes.bool.isRequired,
  handleOpenShareGameDialog: PropTypes.func.isRequired,
  handleCloseShareGameDialog: PropTypes.func.isRequired,
  removeProject: PropTypes.func.isRequired,
  private: PropTypes.bool.isRequired,
  project: PropTypes.object.isRequired,
  language: PropTypes.string,
};
const defaultProps = {
  img: placeholder_project,
};

class MyProjectCard extends Component {
  constructor() {
    super();
    this.state = {
      showDropdown: false,
    };
  }

  closeDropdown() {
    this.setState({ showDropdown: false });
  }

  toggleDropdown(open) {
    this.setState({ showDropdown: open });
  }

  renderUnjoinProjectDialog() {
    return (
      <UnjoinProjectDialogContainer
        showModal={this.props.showUnjoinProjectDialog}
        projectId={this.props.id}
        handleCloseUnjoinProjectDialog={
          this.props.handleCloseUnjoinProjectDialog
        }
        removeProject={this.props.removeProject}
        private={this.props.private}
      />
    );
  }

  renderShareGameDialog() {
    return (
      <ShareGameDialog
        showDialog={this.props.showShareGameDialog}
        handleCloseDialog={this.props.handleCloseShareGameDialog}
        projectId={this.props.id}
        project={this.props.project}
        language={this.props.language}
      />
    );
  }

  renderMenuDropdownButton() {
    return (
      <div className="dropdown-button-group">
        <Dropdown
          dropup={false}
          onSelect={(eventKey, event) => {
            event.preventDefault();
          }}
          pullRight={true}
          open={this.state.showDropdown}
          onToggle={this.toggleDropdown.bind(this)}
        >
          <Dropdown.Toggle
            btnStyle="flat"
            noCaret={true}
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <span className="more-icon" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {!this.props.private && (
              <div
                to={PROJECT_SHARE.format(this.props.id)}
                className="link"
                onClick={(e) => {
                  e.preventDefault();
                  this.closeDropdown();
                  this.props.handleOpenShareGameDialog(e);
                }}
              >
                {localize("share_text", this.props.language)}
              </div>
            )}
            <div
              to={PROJECT_REMOVE_GAME.format(this.props.id)}
              className="link"
              onClick={(e) => {
                e.preventDefault();
                this.closeDropdown();
                this.props.handleOpenUnjoinProjectDialog(e);
              }}
            >
              {localize("bundle_remove_title", this.props.language)}
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  render() {
    const link = PROJECT_HOME.format(this.props.id);
    let description = this.props.description;
    let img = this.props.img;
    let title = this.props.title;

    return (
      <div className="project-card-container">
        {this.props.showUnjoinProjectDialog && this.renderUnjoinProjectDialog()}
        {this.props.showShareGameDialog && this.renderShareGameDialog()}
        <Link
          to={link}
          className="project-card project-card-bg pure-g"
          onClick={this.props.handleProjectCardClick}
        >
          <div className="pure-u-5-24">
            <span className="square-image-wrapper">
              <span className="square-image circle">
                <img src={img} alt={title} />
              </span>
            </span>
          </div>
          <div className="pure-u-1-24" />
          {/*
            for description and title, dedicate less than 5/8 of space
            to prevent overlapping of 'delete project' button and text
          */}
          <div className="pure-u-18-24 my-project-info">
            <div className="my-project-card-header">
              <Dotdotdot clamp={2} className="my-project-card-title">
                <h6 className="title">{title}</h6>
              </Dotdotdot>
              {this.renderMenuDropdownButton()}
            </div>
            {description && description.trim() !== "" && (
              <div className="my-description-content description-content">
                <div className="hide-from-md">
                  <Dotdotdot clamp={5}>
                    <p dangerouslySetInnerHTML={{ __html: description }} />
                  </Dotdotdot>
                </div>
                {/* Show more lines in desktop mode */}
                <div className="hide-below-md">
                  <Dotdotdot clamp={6}>
                    <p dangerouslySetInnerHTML={{ __html: description }} />
                  </Dotdotdot>
                </div>
              </div>
            )}
          </div>
        </Link>
      </div>
    );
  }
}

MyProjectCard.propTypes = propTypes;
MyProjectCard.defaultProps = defaultProps;

export default MyProjectCard;
