import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import LoginDialogContainer from "components/shared/Dialogs/LoginDialog/LoginDialogContainer";
import placeholder_profile from "images/core/placeholders/default_avatar.png";
import placeholder_team from "images/core/placeholders/placeholder_team.jpg";
import {
  PROJECT_TEAM_INVITE,
  TEAM_INVITE,
  PROJECT_TEAM_UPDATE,
  TEAM_UPDATE,
} from "App/Routes";
import Link from "components/shared/Link/Link";

import Dropdown, { MenuItem } from "@trendmicro/react-dropdown";
import "@trendmicro/react-buttons/dist/react-buttons.css";
import "@trendmicro/react-dropdown/dist/react-dropdown.css";

import localize from "lang/localize";

const propTypes = {
  projectId: PropTypes.number,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string,
  joined: PropTypes.bool.isRequired,
  teamCreator: PropTypes.bool.isRequired,
  teamCreateEnabled: PropTypes.bool.isRequired,
  teamJoinEnabled: PropTypes.bool.isRequired,
  teamJoinedInProject: PropTypes.bool.isRequired,
  user: PropTypes.object,
  members: PropTypes.array.isRequired,
  points: PropTypes.number.isRequired,
  handleJoinTeam: PropTypes.func.isRequired,
  handleLeaveTeam: PropTypes.func.isRequired,
  language: PropTypes.string,
  showLoginDialog: PropTypes.bool,
  handleOpenLoginDialog: PropTypes.func,
  handleCloseLoginDialog: PropTypes.func,
  sessionKey: PropTypes.string,
};
// should use default pic

class Team extends Component {
  /**
   * Render Team dropdown menu toggle button
   */
  renderMenuDropdownButton() {
    if (this.props.joined) {
      const SHOW_JOIN_TEAM =
        this.props.teamJoinEnabled && !this.props.teamJoinedInProject;
      const SHOW_LEAVE_TEAM = this.props.teamJoinEnabled && this.props.joined;
      const SHOW_UPDATE_TEAM =
        this.props.teamCreateEnabled && this.props.teamCreator;

      if (SHOW_JOIN_TEAM || SHOW_LEAVE_TEAM || SHOW_UPDATE_TEAM) {
        return (
          <div className="dropdown-button-group">
            <Dropdown
              onSelect={(eventKey, event) => {
                event.preventDefault();
              }}
              pullRight={true}
            >
              <Dropdown.Toggle
                btnStyle="flat"
                noCaret={true}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <span className="more-icon" />
              </Dropdown.Toggle>
              <Dropdown.Menu>{this.renderMenuDropdownLinks()}</Dropdown.Menu>
            </Dropdown>
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  /**
   * Render links for Team dropdown menu
   */
  renderMenuDropdownLinks() {
    if (this.props.sessionKey) {
      if (this.props.joined) {
        return (
          <Fragment>
            {this.renderMenuDropdownUpdateLink()}
            {this.renderMenuDropdownInviteLink()}
            <MenuItem
              eventKey={1}
              onSelect={(eventKey, event) => {
                this.props.handleLeaveTeam(event);
              }}
            >
              {localize("leave_team", this.props.language)}
            </MenuItem>
          </Fragment>
        );
      }
    }
  }

  /**
   * Render the join button
   */
  renderJoinTeamButton() {
    if (
      !this.props.joined &&
      this.props.teamJoinEnabled &&
      !this.props.teamJoinedInProject
    ) {
      if (this.props.sessionKey) {
        /* User is logged in */
        return (
          <div className="flex justify-content-center">
            <button
              className="button cta"
              onClick={(event) => {
                this.props.handleJoinTeam(event);
              }}
            >
              {localize("join_team", this.props.language)}
            </button>
          </div>
        );
      } else {
        /* User is not logged in */
        return (
          <div className="flex justify-content-center">
            <button
              className="button cta"
              onClick={this.props.handleOpenLoginDialog}
            >
              {localize("join_team", this.props.language)}
            </button>
            {this.props.showLoginDialog && this.renderLoginDialog()}
          </div>
        );
      }
    }

    return null;
  }

  /**
   * Render prompt login popup dialog
   */
  renderLoginDialog() {
    return (
      <LoginDialogContainer
        showModal={this.props.showLoginDialog}
        handleCloseLoginDialog={this.props.handleCloseLoginDialog}
      />
    );
  }

  /**
   * Render update link for Team Dropdown Menu
   */
  renderMenuDropdownUpdateLink() {
    if (this.props.teamCreateEnabled && this.props.teamCreator) {
      return (
        <Link
          to={
            !!this.props.projectId
              ? PROJECT_TEAM_UPDATE.format(this.props.projectId, this.props.id)
              : TEAM_UPDATE.format(this.props.id)
          }
        >
          {localize("update_team_text", this.props.language)}
        </Link>
      );
    } else {
      return null;
    }
  }

  /**
   * Render invite players for Team Dropdown Menu
   */
  renderMenuDropdownInviteLink() {
    if (this.props.teamJoinEnabled) {
      return (
        <Link
          to={
            !!this.props.projectId
              ? PROJECT_TEAM_INVITE.format(this.props.projectId, this.props.id)
              : TEAM_INVITE.format(this.props.id)
          }
        >
          {localize("invite_players_text", this.props.language)}
        </Link>
      );
    } else {
      return null;
    }
  }

  /**
   * Render user's profile image
   */
  renderUserProfileImage() {
    if (
      !!this.props.joined &&
      !!this.props.user &&
      !!this.props.user.photoLarge
    ) {
      return (
        <span className="square-image-wrapper team-profile-user-image">
          <span className="square-image circle border-gray-light">
            <img src={this.props.user.photoLarge} alt={this.props.user.name} />
          </span>
        </span>
      );
    } else if (!!this.props.joined) {
      return (
        <span className="square-image-wrapper team-profile-user-image">
          <span className="square-image circle border-gray-light">
            <img src={placeholder_profile} alt="" />
          </span>
        </span>
      );
    }
  }

  /**
   * Render Team description
   */
  renderDescription() {
    if (this.props.description !== "") {
      return (
        <div className="topmargin">
          <div className="text-align-center nomargin">
            <div dangerouslySetInnerHTML={{ __html: this.props.description }} />
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  /**
   * Render the component
   */
  render() {
    let image = this.props.image || placeholder_team;
    let name = this.props.name;

    return (
      <Fragment>
        {/* Render Mobile View */}
        <div className="hide-from-md pure-g team-container">
          <div className="pure-u-1-1 flex align-items-center">
            <div className="pure-u-3-8 flex flex-direction-column justify-content-center align-items-center">
              <p className="nomargin">
                <strong>{this.props.points.abbreviateNumber()}</strong>
              </p>
              <p className="nomargin">
                {this.props.points.localize(
                  "point_just_text",
                  "points_just_text",
                  this.props.language,
                  false,
                )}
              </p>
            </div>
            <div className="pure-u-1-4">
              <span className="square-image-wrapper">
                <span className="square-image circle border-gray-light">
                  <img src={image} alt={name} />
                </span>
              </span>
            </div>
            <div className="pure-u-3-8">
              {this.renderJoinTeamButton()}

              <div className="flex justify-content-flexend">
                {this.renderUserProfileImage()}
                {this.renderMenuDropdownButton()}
              </div>
            </div>
          </div>
          <div className="pure-u-1-1">
            <h2 className="team-name topmargin-5">
              <div dangerouslySetInnerHTML={{ __html: name }} />
            </h2>
            {this.renderDescription()}
          </div>
        </div>

        {/* Render Desktop View */}
        <div className="hide-below-md pure-g team-container align-items-center">
          <div className="pure-u-2-24" />
          <div className="pure-u-3-24">
            <span className="square-image-wrapper fullwidth">
              <span className="square-image circle border-gray-light">
                <img src={image} alt={name} />
              </span>
            </span>
          </div>
          <div className="pure-u-md-14-24 horizontalpadding relative">
            <div className="textcenter">
              <h2 className="team-name">
                <div dangerouslySetInnerHTML={{ __html: name }} />
              </h2>
              {this.renderDescription()}
              <div className="topmargin">
                <p className="text-align-center nomargin">
                  <strong>{this.props.points.abbreviateNumber() + " "}</strong>
                  {this.props.points.localize(
                    "point_just_text",
                    "points_just_text",
                    this.props.language,
                    false,
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="pure-u-3-24 flex">
            {this.renderJoinTeamButton()}
            {this.renderUserProfileImage()}
            {this.renderMenuDropdownButton()}
          </div>
        </div>
      </Fragment>
    );
  }
}

Team.propTypes = propTypes;

export default Team;
