/**
 * Return parameterized string like Java's String.format
 * @returns
 */
// eslint-disable-next-line
String.prototype.format = function () {
  let content = this;

  for (let i = 0; i < arguments.length; i++) {
    let replacement = "{" + i + "}";
    content = content.replace(replacement, arguments[i]);
  }
  return content;
};
