import * as Urls from "App/UrlVariables";

import {
  PLAYER_URL,
  CALLBACK_URL_PARAMETER_APPENDED,
  CALLBACK_ID_DEFAULT,
  CALLBACK_ID_PARAMETER_APPENDED,
  ENABLE_PLATFORM_LANG_URL_PARAM,
  PLATFORM_LANGUAGE_PARAMETER,
} from "config";

const urlServices = {
  defaultPath: function () {
    /*
      defaultPath stored as function instead of variable,
      so that only the latest window.location will be retrieved
    */
    return window.location.pathname + window.location.search;
  },

  getEmbedLangParam(language, appended) {
    if (!ENABLE_PLATFORM_LANG_URL_PARAM) {
      // Do not append lang param if disabled
      return "";
    }

    // We assume all languages are support, so filtering is not done here
    return (appended ? "&" : "?") + PLATFORM_LANGUAGE_PARAMETER + language;
  },

  getCallbackParams: function (sessionKey, language) {
    let params = this.getEmbedLangParam(language, true);

    if (CALLBACK_ID_DEFAULT != null) {
      params += CALLBACK_ID_PARAMETER_APPENDED + CALLBACK_ID_DEFAULT;
    } else {
      params +=
        CALLBACK_URL_PARAMETER_APPENDED +
        encodeURIComponent(
          PLAYER_URL + "frameloginsuccess?sessionKey=" + sessionKey,
        );
    }

    return params;
  },

  removeFirstSlash: function (path = this.defaultPath()) {
    return path.substring(0, 1) === "/" ? path.substring(1) : path;
  },

  /*
    Retrieve params from eg. window.location.search,
  */
  getUrlParams: function () {
    const PARAMS_STRING = new URLSearchParams(window.location.search);
    let params = [];
    for (const [key, value] of PARAMS_STRING) {
      params.push({ name: key, value: value });
    }

    return params;
  },

  // adding params to the current url
  addParam: function (key, value) {
    key = encodeURIComponent(key);
    value = encodeURIComponent(value);
    const params = new URLSearchParams(window.location.search);
    params.set(key, value);

    // reload page with new params
    document.location.search = params.toString();
  },

  /*
    Returns string value if key is found,
    null if key is not found
  */
  getUrlParamValueByKey: function (keyName) {
    const PARAMS = this.getUrlParams();

    for (let i = 0; i < PARAMS.length; i++) {
      if (PARAMS[i].name.toLowerCase() === keyName.toLowerCase()) {
        return PARAMS[i].value;
      }
    }

    return null;
  },

  isResendActivation: function (path = this.defaultPath()) {
    return Urls.RESEND_ACTIVATION.test(this.removeFirstSlash(path));
  },

  isGeneralLoginHome: function (path = this.defaultPath()) {
    return Urls.LOGIN_HOME.test(this.removeFirstSlash(path));
  },

  isGeneralLogin: function (path = this.defaultPath()) {
    return (
      Urls.LOGIN.test(this.removeFirstSlash(path)) ||
      Urls.LOGIN_SSO.test(this.removeFirstSlash(path)) ||
      Urls.SSO_LOGIN_SUCCESS.test(this.removeFirstSlash(path)) ||
      Urls.FRAME_LOGIN_SUCCESS.test(this.removeFirstSlash(path)) ||
      Urls.POST_LOGIN_INTERCEPT.test(this.removeFirstSlash(path))
    );
  },

  isGeneralTour: function (path = this.defaultPath()) {
    return Urls.LOGIN_TOUR.test(this.removeFirstSlash(path));
  },

  isGeneralRegister: function (path = this.defaultPath()) {
    return Urls.REGISTER.test(this.removeFirstSlash(path));
  },

  isGeneralResetPassword: function (path = this.defaultPath()) {
    return Urls.RESET_PASSWORD.test(this.removeFirstSlash(path));
  },

  isProjectLoginHome: function (path = this.defaultPath()) {
    return Urls.PROJECT_LOGIN_HOME_MULTI.test(this.removeFirstSlash(path));
  },

  isProjectLogin: function (path = this.defaultPath()) {
    return (
      Urls.PROJECT_LOGIN_MULTI.test(this.removeFirstSlash(path)) ||
      Urls.PROJECT_LOGIN_SSO.test(this.removeFirstSlash(path)) ||
      Urls.PROJECT_SSO_LOGIN_SUCCESS.test(this.removeFirstSlash(path)) ||
      Urls.PROJECT_FRAME_LOGIN_SUCCESS.test(this.removeFirstSlash(path)) ||
      Urls.PROJECT_POST_LOGIN_INTERCEPT.test(this.removeFirstSlash(path))
    );
  },

  isProjectRegister: function (path = this.defaultPath()) {
    return Urls.PROJECT_REGISTER_MULTI.test(this.removeFirstSlash(path));
  },

  isProjectResetPassword: function (path = this.defaultPath()) {
    return Urls.PROJECT_RESET_PASSWORD_MULTI.test(this.removeFirstSlash(path));
  },

  isLoginHome: function (path = this.defaultPath()) {
    return this.isGeneralLoginHome(path) || this.isProjectLoginHome(path);
  },

  isLogin: function (path = this.defaultPath()) {
    return this.isGeneralLogin(path) || this.isProjectLogin(path);
  },

  isTour: function (path = this.defaultPath()) {
    return this.isGeneralTour(path);
  },

  isRegister: function (path = this.defaultPath()) {
    return this.isGeneralRegister(path) || this.isProjectRegister(path);
  },

  isResetPassword: function (path = this.defaultPath()) {
    return (
      this.isGeneralResetPassword(path) || this.isProjectResetPassword(path)
    );
  },

  isAccountAccessPage: function (path = this.defaultPath()) {
    return (
      this.isLoginHome(path) ||
      this.isLogin(path) ||
      this.isRegister(path) ||
      this.isResetPassword(path) ||
      this.isResendActivation(path)
    );
  },

  isLoginRedirectionPage: function (path = this.defaultPath()) {
    return this.isAccountAccessPage(path) || this.isTour(path);
  },
};

export default urlServices;
