import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import Loading from "components/shared/Loading";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";
import SearchBar from "components/shared/SearchBar/SearchBar";
import ProjectsListPage from "./ProjectsListPage";

import localize from "lang/localize";

const propTypes = {
  project: PropTypes.array,
  more: PropTypes.bool,
  keywords: PropTypes.string,
  handleMore: PropTypes.func,
  handleKeyPress: PropTypes.func,
  handleSearchChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  language: PropTypes.string,
};

const defaultTypes = {
  more: false,
};

class SearchProjectsPage extends Component {
  renderProjectsList() {
    if (this.props.projects && this.props.projects.length !== 0) {
      return (
        <div className="project-search">
          <ProjectsListPage
            projects={this.props.projects}
            more={this.props.more}
            handleMore={this.props.handleMore}
            language={this.props.language}
          />
        </div>
      );
    } else if (this.props.projects) {
      return (
        <GenericErrorPage
          message={localize("empty_bundles_table_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }

  render() {
    return (
      <Fragment>
        <div className="container topmargin-20">
          <div className="project-cards-wrapper">
            <div className="project-card-container">
              <SearchBar
                handleSubmit={this.props.handleSubmit}
                handleKeyPress={this.props.handleKeyPress}
                handleSearchChange={this.props.handleSearchChange}
                placeholder={localize(
                  "home_search_game_placeholder_text",
                  this.props.language,
                )}
                defaultValue={this.props.keywords}
                language={this.props.language}
              />
            </div>
          </div>
        </div>
        {this.renderProjectsList()}
      </Fragment>
    );
  }
}

ProjectsListPage.propTypes = propTypes;
ProjectsListPage.defaultTypes = defaultTypes;

export default SearchProjectsPage;
