import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export function handleNumberInput(event) {
  let text = event.target.value.replace(/[^-0-9]/g, "");

  if (text[0] === "0") {
    text = text.substring(1);
  } else if (text.length > 1 && text[text.length - 1] === "-") {
    text = text.substring(0, text.length - 1);
  }

  text = text.replace("--", "-");
  text = text.replace("-0", "-");

  if (parseInt(text) > 2147483647) {
    text = "2147483647";
  } else if (parseInt(text) < -2147483646) {
    text = "-2147483646";
  }

  event.target.value = text;
}

const NumberInput = (props) => {
  let placeholder = props.placeholder;

  return (
    <input
      type="text"
      placeholder={placeholder}
      onChange={props.onChange}
      onInput={handleNumberInput}
      {...props}
    />
  );
};

NumberInput.propTypes = propTypes;

export default NumberInput;
