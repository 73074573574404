import { combineReducers } from "redux";

import {
  SET_SESSIONKEY,
  SET_USER,
  SET_USER_ID,
  SET_TEAM,
  SET_PROJECT_ID,
  SET_PROJECT,
  SET_TOPIC_CATEGORY,
  SET_TOPIC,
  SET_CHALLENGE_CATEGORY,
  SET_CHALLENGE,
  SET_BOARD,
  SET_POST,
  SET_SIDEBAR_STATE,
  SET_TOPBAR,
  ADD_TOPBAR_POINTS,
  SET_PROFILEDRAWER,
  ADD_PROFILEDRAWER_POINTS,
  SET_ALERT,
  CLEAR_ALERT,
  SET_CHALLENGE_COMPLETED,
  CLEAR_CHALLENGE_COMPLETED,
  SET_ACHIEVEMENTS,
  CLEAR_ACHIEVEMENTS,
  SET_FLOATINGBUTTONS_STATE,
  SET_BUTTONS,
  SET_AWARDABLE_ACTION,
  CLEAR_AWARDABLE_ACTION,
  SET_LANGUAGE,
  SET_SHOW_QR_MODAL,
} from "./actions";

function sessionKey(state = null, action) {
  switch (action.type) {
    case SET_SESSIONKEY:
      return action.sessionKey;
    default:
      return state;
  }
}

function user(state = {}, action) {
  switch (action.type) {
    case SET_USER:
      return action.user;
    case SET_USER_ID:
      return Object.assign({}, state, { id: action.userId });
    default:
      return state;
  }
}

function team(state = {}, action) {
  switch (action.type) {
    case SET_TEAM:
      if (action.team) {
        return action.team;
      } else {
        return state;
      }
    default:
      return state;
  }
}

function project(state = {}, action) {
  switch (action.type) {
    case SET_PROJECT:
      return action.project;
    default:
      return state;
  }
}

function topic(state = {}, action) {
  switch (action.type) {
    case SET_TOPIC:
      return action.topic;
    default:
      return state;
  }
}

function board(state = {}, action) {
  switch (action.type) {
    case SET_BOARD:
      return action.board;
    default:
      return state;
  }
}

function post(state = {}, action) {
  switch (action.type) {
    case SET_POST:
      return action.post;
    default:
      return state;
  }
}

function projectId(state = null, action) {
  switch (action.type) {
    case SET_PROJECT_ID:
      if (action.projectId) {
        return parseInt(action.projectId, 10);
      } else {
        return null;
      }
    case SET_PROJECT:
      if (action.project && action.project.id) {
        return parseInt(action.project.id, 10);
      } else {
        return null;
      }
    default:
      return state;
  }
}

function sidebar(state = {}, action) {
  switch (action.type) {
    case SET_PROJECT:
      return Object.assign({}, state, {
        project: action.project,
      });
    case SET_TOPIC:
      return Object.assign({}, state, {
        topic: action.topic,
      });
    case SET_BOARD:
      return Object.assign({}, state, {
        board: action.board,
      });
    case SET_POST:
      return Object.assign({}, state, {
        post: action.post,
      });
    case SET_SIDEBAR_STATE:
      return Object.assign({}, state, {
        state: action.state,
      });
    default:
      return state;
  }
}

function topbar(state = {}, action) {
  switch (action.type) {
    case SET_TOPBAR:
      return Object.assign({}, state, action.info);
    case ADD_TOPBAR_POINTS:
      return Object.assign({}, state, { points: state.points + action.points });
    case SET_PROJECT:
      return Object.assign({}, state, {
        project: action.project,
      });
    case SET_TOPIC:
      return Object.assign({}, state, {
        topic: action.topic,
      });
    case SET_TOPIC_CATEGORY:
      return Object.assign({}, state, {
        topicCategory: action.topicCategory,
      });
    case SET_CHALLENGE_CATEGORY:
      return Object.assign({}, state, {
        challengeCategory: action.challengeCategory,
      });
    case SET_CHALLENGE:
      return Object.assign({}, state, {
        challenge: action.challenge,
      });
    case SET_BOARD:
      return Object.assign({}, state, {
        board: action.board,
      });
    case SET_POST:
      return Object.assign({}, state, {
        post: action.post,
      });
    default:
      return state;
  }
}

function profileDrawer(state = {}, action) {
  switch (action.type) {
    case SET_PROFILEDRAWER:
      return Object.assign({}, state, action.info);
    case ADD_PROFILEDRAWER_POINTS:
      return Object.assign({}, state, { points: state.points + action.points });
    default:
      return state;
  }
}

function alerts(state = [], action) {
  switch (action.type) {
    case SET_ALERT:
      return [
        {
          alert: action.alert,
          id: action.id,
        },
        ...state,
      ];
    case CLEAR_ALERT:
      return state.filter((alert) => alert.id !== action.id);
    default:
      return state;
  }
}

/* Challenge completions */
function challengeCompleted(state = [], action) {
  switch (action.type) {
    case SET_CHALLENGE_COMPLETED:
      return [
        {
          challengeCompleted: action.challengeCompleted,
          id: action.id,
        },
        ...state,
      ];
    case CLEAR_CHALLENGE_COMPLETED:
      return state.filter(
        (challengeCompleted) => challengeCompleted.id !== action.id,
      );
    default:
      return state;
  }
}

/* Achievements */
function achievements(state = [], action) {
  switch (action.type) {
    case SET_ACHIEVEMENTS:
      return [
        {
          achievements: action.achievements,
          id: action.id,
        },
        ...state,
      ];
    case CLEAR_ACHIEVEMENTS:
      return state.filter((achievements) => achievements.id !== action.id);
    default:
      return state;
  }
}

/* Floating buttons */
function floatingButtons(state = { showQRModal: false }, action) {
  switch (action.type) {
    case SET_PROJECT:
      return Object.assign({}, state, {
        project: action.project,
      });
    case SET_SHOW_QR_MODAL:
      return Object.assign({}, state, {
        showQRModal: action.isShow,
      });
    case SET_BUTTONS:
      return Object.assign({}, state, {
        buttons: action.buttons,
      });
    case SET_FLOATINGBUTTONS_STATE:
      return Object.assign({}, state, {
        state: action.state,
      });
    default:
      return state;
  }
}

function topicCategoryId(state = null, action) {
  switch (action.type) {
    case SET_TOPIC_CATEGORY:
      return action.topicCategory ? action.topicCategory.id : null;
    default:
      return state;
  }
}

function challengeCategoryId(state = null, action) {
  switch (action.type) {
    case SET_CHALLENGE_CATEGORY:
      return action.challengeCategory ? action.challengeCategory.id : null;
    default:
      return state;
  }
}

function awardableActions(state = [], action) {
  switch (action.type) {
    case SET_AWARDABLE_ACTION:
      return [
        {
          awardableAction: action.awardableAction,
          id: action.id,
        },
        ...state,
      ];
    case CLEAR_AWARDABLE_ACTION:
      return state.filter(
        (awardableAction) => awardableAction.id !== action.id,
      );
    default:
      return state;
  }
}

function language(state = null, action) {
  switch (action.type) {
    case SET_LANGUAGE:
      return action.language;
    default:
      return state;
  }
}

const allReducers = combineReducers({
  sessionKey,
  user,
  team,
  project,
  topic,
  board,
  post,
  projectId,
  sidebar,
  topbar,
  profileDrawer,
  alerts,
  challengeCompleted,
  achievements,
  floatingButtons,
  topicCategoryId,
  challengeCategoryId,
  awardableActions,
  language,
});
export default allReducers;
