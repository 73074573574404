import React, { Component } from "react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";

import ResendActivationPage from "./ResendActivationPage";

import loginServices from "services/loginServices";

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    userId: state.user ? state.user.id : null,
    projectId: state.projectId,
    language: state.language,
  };
};

export class ResendActivationContainer extends Component {
  componentDidMount() {
    if (this.props.sessionKey && this.props.userId) {
      loginServices.postLoginReplaceAndNavigate(
        this.props.projectId,
        null,
        false,
      );
    } else {
      // Set page identifier class to .component-container
      let mainComponent = ReactDOM.findDOMNode(this).parentNode;
      if (!mainComponent.classList.contains("resendActivationPage")) {
        mainComponent.className += " resendActivationPage";
      }

      // Add other page classes to body DOM
      let bodyDOM = document.body; // <body> tag
      if (!bodyDOM.classList.contains("page-not-loggedin")) {
        bodyDOM.className += " page-not-loggedin";
      }
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    // Remove page identifier class from .component-container
    let mainComponent = ReactDOM.findDOMNode(this).parentNode;
    if (mainComponent.classList.contains("resendActivationPage")) {
      mainComponent.classList.remove("resendActivationPage");
    }

    // Remove other page classes from body DOM
    let bodyDOM = document.body;
    if (bodyDOM.classList.contains("page-not-loggedin")) {
      bodyDOM.classList.remove("page-not-loggedin");
    }
  }

  render() {
    return <ResendActivationPage language={this.props.language} />;
  }
}

export default connect(mapStateToProps)(ResendActivationContainer);
