import React, { Component } from "react";
import PropTypes from "prop-types";

import powered_by_gametize_image from "images/core/home/powered-by-gametize.png";
import Link from "components/shared/Link/Link";
import TourSlider from "./TourSlider";
import { ENABLE_TOUR_SKIP } from "config";

import localize from "lang/localize";

const propTypes = {
  completedTour: PropTypes.bool,
  lastSlide: PropTypes.bool,
  tourScreens: PropTypes.array,
  redirectUrl: PropTypes.string,
  apiCallComplete: PropTypes.bool,
  setCompletedTour: PropTypes.func.isRequired,
  setSlide: PropTypes.func.isRequired,
  handleCloseClick: PropTypes.func.isRequired,
  language: PropTypes.string,
};

class TourPage extends Component {
  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    let bodyDOM = document.body; // <body> tag

    // Set page identifier class to body DOM
    if (!bodyDOM.classList.contains("tourPage")) {
      bodyDOM.className += " tourPage";
    }

    // Add other page classes to body DOM
    if (!bodyDOM.classList.contains("page-not-loggedin")) {
      bodyDOM.className += " page-not-loggedin";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    let bodyDOM = document.body; // <body> tag

    // Remove page identifier class from body DOM
    if (bodyDOM.classList.contains("tourPage")) {
      bodyDOM.classList.remove("tourPage");
    }

    // Remove other page classes from body DOM
    if (bodyDOM.classList.contains("page-not-loggedin")) {
      bodyDOM.classList.remove("page-not-loggedin");
    }
  }

  /**
   * Render tour screen slider
   */
  renderTourSlider() {
    return (
      <TourSlider
        tourScreens={this.props.tourScreens}
        completedTour={this.props.completedTour}
        apiCallComplete={this.props.apiCallComplete}
        setCompletedTour={this.props.setCompletedTour}
        setSlide={this.props.setSlide}
        handleCloseClick={this.props.handleCloseClick}
        language={this.props.language}
      />
    );
  }

  /* To be added below .tour-cta-container if necessary */
  renderPoweredByGametizeImageContainer() {
    return (
      <div className="pure-g">
        <div className="pure-u-3-8 pure-u-sm-5-12" />
        <div className="pure-u-1-4 pure-u-sm-1-6">
          <a
            href="https://www.gametize.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={powered_by_gametize_image}
              className="imgresponsive"
              alt=""
            />
          </a>
        </div>
        <div className="pure-u-3-8 pure-u-sm-5-12" />
      </div>
    );
  }

  /**
   * Render tour page with tour slider
   */
  render() {
    return (
      <div className="container verticalpadding relative tour-interface">
        <button
          onClick={(e) => this.props.handleCloseClick(e)}
          className="close-button-container"
        >
          <i className="fa close-button" aria-hidden="true" />
        </button>
        <div className="innerblock flex-from-md flex-direction-column justify-content-center">
          <div className="pure-g">
            <div className="pure-u-1-1 bottommargin-30">
              {this.renderTourSlider()}
            </div>
          </div>
          <div className="pure-g tour-cta-container">
            <div className="pure-u-1 flex justify-content-center bottommargin-10">
              {this.props.lastSlide ? (
                <Link
                  to={this.props.redirectUrl}
                  className="button cta"
                  id="tourLinkContinue"
                  onClick={(e) => this.props.handleCloseClick(e)}
                >
                  {localize("button_quest_continue", this.props.language)}
                </Link>
              ) : (
                ENABLE_TOUR_SKIP && (
                  <Link
                    to={this.props.redirectUrl}
                    className="default"
                    id="tourLinkSkip"
                    onClick={(e) => this.props.handleCloseClick(e)}
                  >
                    {localize("button_tour_skip", this.props.language)}
                  </Link>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TourPage.propTypes = propTypes;

export default TourPage;
