import React, { Component } from "react";
import { connect } from "react-redux";
import debounce from "lodash.debounce";

import NotificationsPage from "./NotificationsPage";
import Loading from "components/shared/Loading";
import NotLoggedInContainer from "components/shared/NotLoggedIn/NotLoggedInContainer";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";

import { LOAD_MORE_COUNT } from "config";
import { GET_NOTIFICATIONS, GET_TOPICS } from "services/api";
import listenerServices from "services/listenerServices";
import { setProfileDrawer, setProject, setButtons } from "actions";
import getApiGenerator from "services/getApiGenerator";
import localize from "lang/localize";

export const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    sessionKey: state.sessionKey,
    projectId: state.projectId,
    language: state.language,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setProfileDrawer: (info) => {
      dispatch(setProfileDrawer(info));
    },
    setProject: (project) => {
      dispatch(setProject(project));
    },
    setButtons: (buttons) => {
      dispatch(setButtons(buttons));
    },
  };
};

export class NotificationContainer extends Component {
  constructor() {
    super();
    this.state = {
      notifications: null,
      more: false,
      page: 1,
      topics: null,
      error: null,
      code: null,
      isLoadingMore: false,
    };
    this.handleMore = this.handleMore.bind(this);
    this.getNotifications = this.getNotifications.bind(this);
  }

  componentDidMount() {
    if (this.props.sessionKey) {
      this.getNotifications(this.state.page);
    }
    /* Retrieve Project information */
    if (this.props.projectId) {
      this.getTopics();
    }
    window.addEventListener("scroll", this.handleMore);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.sessionKey !== this.props.sessionKey) {
      this.getNotifications(this.state.page);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleMore);
  }

  handleMore = debounce(() => {
    const {
      getNotifications,
      state: { more, isLoadingMore, page },
    } = this;
    if (!more) {
      return;
    } else if (!isLoadingMore && more) {
      if (listenerServices.isAtScrollThreshold()) {
        this.setState(() => ({
          isLoadingMore: true,
        }));
        getNotifications(page);
      }
    }
  }, 100);

  getNotifications(page) {
    getApiGenerator(
      GET_NOTIFICATIONS,
      {
        page: page,
        bundle_id: this.props.projectId,
        limit:
          LOAD_MORE_COUNT *
          2 /* Fix pagination bug when list is shorter than window height */,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        // 500 return means no notifications
        if (res.body.code === 500) {
          this.setState({ notifications: [], isLoadingMore: false });
        }
      } else {
        const notifications = this.state.notifications
          ? this.state.notifications.slice()
          : [];
        this.setState({
          notifications: notifications.concat(res.body.data),
          more: res.body.more,
          page: this.state.page + 1,
          isLoadingMore: false,
        });
        this.props.setProfileDrawer({ notifications: 0 });
      }
    });
  }

  getTopics() {
    getApiGenerator(
      GET_TOPICS.format(this.props.projectId),
      {
        page: 1,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            topics: [],
            code: 500,
            error: res.body.error,
          });
        }
      } else {
        this.setState({
          topics: res.body.data,
          code: res.body.code,
          error: "",
        });

        const BUTTONS =
          res.body.buttons && res.body.buttons.length > 0
            ? res.body.buttons
            : null;

        this.props.setProject(res.body.game);
        this.props.setButtons(BUTTONS);
      }
    });
  }

  render() {
    if (!this.props.user.id) {
      return (
        <NotLoggedInContainer
          showLogin={false}
          language={this.props.language}
          fullHeight={true}
        />
      );
    } else if (
      /* Project ID is available, but Project is private */
      this.props.projectId &&
      this.state.code === 500 &&
      this.state.error &&
      this.state.error.indexOf("private") !== -1
    ) {
      return (
        <GenericErrorPage
          message={localize(
            "bundle_private_logged_in_text",
            this.props.language,
          )}
          language={this.props.language}
        />
      );
    } else if (
      /* Project ID is available, but Project does not exist */
      this.props.projectId &&
      Array.isArray(this.state.topics) &&
      (this.state.error.indexOf("not exist") !== -1 ||
        this.state.error.indexOf("not published") !== -1)
    ) {
      return (
        <GenericErrorPage
          message={localize("bundle_not_found_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else if (
      this.state.notifications &&
      this.state.notifications.length !== 0
    ) {
      return (
        <NotificationsPage
          isLoadingMore={this.state.isLoadingMore}
          notifications={this.state.notifications}
          more={this.state.more}
          handleMore={this.handleMore}
          language={this.props.language}
          projectId={this.props.projectId}
          userId={this.props.user.id}
        />
      );
    } else if (this.state.notifications) {
      return (
        <GenericErrorPage
          message={localize(
            "empty_notification_table_text",
            this.props.language,
          )}
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationContainer);
