import linkifyHtml from "linkify-html";

/**
 * Parse text to get any URLs and link them
 *
 * @param {String} text - String to parse for links
 * @param {Boolean} newWindow - Whether to open links in a new window
 * @param {Boolean} secondaryLink - Whether to append secondaryLink class (to allow clickthrough on ChallengeCard and ClaimResult)
 * @returns {String} Parsed text with links in anchor tags
 */
function urlParse(text, newWindow, secondaryLink) {
  // Check if text is any of the falsy values
  if (!text) {
    return null;
  }

  // Define regular expression for delimiters (spaces, carriage returns, line feeds) and conditions to check for URLs
  const delimiterRegexp = /(\s+|\r|\n)/;
  const includeConditions = ["https://", "http://"];
  const excludeConditions = ["src", "href"];
  let parsedText = text;

  // Split the text into each word using the delimiter regular expression and check:
  // if the word contains an item in the includeConditions, attempt to convert the word into a link
  // if the word contains an item in the excludeConditions, disregard the conversion
  let textArray = text.split(delimiterRegexp);

  for (let i = 0; i < textArray.length; i++) {
    let splittedText = textArray[i];
    let includeCheck = includeConditions.some((condition) =>
      splittedText.includes(condition),
    );
    let excludeCheck = excludeConditions.some((condition) =>
      splittedText.includes(condition),
    );
    if (includeCheck && !excludeCheck) {
      try {
        textArray[i] = linkifyHtml(splittedText, {
          defaultProtocol: "https",
          className:
            "link default break-url " + (secondaryLink ? "secondaryLink" : ""),
          target: newWindow ? "_blank" : "",
          rel: "external",
        });
      } catch (err) {
        return text;
      }
    }
  }
  parsedText = textArray.join(" ");

  return parsedText;
}

export default urlParse;
