import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import DeleteItemDialog from "./DeleteItemDialog";

import { DELETE_POST, DELETE_POST_COMMENT } from "services/api";
import { showAlertWithTimeout } from "actions";
import getApiGenerator2 from "services/getApiGenerator2";
import localize from "lang/localize";

const propTypes = {
  showDialog: PropTypes.bool,
  postId: PropTypes.number,
  commendId: PropTypes.number,
  isSubComment: PropTypes.bool,
  handleCloseDialog: PropTypes.func,
  removePost: PropTypes.func,
  removeComment: PropTypes.func,
  type: PropTypes.string,
};

const defaultProps = {
  showDialog: false,
  isSubComment: false,
};

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    userId: state.user ? state.user.id : null,
    language: state.language,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showAlertWithTimeout: (alert) => {
      dispatch(showAlertWithTimeout(alert));
    },
  };
};

class DeleteItemDialogContainer extends Component {
  constructor(props) {
    super(props);

    this.handleClickDeletePost = this.handleClickDeletePost.bind(this);
    this.handleClickDeleteComment = this.handleClickDeleteComment.bind(this);
  }

  handleClickDeletePost() {
    this.deletePost(this.props.postId);
    this.props.removePost(this.props.postId);
    this.props.handleCloseDialog();
  }

  handleClickDeleteComment() {
    this.deleteComment(this.props.commentId);
    this.props.removeComment(this.props.commentId);
    this.props.handleCloseDialog();
  }

  deletePost(post_id) {
    getApiGenerator2(
      DELETE_POST.format(post_id),
      {},
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error) {
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error",
          });
        }
      } else {
        this.props.showAlertWithTimeout({
          text: localize("post_delete_success", this.props.language),
          type: "success",
        });
      }
    });
  }

  deleteComment(comment_id) {
    getApiGenerator2(
      DELETE_POST_COMMENT.format(comment_id),
      {},
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error) {
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error",
          });
        }
      } else {
        this.props.showAlertWithTimeout({
          text: localize("reply_delete_success", this.props.language),
          type: "success",
        });
      }
    });
  }

  render() {
    return (
      <DeleteItemDialog
        showDialog={this.props.showDialog}
        postId={this.props.postId}
        handleCloseDialog={this.props.handleCloseDialog}
        handleClickDeletePost={this.handleClickDeletePost}
        handleClickDeleteComment={this.handleClickDeleteComment}
        language={this.props.language}
        type={this.props.type}
      />
    );
  }
}

DeleteItemDialogContainer.propTypes = propTypes;
DeleteItemDialogContainer.defaultProps = defaultProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteItemDialogContainer);
