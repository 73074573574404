import React, { Component } from "react";
import PropTypes from "prop-types";

import TextboxInput from "components/shared/Inputs/TextboxInput";
import NumberInput from "components/shared/Inputs/NumberInput";
import DecimalInput from "components/shared/Inputs/DecimalInput";
import AdvancedSelectInput from "components/shared/Inputs/AdvancedSelectInput";
import ImageInput from "components/shared/Inputs/ImageInput";
import DateInput from "components/shared/Inputs/DateInput/DateInput";
import DurationInput from "components/shared/Inputs/DurationInput";
import CountdownButton from "components/shared/Countdown/CountdownButton";
import CharacterCounter from "components/shared/Inputs/CharacterCounter";

import localize from "lang/localize";

const propTypes = {
  fields: PropTypes.array.isRequired,
  photoRequired: PropTypes.bool,
  noPhoto: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  showAlertWithTimeout: PropTypes.func,
  submitted: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  challengeMinViewDuration: PropTypes.number,
  isTimerActive: PropTypes.bool,
  setTimerActive: PropTypes.func,
  countdownTime: PropTypes.number,
  language: PropTypes.string,
  charLimit: PropTypes.number,
};

const defaultProps = {
  photoRequired: false,
};

class MultiFieldChallenge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: props.fields,
      image: null,
      imagename: null,
    };

    this.onMFChangeDate = this.onMFChangeDate.bind(this);
    this.onMFChangeDuration = this.onMFChangeDuration.bind(this);
  }

  onOptionChange(name, option) {
    // option is always the last parameter
    const index = parseInt(name, 10);
    let fields = this.state.fields.slice();
    // API works with text only!
    fields[index].value = "" + option.value;
    this.setState({ fields: fields });
  }

  onMFChange(event) {
    const index = parseInt(event.currentTarget.name, 10);
    let fields = this.state.fields.slice();
    fields[index].value = event.currentTarget.value;

    // check if a text field is over the character limit
    fields[index].type === "TEXT" &&
    this.props.charLimit !== 0 &&
    fields[index].value.length > this.props.charLimit
      ? (fields[index].textexceeded = true)
      : (fields[index].textexceeded = false);

    // check if there is any text field that is over the character limit
    fields.find((o) => o.textexceeded === true)
      ? this.setState({ textexceeded: true })
      : this.setState({ textexceeded: false });

    this.setState({ fields: fields });
  }

  onMFChangeDate(date, index) {
    let fields = this.state.fields.slice();
    fields[index].value = date ? date.format("DD MMM YYYY") : null;
    this.setState({ fields: fields });
  }

  onMFChangeDuration(index, seconds) {
    let fields = this.state.fields.slice();
    fields[index].value = seconds;
    this.setState({ fields: fields });
  }

  onMFChangeButtonOptions(index, id) {
    let fields = this.state.fields.slice();
    fields[index].value = id.toString();
    this.setState({ fields: fields });
  }

  renderFields() {
    const fields = this.state.fields;
    return fields.map((field, index) => {
      let fieldTitle = field.title;
      switch (field.type) {
        case "TEXT":
          return (
            <div key={index} className="custom-form-row relative">
              <label>{fieldTitle}</label>
              <TextboxInput
                name={index}
                onChange={this.onMFChange.bind(this)}
                placeholder={localize(
                  "comment_placeholder_text",
                  this.props.language,
                )}
                readOnly={!this.props.isLoggedIn}
                className={!this.props.isLoggedIn ? "cursor-not-allowed" : ""}
              />
              <CharacterCounter
                input={field.value !== undefined ? field.value : ""}
                charLimit={this.props.charLimit}
              />
            </div>
          );
        case "INTEGER":
          return (
            <div key={index} className="custom-form-row">
              <label>{fieldTitle}</label>
              <NumberInput
                name={index}
                onChange={this.onMFChange.bind(this)}
                placeholder={localize(
                  "claim_number_placeholder",
                  this.props.language,
                )}
                readOnly={!this.props.isLoggedIn}
                className={!this.props.isLoggedIn ? "cursor-not-allowed" : ""}
              />
            </div>
          );
        case "FLOAT":
          return (
            <div key={index} className="custom-form-row">
              <label>{fieldTitle}</label>
              <DecimalInput
                name={index}
                onChange={this.onMFChange.bind(this)}
                placeholder={localize(
                  "claim_number_placeholder",
                  this.props.language,
                )}
                readOnly={!this.props.isLoggedIn}
                className={!this.props.isLoggedIn ? "cursor-not-allowed" : ""}
              />
            </div>
          );
        case "CALENDAR":
          return (
            <div key={index} className="custom-form-row">
              <label>{fieldTitle}</label>
              <div>
                <DateInput
                  onMFChangeDate={this.onMFChangeDate}
                  keyIndex={index}
                  field={this.state.fields[index]}
                  disabled={!this.props.isLoggedIn}
                  language={this.props.language}
                />
              </div>
            </div>
          );
        case "OPTION":
          return (
            <div key={index} className="custom-form-row">
              <label>{fieldTitle}</label>
              {this.renderOptions(field, index)}
            </div>
          );
        case "DURATION":
          return (
            <div key={index} className="custom-form-row">
              <label>{fieldTitle}</label>
              <div>
                <DurationInput
                  name={index}
                  onMFChangeDuration={this.onMFChangeDuration}
                  disabled={!this.props.isLoggedIn}
                />
              </div>
            </div>
          );
        default:
          return null;
      }
    });
  }

  onImageChange(image) {
    if (image[0]) {
      this.setState({
        image: image[0],
        imagename: image[0].name,
      });
    }
  }

  onImageReset() {
    this.setState({
      image: null,
      imagename: null,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    let fields = this.state.fields.map((field) => ({
      field_id: field.id,
      field_entry: field.value || "",
    }));
    this.props.handleSubmit(
      { entries: JSON.stringify(fields) },
      this.state.image,
      this.state.imagename,
    );
    return true;
  }

  renderOptions(field, index) {
    const options = field.options.map((option) => ({
      value: option.id,
      label: option.title,
    }));

    return (
      <AdvancedSelectInput
        name={index}
        options={options}
        placeholder={localize(
          "claim_multiple_choice_placeholder",
          this.props.language,
        )}
        className="fullwidth"
        onChange={this.onOptionChange.bind(this)}
        disabled={!this.props.isLoggedIn}
      />
    );
  }

  render() {
    if (this.props.noPhoto) {
      return (
        <form className="pure-form">
          <fieldset>
            <div className="pure-u-1-1 pure-u-md-24-24">
              {this.renderFields()}
            </div>
            {this.props.isLoggedIn ? (
              <button
                className={
                  "button automargin pure-u-1-1 pure-u-md-6-24 " +
                  (this.props.submitted ||
                  (this.props.challengeMinViewDuration &&
                    this.props.isTimerActive) ||
                  this.state.textexceeded
                    ? "inactive"
                    : "cta")
                }
                onClick={this.handleSubmit.bind(this)}
                disabled={
                  this.props.submitted ||
                  (this.props.challengeMinViewDuration &&
                    this.props.isTimerActive) ||
                  this.state.textexceeded
                }
                id="submitCompletionButton"
              >
                {this.props.challengeMinViewDuration &&
                this.props.countdownTime &&
                this.props.isTimerActive ? (
                  <CountdownButton
                    countdownTime={this.props.countdownTime}
                    setTimerActive={this.props.setTimerActive}
                    language={this.props.language}
                  />
                ) : this.props.submitted ? (
                  localize("button_submitting", this.props.language)
                ) : (
                  localize("button_claim_get_points", this.props.language)
                )}
              </button>
            ) : (
              <button
                className={
                  "button automargin pure-u-1-1 pure-u-md-6-24 " +
                  (this.props.submitted ||
                  (this.props.challengeMinViewDuration &&
                    this.props.isTimerActive) ||
                  this.state.textexceeded
                    ? "inactive"
                    : "cta")
                }
                disabled={
                  this.props.submitted ||
                  (this.props.challengeMinViewDuration &&
                    this.props.isTimerActive) ||
                  this.state.textexceeded
                }
                id="loginBeforeSubmitCompletionButton"
              >
                {this.props.challengeMinViewDuration &&
                this.props.countdownTime &&
                this.props.isTimerActive ? (
                  <CountdownButton
                    countdownTime={this.props.countdownTime}
                    setTimerActive={this.props.setTimerActive}
                    language={this.props.language}
                  />
                ) : this.props.submitted ? (
                  localize("button_submitting", this.props.language)
                ) : (
                  localize("button_claim_get_points", this.props.language)
                )}
              </button>
            )}
          </fieldset>
        </form>
      );
    } else {
      return (
        <div className="pure-form">
          {/*
              Set the above back to <form className="pure-form">
              if we are no longer using <input type="file">
              for image inputs
          */}
          <fieldset>
            <div className="pure-u-1-1 pure-u-md-15-24">
              {this.renderFields()}
            </div>
            <div className="pure-u-1-1 pure-u-md-1-24" />
            <div className="pure-u-1-1 pure-u-md-8-24">
              <ImageInput
                imagename={this.state.imagename}
                onDrop={this.onImageChange.bind(this)}
                resetFiles={this.onImageReset.bind(this)}
                showAlertWithTimeout={this.props.showAlertWithTimeout}
                disabled={!this.props.isLoggedIn}
                language={this.props.language}
              />
            </div>
            {this.props.isLoggedIn ? (
              <button
                className={
                  "button automargin pure-u-1-1 pure-u-md-6-24 " +
                  (this.props.submitted ||
                  (this.props.photoRequired && this.state.image == null) ||
                  (this.props.challengeMinViewDuration &&
                    this.props.isTimerActive) ||
                  this.state.textexceeded
                    ? "inactive"
                    : "cta")
                }
                onClick={this.handleSubmit.bind(this)}
                disabled={
                  this.props.submitted ||
                  (this.props.photoRequired && this.state.image == null) ||
                  (this.props.challengeMinViewDuration &&
                    this.props.isTimerActive) ||
                  this.state.textexceeded
                }
                id="submitCompletionButton"
              >
                {this.props.challengeMinViewDuration &&
                this.props.countdownTime &&
                this.props.isTimerActive ? (
                  <CountdownButton
                    countdownTime={this.props.countdownTime}
                    setTimerActive={this.props.setTimerActive}
                    language={this.props.language}
                  />
                ) : this.props.submitted ? (
                  localize("button_submitting", this.props.language)
                ) : this.props.photoRequired ? (
                  localize(
                    "button_claim_upload_photo_to_submit",
                    this.props.language,
                  )
                ) : (
                  localize("button_claim_get_points", this.props.language)
                )}
              </button>
            ) : (
              <button
                className={
                  "button automargin pure-u-1-1 pure-u-md-6-24 " +
                  (this.props.submitted ||
                  (this.props.photoRequired && this.state.image == null) ||
                  (this.props.challengeMinViewDuration &&
                    this.props.isTimerActive) ||
                  this.state.textexceeded
                    ? "inactive"
                    : "cta")
                }
                disabled={
                  this.props.submitted ||
                  (this.props.photoRequired && this.state.image == null) ||
                  (this.props.challengeMinViewDuration &&
                    this.props.isTimerActive) ||
                  this.state.textexceeded
                }
                id="loginBeforeSubmitCompletionButton"
              >
                {this.props.challengeMinViewDuration &&
                this.props.countdownTime &&
                this.props.isTimerActive ? (
                  <CountdownButton
                    countdownTime={this.props.countdownTime}
                    setTimerActive={this.props.setTimerActive}
                    language={this.props.language}
                  />
                ) : this.props.submitted ? (
                  localize("button_submitting", this.props.language)
                ) : this.props.photoRequired ? (
                  localize(
                    "button_claim_upload_photo_to_submit",
                    this.props.language,
                  )
                ) : (
                  localize("button_claim_get_points", this.props.language)
                )}
              </button>
            )}
          </fieldset>
          {/*
            Set the below back to </form> if we are
            no longer using <input type="file"> for
            image inputs
          */}
        </div>
      );
    }
  }
}

MultiFieldChallenge.propTypes = propTypes;
MultiFieldChallenge.defaultProps = defaultProps;

export default MultiFieldChallenge;
