import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { CHALLENGE } from "App/Routes";
import ChallengeCard from "./ChallengeCard";

import Router from "router";

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
  };
};

const propTypes = {
  id: PropTypes.number.isRequired,
  unlocked: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  challengeType: PropTypes.string.isRequired,
  challengeTypeId: PropTypes.number.isRequired,
  points: PropTypes.number.isRequired,
  isFlashcard: PropTypes.bool.isRequired,
  isConfirmationChallenge: PropTypes.bool,
  challengeReferralLink: PropTypes.string,
  likeNo: PropTypes.number.isRequired,
  completedNo: PropTypes.number.isRequired,
  isLiked: PropTypes.bool.isRequired,
  isBookmarked: PropTypes.bool.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  isExpired: PropTypes.bool.isRequired,
  handleLike: PropTypes.func.isRequired,
  handleBookmark: PropTypes.func.isRequired,
  handleOpenLoginDialog: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  language: PropTypes.string,
  contactEmail: PropTypes.string,
};

export class ChallengeCardContainer extends Component {
  constructor() {
    super();

    this.handleChallengeTitleClick = this.handleChallengeTitleClick.bind(this);
  }

  handleChallengeTitleClick(event) {
    if (!event.target.classList.contains("secondaryLink")) {
      event.preventDefault();
      Router.navigate(CHALLENGE.format(this.props.id));
    }
  }

  render() {
    return (
      <ChallengeCard
        sessionKey={this.props.sessionKey}
        id={this.props.id}
        unlocked={this.props.unlocked}
        title={this.props.title}
        img={this.props.img}
        challengeType={this.props.challengeType}
        challengeTypeId={this.props.challengeTypeId}
        points={this.props.points}
        isFlashcard={this.props.isFlashcard}
        isConfirmationChallenge={this.props.isConfirmationChallenge}
        challengeReferralLink={this.props.challengeReferralLink}
        likeNo={this.props.likeNo}
        completedNo={this.props.completedNo}
        isLiked={this.props.isLiked}
        isBookmarked={this.props.isBookmarked}
        isCompleted={this.props.isCompleted}
        isExpired={this.props.isExpired}
        handleLike={this.props.handleLike}
        handleBookmark={this.props.handleBookmark}
        handleOpenLoginDialog={this.props.handleOpenLoginDialog}
        isLoggedIn={this.props.isLoggedIn}
        language={this.props.language}
        contactEmail={this.props.contactEmail}
        handleChallengeTitleClick={this.handleChallengeTitleClick}
      />
    );
  }
}

ChallengeCardContainer.propTypes = propTypes;

export default connect(mapStateToProps)(ChallengeCardContainer);
