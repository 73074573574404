import React, { Component } from "react";
import PropTypes from "prop-types";

import { CHALLENGE, TOPIC } from "App/Routes";
import Link from "components/shared/Link/Link";
import NotLoggedInContainer from "components/shared/NotLoggedIn/NotLoggedInContainer";
import StandardChallenge from "./StandardChallenge";
import VideoChallenge from "./VideoChallenge";
import QuizChallenge from "./QuizChallenge";
import FixedChallenge from "./FixedChallenge";
import MultiFieldChallenge from "./MultiFieldChallenge";
import QRChallenge from "./QRChallenge";
import ExternalChallenge from "./ExternalChallenge";

import localize from "lang/localize";
import "./ClaimForm.css";

import urlParse from "library/js/url";

const propTypes = {
  sessionKey: PropTypes.string,
  isLoggedIn: PropTypes.bool.isRequired,
  challengeTypeId: PropTypes.number.isRequired,
  challengeId: PropTypes.number.isRequired,
  title: PropTypes.string,
  isConfirmationChallenge: PropTypes.bool,
  gridWidth: PropTypes.number,
  photoRequired: PropTypes.bool,
  noPhoto: PropTypes.bool,
  quizOptions: PropTypes.array,
  fields: PropTypes.array,
  completionMessage: PropTypes.string,
  multiSelect: PropTypes.bool,
  claimed: PropTypes.bool,
  claimedBefore: PropTypes.bool,
  postClaim: PropTypes.func.isRequired,
  postQRClaim: PropTypes.func.isRequired,
  showAlertWithTimeout: PropTypes.func,
  adminToClaim: PropTypes.bool.isRequired,
  expired: PropTypes.bool.isRequired,
  submitted: PropTypes.bool.isRequired,
  videoDurationLimit: PropTypes.number,
  videoDurationLimitFormatted: PropTypes.string,
  externalEmbeddable: PropTypes.object,
  showQRModal: PropTypes.bool,
  handleOpenQRModal: PropTypes.func,
  handleCloseQRModal: PropTypes.func,
  showExternalChallengeDialog: PropTypes.bool,
  handleOpenExternalChallengeDialog: PropTypes.func,
  handleCloseExternalChallengeDialog: PropTypes.func,
  challengeMinigameTitle: PropTypes.string,
  challengeMinViewDuration: PropTypes.number,
  isTimerActive: PropTypes.bool,
  setTimerActive: PropTypes.func,
  countdownTime: PropTypes.number,
  language: PropTypes.string,
  charLimit: PropTypes.number,
  repeat: PropTypes.bool,
  repeatUntilCorrect: PropTypes.bool,
  repeatAtFormatted: PropTypes.string,
  correctAnswerTitle: PropTypes.string,
  correctAnswer: PropTypes.bool,
  nextChallengeId: PropTypes.number,
  nextTopicId: PropTypes.number,
};

class ClaimForm extends Component {
  addAnchors(text) {
    // add anchor tags to links
    return urlParse(text, true);
  }

  /**
   * Render challenge repeatable message.
   *
   * @param {boolean} repeat - Whether the challenge is repeatable or not
   */
  getRepeatText(repeat) {
    if (this.props.challengeTypeId === 4) {
      return null;
    }

    /* passcode/fixed answer challenge */
    if (this.props.challengeTypeId === 9) {
      return localize(
        "challenge_profile_repeat_once_text",
        this.props.language,
      );
    }

    if (this.props.repeatUntilCorrect) {
      return localize(
        "challenge_profile_repeat_again_text",
        this.props.language,
      );
    } else if (!repeat) {
      return localize(
        "challenge_profile_repeat_once_text",
        this.props.language,
      );
    } else if (this.props.repeatAtFormatted) {
      return localize(
        "challenge_profile_repeat_again_text_with_duration",
        this.props.language,
      ).format(this.props.repeatAtFormatted);
    } else {
      return localize(
        "challenge_profile_repeat_again_text",
        this.props.language,
      );
    }
  }

  /**
   * Render expired
   */
  renderChallengeExpired() {
    if (this.props.expired) {
      return (
        <React.Fragment>
          {/* Render expiry information */}
          <div
            className="pure-g topmargin-20 flex align-items-center"
            id="expired"
          >
            <div className="pure-u-2-24 pure-u-md-1-24 font-size-lg">
              <i className="far fa-clock" />
            </div>
            <div className="pure-u-22-24 pure-u-md-23-24">
              <em>
                {localize(
                  "challenge_profile_expired_text",
                  this.props.language,
                )}
              </em>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }

  /**
   * Render form according to challenge type
   */
  renderFormForChallengeType() {
    if (!this.props.isLoggedIn) {
      // Does not display anything.
      return null;
    }

    switch (this.props.challengeTypeId) {
      case 1: // standard
        return (
          <React.Fragment>
            <div id="claimform">
              <div className="innerblock challenge-claim-form-wrap" id="claim">
                <h5>
                  <i className="fa fa-pencil-alt rightmargin-10" />
                  {localize(
                    "challenge_profile_action_header_generic",
                    this.props.language,
                  )}
                </h5>
                <StandardChallenge
                  handleSubmit={this.props.postClaim}
                  photoRequired={this.props.photoRequired}
                  showAlertWithTimeout={this.props.showAlertWithTimeout}
                  submitted={this.props.submitted}
                  isLoggedIn={this.props.isLoggedIn}
                  challengeMinViewDuration={this.props.challengeMinViewDuration}
                  isTimerActive={this.props.isTimerActive}
                  setTimerActive={this.props.setTimerActive}
                  countdownTime={this.props.countdownTime}
                  language={this.props.language}
                  charLimit={this.props.charLimit}
                />
              </div>
            </div>
          </React.Fragment>
        );
      case 5: // video
        return (
          <React.Fragment>
            <div id="claimform">
              <div className="innerblock challenge-claim-form-wrap" id="claim">
                <h5>
                  <i className="fa fa-pencil-alt rightmargin-10" />
                  {localize(
                    "challenge_profile_action_header_generic",
                    this.props.language,
                  )}
                </h5>
                <VideoChallenge
                  handleSubmit={this.props.postClaim}
                  showAlertWithTimeout={this.props.showAlertWithTimeout}
                  submitted={this.props.submitted}
                  videoDurationLimit={this.props.videoDurationLimit}
                  videoDurationLimitFormatted={
                    this.props.videoDurationLimitFormatted
                  }
                  isLoggedIn={this.props.isLoggedIn}
                  challengeMinViewDuration={this.props.challengeMinViewDuration}
                  isTimerActive={this.props.isTimerActive}
                  setTimerActive={this.props.setTimerActive}
                  countdownTime={this.props.countdownTime}
                  language={this.props.language}
                  charLimit={this.props.charLimit}
                />
              </div>
            </div>
          </React.Fragment>
        );
      case 2: // quiz
      case 3: // prediction
      case 10: // quiz-repeat
      case 11: // survey or confirmation
      case 14: // personality quiz
        return (
          <React.Fragment>
            <div id="claimform">
              <div className="innerblock challenge-claim-form-wrap" id="claim">
                <h5>
                  <i className="fa fa-pencil-alt rightmargin-10" />
                  {localize(
                    "challenge_profile_action_header_fixed_answer",
                    this.props.language,
                  )}
                </h5>
                <QuizChallenge
                  isConfirmationChallenge={this.props.isConfirmationChallenge}
                  options={this.props.quizOptions}
                  handleSubmit={this.props.postClaim}
                  multiSelect={this.props.multiSelect}
                  gridWidth={this.props.gridWidth}
                  showAlertWithTimeout={this.props.showAlertWithTimeout}
                  submitted={this.props.submitted}
                  isLoggedIn={this.props.isLoggedIn}
                  challengeMinViewDuration={this.props.challengeMinViewDuration}
                  isTimerActive={this.props.isTimerActive}
                  setTimerActive={this.props.setTimerActive}
                  countdownTime={this.props.countdownTime}
                  language={this.props.language}
                />
              </div>
            </div>
          </React.Fragment>
        );
      case 9: // fixed answer
        return (
          <React.Fragment>
            <div id="claimform">
              <div className="innerblock challenge-claim-form-wrap" id="claim">
                <h5>
                  <i className="fa fa-pencil-alt rightmargin-10" />
                  {localize(
                    "challenge_profile_action_header_fixed_answer",
                    this.props.language,
                  )}
                </h5>
                <FixedChallenge
                  handleSubmit={this.props.postClaim}
                  showAlertWithTimeout={this.props.showAlertWithTimeout}
                  submitted={this.props.submitted}
                  isLoggedIn={this.props.isLoggedIn}
                  challengeMinViewDuration={this.props.challengeMinViewDuration}
                  isTimerActive={this.props.isTimerActive}
                  setTimerActive={this.props.setTimerActive}
                  countdownTime={this.props.countdownTime}
                  language={this.props.language}
                />
              </div>
            </div>
          </React.Fragment>
        );
      case 13: // multi-field
        return (
          <React.Fragment>
            <div id="claimform">
              <div className="innerblock challenge-claim-form-wrap" id="claim">
                <h5>
                  <i className="fa fa-pencil-alt rightmargin-10" />
                  {localize(
                    "challenge_profile_action_header_generic",
                    this.props.language,
                  )}
                </h5>
                <MultiFieldChallenge
                  fields={this.props.fields}
                  handleSubmit={this.props.postClaim}
                  photoRequired={this.props.photoRequired}
                  noPhoto={this.props.noPhoto}
                  showAlertWithTimeout={this.props.showAlertWithTimeout}
                  submitted={this.props.submitted}
                  isLoggedIn={this.props.isLoggedIn}
                  challengeMinViewDuration={this.props.challengeMinViewDuration}
                  isTimerActive={this.props.isTimerActive}
                  setTimerActive={this.props.setTimerActive}
                  countdownTime={this.props.countdownTime}
                  language={this.props.language}
                  charLimit={this.props.charLimit}
                />
              </div>
            </div>
          </React.Fragment>
        );
      case 7: // qr code
        return (
          <React.Fragment>
            <div id="claimform">
              <div className="innerblock challenge-claim-form-wrap" id="claim">
                <QRChallenge
                  handleSubmit={this.props.postQRClaim}
                  isLoggedIn={this.props.isLoggedIn}
                  showQRModal={this.props.showQRModal}
                  handleOpenQRModal={this.props.handleOpenQRModal}
                  handleCloseQRModal={this.props.handleCloseQRModal}
                  language={this.props.language}
                />
              </div>
            </div>
          </React.Fragment>
        );
      case 15: // external
        return (
          <React.Fragment>
            <div id="claimform">
              <div className="innerblock" id="claim">
                <button
                  className={
                    "button automargin pure-u-1-1 pure-u-md-8-24 " +
                    (this.props.claimed || this.props.expired
                      ? "inactive"
                      : "cta")
                  }
                  onClick={this.props.handleOpenExternalChallengeDialog}
                >
                  {this.props.claimed
                    ? localize("button_minigame_completed", this.props.language)
                    : this.props.expired
                      ? localize(
                          "button_challenge_challenge_expired",
                          this.props.language,
                        )
                      : !this.props.claimed && this.props.claimedBefore
                        ? localize(
                            "button_minigame_play_again",
                            this.props.language,
                          )
                        : localize("button_minigame_play", this.props.language)}
                </button>
              </div>
            </div>
            {this.props.showExternalChallengeDialog && (
              <ExternalChallenge
                sessionKey={this.props.sessionKey}
                language={this.props.language}
                challengeId={this.props.challengeId}
                challengeMinigameTitle={this.props.challengeMinigameTitle}
                title={this.props.title}
                externalEmbeddable={this.props.externalEmbeddable}
                showDialog={this.props.showExternalChallengeDialog}
                handleOpenDialog={this.props.handleOpenExternalChallengeDialog}
                handleCloseDialog={
                  this.props.handleCloseExternalChallengeDialog
                }
              />
            )}
          </React.Fragment>
        );
      case 4: // Flashcard
        return (
          <React.Fragment>
            <hr />
          </React.Fragment>
        );
      default:
        return null;
    }
  }

  /**
   * Render next challenge/topic button
   * @returns
   */
  renderNextContentLink() {
    let render = null;

    // Prioritize next challenge first.
    // If no next challenge, render next topic.
    if (this.props.nextChallengeId || this.props.nextTopicId) {
      if (
        this.props.nextChallengeId &&
        (this.props.claimedBefore || this.props.challengeTypeId === 4)
      ) {
        render = (
          <Link
            className="button automargin pure-u-1-1 pure-u-md-6-24 cta"
            to={CHALLENGE.format(this.props.nextChallengeId)}
          >
            {localize(
              "button_feedback_view_next_challenge",
              this.props.language,
            )}{" "}
            &raquo;
          </Link>
        );
      } else if (this.props.nextTopicId) {
        render = (
          <div className="challenge-topic-nav topmargin-20 padding">
            <div className="textcenter">
              {localize(
                "challenge_profile_topic_end_text",
                this.props.language,
              )}
              <div className="topmargin-10">
                <Link
                  className="button automargin pure-u-1-1 pure-u-md-6-24 cta"
                  to={TOPIC.format(this.props.nextTopicId)}
                >
                  {localize("button_next_topic", this.props.language)} &raquo;
                </Link>
              </div>
            </div>
          </div>
        );
      }
    }

    return render;
  }

  /**
   * Render post-challenge completion message if any.
   *
   * @param {String} completion_message - Post-completion message
   * @returns
   */
  renderCompletionMessage(completion_message) {
    let completionMessageAnchored = this.addAnchors(completion_message);

    return (
      <div dangerouslySetInnerHTML={{ __html: completionMessageAnchored }} />
    );
  }

  /**
   * Render prompt for non-logged in users
   */
  renderNotLoggedIn() {
    if (!this.props.isLoggedIn && this.props.challengeTypeId !== 4) {
      return (
        <React.Fragment>
          <div id="claimform">
            <div
              className="innerblock challenge-claim-form-wrap"
              id="notLoggedIn"
            >
              <NotLoggedInContainer
                showLogin={false}
                message={localize(
                  "require_login_complete_challenge",
                  this.props.language,
                )}
                language={this.props.language}
              />
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  /**
   * Render info for users who have completed this challenge before.
   */
  renderClaimedBefore() {
    if (this.props.claimedBefore) {
      return (
        <React.Fragment>
          <div className="topmargin-20">
            <div
              className="innerblock challenge-claim-form-wrap responsive-img-description"
              id="completed"
            >
              <h5>{localize("feedback_default_text", this.props.language)}</h5>
              {/* Prediction challenge outcome */}
              {this.props.challengeTypeId === 3 && (
                <div className="pure-g topmargin-20">
                  <div className="pure-u-3-24 pure-u-md-2-24 textcenter font-size-lg">
                    <i className="fas fa-question-circle" />
                  </div>
                  <div className="pure-u-21-24 pure-u-md-22-24">
                    {localize("feedback_prediction_text", this.props.language)}
                  </div>
                </div>
              )}
              {/* Quiz challenge outcome */}
              {(this.props.challengeTypeId === 2 ||
                this.props.challengeTypeId === 10) && (
                <div className="pure-g topmargin-20">
                  <div className="pure-u-3-24 pure-u-md-2-24 textcenter font-size-lg">
                    {this.props.correctAnswer ? (
                      <i className="fas fa-check-circle correct" />
                    ) : (
                      <i className="fas fa-times-circle wrong" />
                    )}
                  </div>
                  <div className="pure-u-21-24 pure-u-md-22-24">
                    {(this.props.claimed || this.props.claimedBefore) &&
                    this.props.correctAnswerTitle
                      ? this.props.correctAnswer
                        ? localize(
                            "challenge_profile_quiz_correct",
                            this.props.language,
                          ).format(this.props.correctAnswerTitle)
                        : localize(
                            "challenge_profile_quiz_incorrect",
                            this.props.language,
                          ).format(this.props.correctAnswerTitle)
                      : localize(
                          "challenge_profile_quiz_incorrect_without_answer",
                          this.props.language,
                        )}
                  </div>
                </div>
              )}
              {/* Render completion message if it exists */}
              {this.props.completionMessage && (
                <div className="pure-g topmargin-20">
                  <div className="pure-u-3-24 pure-u-md-2-24 textcenter font-size-lg">
                    <i className="fas fa-info-circle" />
                  </div>
                  <div className="pure-u-21-24 pure-u-md-22-24">
                    {this.renderCompletionMessage(this.props.completionMessage)}
                  </div>
                </div>
              )}
              {/* Render repeat information (except for prediction challenges) */}
              {this.props.challengeTypeId !== 3 && (
                <div className="pure-g topmargin-20">
                  <div className="pure-u-3-24 pure-u-md-2-24 textcenter font-size-lg">
                    <i className="fas fa-pen" />
                  </div>
                  <div className="pure-u-21-24 pure-u-md-22-24">
                    {this.getRepeatText(this.props.repeat)}
                  </div>
                </div>
              )}
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  /**
   * Render info for challenges that can only be completed by admin
   */
  renderAdminClaim() {
    if (this.props.adminToClaim && !this.props.claimed) {
      return (
        <React.Fragment>
          <div
            className="innerblock challenge-claim-form-wrap"
            id="adminCompletion"
          >
            {localize("only_admin_can_claim_text", this.props.language)}
          </div>
        </React.Fragment>
      );
    }
  }

  /**
   * Render the component
   */
  render() {
    return (
      <div className="topmargin-20">
        {((!this.props.claimed &&
          this.props.isLoggedIn &&
          !this.props.expired &&
          !this.props.adminToClaim) ||
          this.props.challengeTypeId === 15) &&
          this.renderFormForChallengeType()}
        {this.renderChallengeExpired()}
        {this.renderNotLoggedIn()}
        {this.renderClaimedBefore()}
        {this.renderAdminClaim()}

        <div className="topmargin-20">{this.renderNextContentLink()}</div>
      </div>
    );
  }
}

ClaimForm.propTypes = propTypes;

export default ClaimForm;
