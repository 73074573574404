import React, { Component } from "react";
import PropTypes from "prop-types";

import "./CollectRedeemedDialog.css";

import Modal from "react-modal";
import Link from "components/shared/Link/Link";

import localize from "lang/localize";

const propTypes = {
  showCollectRedeemedDialog: PropTypes.bool.isRequired,
  collectionCancelEnabled: PropTypes.bool,
  collectionEnabled: PropTypes.bool,
  itemTitle: PropTypes.string,
  itemId: PropTypes.number.isRequired,
  itemImg: PropTypes.string.isRequired,
  itemDescription: PropTypes.string,
  itemDescriptionAfterRedeem: PropTypes.string,
  itemDescriptionAfterCollect: PropTypes.string,
  itemOption: PropTypes.string,
  itemRedeemedAtFormatted: PropTypes.string,
  itemUsedAtFormatted: PropTypes.string,
  itemCode: PropTypes.string,
  itemType: PropTypes.string.isRequired,
  showAlertWithTimeout: PropTypes.func.isRequired,
  handleCloseCollectRedeemedDialog: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleOpenConfirmCancelRedeemDialog: PropTypes.func.isRequired,
  language: PropTypes.string,
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

/**
 * Confirmation Popup Dialog for Collect Reward
 */
class CollectRedeemedDialog extends Component {
  /**
   * Constructor
   */
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showCollectRedeemedDialog,
      showDialogType: 1, // 0: no dialog, 1: collection dialog, 2: post-collection dialog
    };
  }

  /**
   * Informs React if this component’s output is not affected by the current change in state or props
   * @param {*} nextProps
   * @param {*} nextState
   * @returns
   */
  shouldComponentUpdate(nextProps, nextState) {
    // Determine if popup with post-collection message should show
    // Note: itemDescriptionAfterCollect is only available after collection, and loaded asynchronously
    if (
      this.props.itemDescriptionAfterCollect !==
      nextProps.itemDescriptionAfterCollect
    ) {
      if (nextProps.itemDescriptionAfterCollect) {
        this.setState({
          showDialogType: 2,
        });
      }
    }

    // showModal value should also update if showDialogType changes
    if (this.state.showDialogType !== nextState.showDialogType) {
      this.setState({ showModal: nextState.showDialogType > 0 });
    }
    return true;
  }

  /**
   * Show post-Reward collection dialog.
   */
  toggleShowPostCollectionDialog() {
    this.setState({ showDialogType: this.props.itemType === "code" ? 2 : 0 });
  }

  /**
   * Enable copying of voucher code to clipboard.
   */
  async copyToClipboard(text) {
    try {
      const input = document.createElement("input");
      document.body.appendChild(input);
      input.value = text;
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      this.props.showAlertWithTimeout({
        text: localize("clipboard_alert_success", this.props.language),
        type: "success",
      });
    } catch (err) {
      this.props.showAlertWithTimeout({
        text: localize("clipboard_alert_failure", this.props.language).format(
          err,
        ),
        type: "error",
      });
    }
  }

  /**
   * Renders Rewards collection dialog box.
   */
  renderCollectionDialog() {
    return (
      <div className="dialog-content">
        <div className="dialog-header">
          <h5
            className="dialog-title"
            dangerouslySetInnerHTML={{ __html: this.props.itemTitle }}
          />
          <button
            onClick={this.handleCloseCollectionDialog}
            className="close-button-container"
          >
            <i className="fa close-button" aria-hidden="true" />
          </button>
        </div>
        <div className="dialog-body">
          <img className="imgresponsive" src={this.props.itemImg} alt="" />

          <div className="reward-info-container">
            {this.props.itemDescription && (
              <div
                className="reward-description bottommargin-20"
                dangerouslySetInnerHTML={{
                  __html: this.props.itemDescription,
                }}
              />
            )}

            {this.props.itemRedeemedAtFormatted && (
              <div className="reward-redeem-info">
                <div className="info-text">
                  <i className="fas fa-history"></i>
                  {localize("item_redeemed_at_text", this.props.language)}:{" "}
                  {this.props.itemRedeemedAtFormatted}
                </div>
                {this.props.itemDescriptionAfterRedeem && (
                  <div
                    className="reward-post-description"
                    dangerouslySetInnerHTML={{
                      __html: this.props.itemDescriptionAfterRedeem,
                    }}
                  />
                )}
              </div>
            )}

            {this.props.itemUsedAtFormatted && (
              <div className="reward-redeem-info">
                <div className="info-text">
                  <i className="fas fa-history"></i>
                  {localize("item_used_at_text", this.props.language)}:{" "}
                  {this.props.itemUsedAtFormatted}
                </div>
                {!this.props.collectionEnabled && (
                  <div className="info-text">
                    <em>
                      {localize("item_auto_collected", this.props.language)}
                    </em>
                  </div>
                )}
                {this.props.itemDescriptionAfterCollect && (
                  <div
                    className="reward-post-description"
                    dangerouslySetInnerHTML={{
                      __html: this.props.itemDescriptionAfterCollect,
                    }}
                  />
                )}
              </div>
            )}

            {this.props.itemOption && (
              <p className="topmargin-20">
                {localize("reward_selected", this.props.language).format(
                  this.props.itemOption,
                )}{" "}
              </p>
            )}
            {this.props.itemCode && (
              <div className="collect-code">
                <p>
                  {" "}
                  {localize("reward_code", this.props.language).format(
                    this.props.itemCode,
                  )}{" "}
                </p>
                <i
                  onClick={() => this.copyToClipboard(this.props.itemCode)}
                  className="fas fa-clipboard-list"
                ></i>
              </div>
            )}
            {this.props.itemType === "code" &&
              !this.props.itemUsedAtFormatted && (
                <div className="info-text">
                  <i className="fas fa-info-circle"></i>
                  <p>{localize("use_item_code_text", this.props.language)}</p>
                </div>
              )}
            {!this.props.itemUsedAtFormatted && (
              <div className="info-text">
                <i className="fas fa-info-circle"></i>
                <p>
                  {localize(
                    "use_item_message_text",
                    this.props.language,
                  ).format(this.props.itemTitle)}
                </p>
              </div>
            )}
          </div>
        </div>
        {(!this.props.itemUsedAtFormatted ||
          this.props.collectionCancelEnabled) && (
          /* Render options if reward is not yet collected */
          <div
            className={
              "textcenter" +
              (!this.props.itemUsedAtFormatted ? " dialog-footer-button" : "")
            }
          >
            <div className="reward-action">
              {!this.props.itemUsedAtFormatted && (
                <button
                  className="button inline cta fullwidth"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.handleClick(e, this.props.itemId);
                    this.toggleShowPostCollectionDialog();
                  }}
                >
                  {localize("button_reward_collect", this.props.language)}
                </button>
              )}

              <Link
                className="link link-color display-block topmargin-10"
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.handleOpenConfirmCancelRedeemDialog();
                  this.handleCloseCollectionDialog();
                }}
              >
                <span>
                  {localize("cancel_reward_redeem", this.props.language)}
                </span>
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  }

  /**
   * Renders post-reward collection dialog box.
   * This appears only when there is post-collection messages or
   * instructions for the user.
   */
  renderPostCollectionDialog() {
    return (
      <div className="dialog-content">
        <div className="dialog-header">
          <h5 className="dialog-title">
            {localize("item_used_at_text", this.props.language)}!
          </h5>
          <button
            onClick={this.handleCloseCollectionDialog}
            className="close-button-container"
          >
            <i className="fa close-button" aria-hidden="true" />
          </button>
        </div>
        <div className="dialog-body">
          <img className="imgresponsive" src={this.props.itemImg} alt="" />
          {this.props.itemDescriptionAfterCollect && (
            <div
              className="bottommargin-10"
              dangerouslySetInnerHTML={{
                __html: this.props.itemDescriptionAfterCollect,
              }}
            />
          )}
          {this.props.itemCode && (
            <div className="collect-code">
              <p>
                {" "}
                {localize("reward_code", this.props.language).format(
                  this.props.itemCode,
                )}{" "}
              </p>
              <i
                onClick={() => this.copyToClipboard(this.props.itemCode)}
                className="fas fa-clipboard-list"
              ></i>
            </div>
          )}
        </div>
      </div>
    );
  }

  /**
   * Render dialog type
   */
  renderCollectionDialogType() {
    switch (this.state.showDialogType) {
      case 1:
        return this.renderCollectionDialog();
      case 2:
        return this.renderPostCollectionDialog();
      default:
        return null;
    }
  }

  /**
   * Handle close dialog
   */
  handleCloseCollectionDialog = () => {
    this.props.handleCloseCollectRedeemedDialog();
    this.setState({ showDialogType: 0 });
  };

  /**
   * Renders the modal with dialog box.
   */
  render() {
    return (
      <Modal
        isOpen={this.state.showModal}
        contentLabel={localize(
          "nav_bar_title_inventory_item_profile_text",
          this.props.language,
        )}
        onRequestClose={this.handleCloseCollectionDialog}
        shouldCloseOnOverlayClick={false}
        className="dialog collect-redeemed-dialog"
        overlayClassName="dialog-overlay"
      >
        {this.renderCollectionDialogType()}
      </Modal>
    );
  }
}

CollectRedeemedDialog.propTypes = propTypes;

export default CollectRedeemedDialog;
