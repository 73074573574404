import React, { Component } from "react";
import { connect } from "react-redux";

import {
  PROJECT_LOGIN,
  LOGIN,
  PROJECT_LOGIN_HOME,
  LOGIN_HOME,
  TOPIC,
  PROJECT_HOME,
} from "App/Routes";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";
import Loading from "components/shared/Loading";

import { SINGLE_PROJECT_APP, ENABLE_LOGIN_HOME, ENABLE_TOUR } from "config";
import { setSessionKey, setUserId } from "actions";
import sessionStorageService from "services/sessionStorageService";
import localStorageService from "services/localStorageService";
import loginServices from "services/loginServices";
import urlServices from "services/urlServices";
import localize from "lang/localize";

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    projectId: state.projectId,
    userId: state.user ? state.user.id : null,
    language: state.language,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setSessionKey: (sessionKey) => {
      dispatch(setSessionKey(sessionKey));
    },
    setUserId: (user) => {
      dispatch(setUserId(user));
    },
  };
};

export class FrameLoginSuccessContainer extends Component {
  constructor() {
    super();
    this.state = {
      sessionKey: null,
      userId: null,
    };
  }

  componentDidMount() {
    /*
      Route out if there is already an existing sessionKey and userId
    */
    if (this.props.sessionKey && this.props.userId) {
      // TODO: Validate session
      /*
        FrameLoginSuccess, unlike PostLoginIntercept (/postloginintercept or PostLoginInterceptContainer.js),
        refers to the page housed in the IFRAME. Thus, it is likely a child page.

        However, this component can also be re-used as a parent page itself, eg. not actually being housed
        in any IFRAME. Thus, we are still include re-routes here should this component be used as a parent
        page.

        If the page is framed, the re-routing will likely happen in PostLoginIntercept (much faster)
        than FrameLoginSuccess. This means that from the visitor's perspective, it will seem like
        the parent window does the re-routing. From the developer's perspective, PostLoginIntercept
        will receive the props from the Redux store and re-route, ignoring whatever happens in its IFRAME.

        Thus, the re-routing logic below is only a fallback for cases where FrameLoginSuccess is its
        own parent page.
      */

      let loginRedirectUrl = sessionStorageService.getItem("loginRedirectUrl");

      loginServices.postLoginReplaceAndNavigate(
        this.props.projectId,
        loginRedirectUrl,
        ENABLE_TOUR, // assume tour screen is always needed since this is likely a login attempt
      );
    } else {
      this.retrieveSessionKeyAndRedirect();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.sessionKey !== this.state.sessionKey ||
      prevState.userId !== this.state.userId
    ) {
      if (
        typeof this.state.sessionKey === "string" &&
        typeof this.state.userId === "number"
      ) {
        this.handleLogin();
      }
    }
  }

  /*
    No need for redirects here, because it's handled by
    the parent LoginContainer's componentDidUpdate
  */
  handleLogin() {
    localStorageService.saveState({
      user_id: this.state.userId,
      sessionKey: this.state.sessionKey,
    });
    this.props.setUserId(this.state.userId);
    this.props.setSessionKey(this.state.sessionKey);
  }

  retrieveSessionKeyAndRedirect() {
    const sessionKey = urlServices.getUrlParamValueByKey("sessionKey");
    const entryProjectId = urlServices.getUrlParamValueByKey("entryProjectId");
    const projectId = urlServices.getUrlParamValueByKey("projectId");
    const entryTopicId = urlServices.getUrlParamValueByKey("entryTopicId");

    if (sessionKey) {
      this.setState(
        {
          sessionKey: sessionKey,
        },
        () => {
          let userIdString = this.state.sessionKey.split("-")[0];
          this.setState({
            userId: isNaN(userIdString) ? false : parseInt(userIdString, 10),
          });

          if (entryTopicId) {
            sessionStorageService.setItem(
              "loginRedirectUrl",
              TOPIC.format(entryTopicId),
            );
          } else if (!SINGLE_PROJECT_APP && (entryProjectId || projectId)) {
            sessionStorageService.setItem(
              "loginRedirectUrl",
              PROJECT_HOME.format(entryProjectId ? entryProjectId : projectId),
            );
          }
        },
      );
    } else {
      this.setState({
        sessionKey: false,
        userId: false,
      });
    }
  }

  getLoginRoute() {
    if (this.props.projectId && ENABLE_LOGIN_HOME) {
      return PROJECT_LOGIN_HOME.format(this.props.projectId);
    } else if (this.props.projectId) {
      return PROJECT_LOGIN.format(this.props.projectId);
    } else if (ENABLE_LOGIN_HOME) {
      return LOGIN_HOME;
    } else {
      return LOGIN;
    }
  }

  render() {
    /*
      When sessionKey and userId are not yet set, they will be null.
      When sessionKey and userId are WRONGFULLY set, they will be false.
    */
    if (this.state.sessionKey === false && this.state.userId === false) {
      return (
        <GenericErrorPage
          routeUrl={this.getLoginRoute()}
          routeName={localize("nav_bar_title_login_text", this.props.language)}
          message={localize("login_failed_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FrameLoginSuccessContainer);
