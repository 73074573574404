function scrollToItem(item, padding) {
  let topbarHeight = 0;

  if (document.getElementsByClassName("topbar").length > 0) {
    topbarHeight = document.getElementsByClassName("topbar")[0].offsetHeight;
  }

  let yPos = item.offsetTop - topbarHeight - (padding ? parseInt(padding) : 10);

  window.scrollTo({
    top: yPos > 0 ? yPos : 0,
    left: 0,
    behavior: "smooth",
  });
}

function scrollToId(id, padding, delay) {
  let dest = document.getElementById(id);
  if (dest) {
    if (delay && parseInt(delay) > 0) {
      setTimeout(() => {
        scrollToItem(dest, padding);
      }, delay);
    } else {
      scrollToItem(dest, padding);
    }
  }
}

export default scrollToId;
