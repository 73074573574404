import React, { Component } from "react";
import { connect } from "react-redux";

import Loading from "components/shared/Loading";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";
import FramePage from "./FramePage";

import { LOAD_MORE_COUNT } from "config";
import { setProject, setButtons } from "actions";
import getApiGenerator from "services/getApiGenerator";
import { GET_TOPICS } from "services/api";
import localize from "lang/localize";

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    projectId: state.projectId,
    language: state.language,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setProject: (project) => {
      dispatch(setProject(project));
    },
    setButtons: (buttons) => {
      dispatch(setButtons(buttons));
    },
  };
};

// For login using callback mechanism
export class FrameContainer extends Component {
  constructor() {
    super();
    this.state = {
      apiCalled: false,
    };
  }

  componentDidMount() {
    // TODO: Use project endpoint in API2 for more lightweight API call
    this.getTopics();
  }

  getTopics(page = 1, category_id = "", limit = LOAD_MORE_COUNT) {
    getApiGenerator(
      GET_TOPICS.format(this.props.projectId),
      {
        page: page,
        category_id: category_id,
        limit: limit,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      this.setState(
        {
          apiCalled: true,
        },
        () => {
          if (!(err || res.body.code !== 200)) {
            const categories = res.body.categories
              ? res.body.categories.map((category) => ({
                  id: category.id,
                  title: category.name,
                  selected: category.selected,
                  description: category.description,
                  image: category.image,
                }))
              : null;

            const BUTTONS =
              res.body.buttons && res.body.buttons.length > 0
                ? res.body.buttons
                : null;

            let compiledProject = categories
              ? Object.assign({}, res.body.game, { categories: categories })
              : res.body.game;

            this.props.setProject(compiledProject);
            this.props.setButtons(BUTTONS);
          }
        },
      );
    });
  }

  render() {
    if (
      !!this.state.apiCalled &&
      typeof this.props.url === "string" &&
      this.props.url.length > 0
    ) {
      const DECODED_URL = decodeURIComponent(this.props.url);
      return <FramePage sessionKey={this.props.sessionKey} url={DECODED_URL} />;
    } else if (
      typeof this.props.url !== "string" ||
      this.props.url.length < 1
    ) {
      return (
        <GenericErrorPage
          message={localize("empty_challenge_table_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FrameContainer);
