import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import BoardFilterDialog from "./BoardFilterDialog";

const propTypes = {
  showBoardFilterDialog: PropTypes.bool.isRequired,
  handleCloseBoardFilterDialog: PropTypes.func.isRequired,
  handleSearchInput: PropTypes.func.isRequired,
  handleBoardFilters: PropTypes.func.isRequired,
  handleFilterReset: PropTypes.func.isRequired,
  defaultBoard: PropTypes.object, // initial board
  currentBoard: PropTypes.object, // current board (filtered/initial)
  selectedOption: PropTypes.object, // selected option (board)
  search: PropTypes.string,
  sort: PropTypes.string,
  language: PropTypes.string,
  projectId: PropTypes.string,
  boards: PropTypes.array,
};

export const mapStateToProps = (state, ownProps) => {
  return {
    language: state.language,
  };
};

export class BoardFilterDialogContainer extends Component {
  render() {
    return (
      <BoardFilterDialog
        showBoardFilterDialog={this.props.showBoardFilterDialog}
        handleCloseBoardFilterDialog={this.props.handleCloseBoardFilterDialog}
        handleSearchInput={this.props.handleSearchInput}
        handleSortToggle={this.props.handleSortToggle}
        handleBoardChange={this.props.handleBoardChange}
        handleBoardFilters={this.props.handleBoardFilters}
        handleFilterReset={this.props.handleFilterReset}
        projectId={this.props.projectId}
        boards={this.props.boards}
        currentBoard={this.props.currentBoard}
        defaultBoard={this.props.defaultBoard}
        selectedOption={
          !!this.props.selectedOption ? this.props.selectedOption : null
        }
        search={this.props.search}
        sort={this.props.sort}
        language={this.props.language}
      />
    );
  }
}

BoardFilterDialogContainer.propTypes = propTypes;

export default connect(mapStateToProps)(BoardFilterDialogContainer);
