import React, { Component } from "react";
import PropTypes from "prop-types";

import { MINI_GAME_LINK } from "config";
import MiniGameDialog from "../../shared/Dialogs/MiniGameDialog/MiniGameDialog";

const propTypes = {
  sessionKey: PropTypes.string,
  challengeId: PropTypes.number.isRequired,
  challengeMinigameTitle: PropTypes.string,
  language: PropTypes.string,
  title: PropTypes.string,
  showDialog: PropTypes.bool,
  handleOpenDialog: PropTypes.func,
  handleCloseDialog: PropTypes.func,
};

/**
 * Renders the External Challenge component
 * Currently, this will open a modal pop-up with am embedded web view
 */
class ExternalChallenge extends Component {
  render() {
    return (
      <MiniGameDialog
        challengeMinigameTitle={this.props.challengeMinigameTitle}
        title={this.props.title}
        hiddenFields={{
          id: this.props.challengeId,
          session_key: this.props.sessionKey,
          lang: this.props.language,
        }}
        url={MINI_GAME_LINK}
        showDialog={this.props.showDialog}
        handleOpenDialog={this.props.handleOpenDialog}
        handleCloseDialog={this.props.handleCloseDialog}
      />
    );
  }
}

ExternalChallenge.propTypes = propTypes;

export default ExternalChallenge;
