import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  TOPIC_CATEGORY,
  PROJECT_HOME,
  TOPIC,
  CHALLENGE_CATEGORY,
} from "App/Routes";
import SelectCategoryDialog from "./SelectCategoryDialog";

import Router from "router";

const propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleCloseSelectCategoryDialog: PropTypes.func,
  type: PropTypes.oneOf(["topic", "challenge"]).isRequired,
};

const defaultProps = {
  showModal: false,
};

export const mapStateToProps = (state, ownProps) => {
  return {
    projectId:
      state.topbar && state.topbar.project ? state.topbar.project.id : null,
    topicId: state.topbar && state.topbar.topic ? state.topbar.topic.id : null,
    topicCategories: state.topbar.project.categories,
    challengeCategories: state.topbar.topic.categories,
    topicCategoryId: state.topicCategoryId,
    challengeCategoryId: state.challengeCategoryId,
  };
};

class SelectCategoryDialogContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory:
        (props.type === "topic"
          ? props.topicCategoryId
          : props.challengeCategoryId) || "",
    };

    this.handleCategory = this.handleCategory.bind(this);
  }

  handleCategory(name, option) {
    this.setState({ selectedCategory: option.value });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.props.type === "topic" && !this.state.selectedCategory) {
      // All topic categories in project
      Router.navigate(PROJECT_HOME.format(this.props.projectId));
    } else if (this.props.type === "topic") {
      Router.navigate(
        TOPIC_CATEGORY.format(
          this.props.projectId,
          this.state.selectedCategory,
        ),
      );
    } else if (!this.state.selectedCategory) {
      // All challenge categories in topic
      Router.navigate(TOPIC.format(this.props.topicId));
    } else {
      Router.navigate(
        CHALLENGE_CATEGORY.format(
          this.props.topicId,
          this.state.selectedCategory,
        ),
      );
    }
  };

  setCategories(type) {
    if (type === "topic") {
      return this.props.topicCategories;
    } else {
      return this.props.challengeCategories;
    }
  }

  render() {
    return (
      <SelectCategoryDialog
        showModal={this.props.showModal}
        handleCloseSelectCategoryDialog={
          this.props.handleCloseSelectCategoryDialog
        }
        categories={this.setCategories(this.props.type)}
        categoryId={this.state.selectedCategory}
        onCategoryChange={this.handleCategory}
        handleSubmit={this.handleSubmit}
        language={this.props.language}
      />
    );
  }
}

SelectCategoryDialogContainer.propTypes = propTypes;
SelectCategoryDialogContainer.defaultProps = defaultProps;

export default connect(mapStateToProps)(SelectCategoryDialogContainer);
