import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import NotLoggedInContainer from "components/shared/NotLoggedIn/NotLoggedInContainer";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";
import TeamInvitePage from "./TeamInvitePage";

import { GET_TOPICS } from "services/api";
import { setProject, setButtons } from "actions";
import getApiGenerator from "services/getApiGenerator";

import localize from "lang/localize";

const propTypes = {
  id: PropTypes.string.isRequired,
};

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    projectId: state.projectId,
    language: state.language,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setProject: (project) => {
      dispatch(setProject(project));
    },
    setButtons: (buttons) => {
      dispatch(setButtons(buttons));
    },
  };
};

export class TeamInviteContainer extends Component {
  constructor() {
    super();
    this.state = {
      topics: null,
      error: null,
      code: null,
    };
  }

  componentDidMount() {
    /* Retrieve Project information */
    if (this.props.projectId) {
      this.getTopics();
    }
  }

  getTopics() {
    getApiGenerator(
      GET_TOPICS.format(this.props.projectId),
      {
        page: 1,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            topics: [],
            code: 500,
            error: res.body.error,
          });
        }
      } else {
        this.setState({
          topics: res.body.data,
          code: res.body.code,
          error: "",
        });

        const BUTTONS =
          res.body.buttons && res.body.buttons.length > 0
            ? res.body.buttons
            : null;

        this.props.setProject(res.body.game);
        this.props.setButtons(BUTTONS);
      }
    });
  }

  render() {
    if (!this.props.sessionKey) {
      return (
        <NotLoggedInContainer
          showLogin={false}
          message={localize("require_login_view_page", this.props.language)}
          language={this.props.language}
          fullHeight={true}
        />
      );
    } else if (
      /* Project ID is available, but Project does not exist */
      this.props.projectId &&
      Array.isArray(this.state.topics) &&
      (this.state.error.indexOf("not exist") !== -1 ||
        this.state.error.indexOf("not published") !== -1)
    ) {
      return (
        <GenericErrorPage
          message={localize("bundle_not_found_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else {
      return (
        <TeamInvitePage
          sessionKey={this.props.sessionKey}
          teamId={this.props.id}
          language={this.props.language}
        />
      );
    }
  }
}

TeamInviteContainer.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeamInviteContainer);
