import React, { Component } from "react";
import { connect } from "react-redux";

import Sidebar from "./Sidebar";

export const mapStateToProps = (state, ownProps) => {
  return {
    sidebar: state.sidebar,
    sessionKey: state.sessionKey,
    language: state.language,
  };
};

export class SidebarContainer extends Component {
  constructor() {
    super();
    this.state = {
      showShareGameDialog: false,
      showTopicResetActivityDialog: false,
    };

    this.handleOpenShareGameDialog = this.handleOpenShareGameDialog.bind(this);
    this.handleCloseShareGameDialog =
      this.handleCloseShareGameDialog.bind(this);

    this.handleOpenResetTopicActivityDialog =
      this.handleOpenResetTopicActivityDialog.bind(this);
    this.handleCloseResetTopicActivityDialog =
      this.handleCloseResetTopicActivityDialog.bind(this);
  }

  /**
   * Handle event when user opens the project share popup dialog
   *
   * @param {object} event - Event
   */
  handleOpenShareGameDialog(event) {
    event.preventDefault();

    this.setState({
      showShareGameDialog: true,
    });
  }

  /**
   * Handle event when user closes the project share popup dialog
   */
  handleCloseShareGameDialog() {
    this.setState({
      showShareGameDialog: false,
    });
  }

  handleOpenResetTopicActivityDialog() {
    this.setState({
      showTopicResetActivityDialog: true,
    });
  }

  handleCloseResetTopicActivityDialog() {
    this.setState({
      showTopicResetActivityDialog: false,
    });
  }

  /**
   * Render view
   */
  render() {
    const sidebar = this.props.sidebar;
    const SHOW_BOARD_SIDEBAR =
      sidebar.state &&
      sidebar.state === "board" &&
      sidebar.board &&
      typeof sidebar.board === "object" &&
      Object.keys(sidebar.board).length > 0 &&
      sidebar.project &&
      typeof sidebar.project === "object" &&
      Object.keys(sidebar.project).length > 0;

    const SHOW_TOPIC_SIDEBAR =
      sidebar.state &&
      sidebar.state === "topic" &&
      typeof sidebar.topic === "object" &&
      Object.keys(sidebar.topic).length > 0 &&
      sidebar.project &&
      typeof sidebar.project === "object" &&
      Object.keys(sidebar.project).length > 0;

    const SHOW_PROJECT_SIDEBAR =
      sidebar.state &&
      (sidebar.state === "project" || sidebar.state === "project-secondary") &&
      sidebar.project &&
      typeof sidebar.project === "object" &&
      Object.keys(sidebar.project).length > 0;

    const IS_PROJECT_PAGE = sidebar.state === "project";

    if (SHOW_BOARD_SIDEBAR || SHOW_TOPIC_SIDEBAR || SHOW_PROJECT_SIDEBAR) {
      return (
        <Sidebar
          state={sidebar.state}
          sessionKey={this.props.sessionKey ? this.props.sessionKey : null}
          projectId={sidebar.project.id}
          projectName={sidebar.project.title}
          projectDescription={sidebar.project.description}
          projectImage={sidebar.project.bannerImage}
          project={sidebar.project}
          showTeams={sidebar.project.teamEnabled}
          topicId={sidebar.topic ? sidebar.topic.id : null}
          topicName={sidebar.topic ? sidebar.topic.title : null}
          topicDescription={sidebar.topic ? sidebar.topic.description : null}
          topicImage={sidebar.topic ? sidebar.topic.bannerImage : null}
          board={sidebar.board ? sidebar.board : null}
          totalChallenges={sidebar.topic ? sidebar.topic.challengeNo : null}
          completedChallenges={
            sidebar.topic ? sidebar.topic.userChallengeCompletedNo : null
          }
          isProjectPage={IS_PROJECT_PAGE}
          showShareGameDialog={this.state.showShareGameDialog}
          handleOpenShareGameDialog={this.handleOpenShareGameDialog}
          handleCloseShareGameDialog={this.handleCloseShareGameDialog}
          allowReset={sidebar.topic ? sidebar.topic.allowReset : false}
          showTopicResetActivityDialog={this.state.showTopicResetActivityDialog}
          handleOpenResetTopicActivityDialog={
            this.handleOpenResetTopicActivityDialog
          }
          handleCloseResetTopicActivityDialog={
            this.handleCloseResetTopicActivityDialog
          }
          language={this.props.language}
        />
      );
    } else {
      return null;
    }
  }
}

export default connect(mapStateToProps)(SidebarContainer);
