import React, { Fragment } from "react";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";

import "./CommentsPage.css";
import mentionsClass from "./mentions.module.css";
import { TOPIC } from "App/Routes";
import Link from "components/shared/Link/Link";
import Comment from "./Comment";
import AchievementsModal from "components/shared/Achievements/AchievementsModal/AchievementsModal";
import { MentionsInput, Mention } from "react-mentions";
import CharacterCounter from "components/shared/Inputs/CharacterCounter";

import localize from "lang/localize";
import getApiGenerator from "services/getApiGenerator";
import { SEARCH_PROJECT } from "services/api";
import { MENTIONS_LIMIT } from "config";
import Loading from "components/shared/Loading";

import Claim from "components/Project/Activity/Claim";
import ClaimResultContainer from "components/Project/Activity/ClaimResultContainer";
import { getChallengeType } from "services/challengeServices";
import ChallengeInfo from "components/Challenge/ChallengeInfo";

const propTypes = {
  isLoadingMore: PropTypes.bool,
  comments: PropTypes.array,
  more: PropTypes.bool,
  showInput: PropTypes.bool,
  input: PropTypes.string,
  handleMore: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  id: PropTypes.string,
  type: PropTypes.string,
  language: PropTypes.string,
  projectId: PropTypes.number,
  userId: PropTypes.number,
  removeComment: PropTypes.func,
  /* for switching to Achievements modal */
  showAchievements: PropTypes.bool,
  achievements: PropTypes.array,
  setHideAchievements: PropTypes.func,
  setAchievements: PropTypes.func,
  // character limit
  charLimit: PropTypes.number,
  // comment context
  challengeData: PropTypes.object,
  claims: PropTypes.object,
  user: PropTypes.object,
};

const ChallengesCommentsNav = (props) => {
  if (props.type === "quest") {
    return (
      <div className="container verticalpadding">
        <nav className="nav-buttons">
          <Link to={TOPIC.format(props.id)} className="button inactive">
            <span className="capitalize">
              <i className="fas fa-th-list"></i>
              {localize("challenges_novariable_text", props.language)
                .format("")
                .trim()}
            </span>
          </Link>
          <button className="button active">
            <span className="capitalize">
              <i className="fas fa-comment"></i>
              {localize("comments_novariable_text", props.language)
                .format("")
                .trim()}
            </span>
          </button>
        </nav>
      </div>
    );
  } else {
    return null;
  }
};

const Comments = (props) => {
  if (
    props.comments &&
    Array.isArray(props.comments) &&
    props.comments.length > 0
  ) {
    return (
      <div className="topmargin-30">
        {props.comments.map((comment, index) => (
          <Fragment key={comment.id}>
            <Comment
              id={comment.id}
              commentUserName={comment.userName}
              commentUserPhoto={comment.photoSmall}
              commentUserId={comment.userId}
              userId={props.userId}
              userRankStatus={comment.userRankStatus}
              userRankStatusImage={comment.userRankStatusImage}
              message={comment.message}
              messageAttributed={comment.commentMessageAttributed}
              createdAtFormatted={comment.createdAtFormatted}
              projectId={props.projectId}
              removeComment={props.removeComment}
              type={props.type}
              typeId={props.id}
              commentThreadCount={comment.commentThreadCount}
            />
          </Fragment>
        ))}
      </div>
    );
  } else {
    return (
      <p className="topmargin-30 text-align-center">
        {localize("empty_comment_table_text", props.language)}
      </p>
    );
  }
};

const CommentInput = (props) => {
  const [mentionsUserList, setMentionsUserList] = React.useState([]);
  const [storedUserList, setStoredUserList] = React.useState([]);
  const [numberOfMentions, setNumberOfMentions] = React.useState(0);

  const getPlayers = debounce((search, callback) => {
    getApiGenerator(
      SEARCH_PROJECT.format(props.projectId),
      {
        type: "user",
        keywords: search,
      },
      props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          return [];
        }
      } else {
        const formattedPlayers = res.body.data.map((user) => ({
          id: user.id,
          display: user.userName ? user.userName : " ",
          userImage: user.userImage,
        }));
        setMentionsUserList(formattedPlayers);
        callback(formattedPlayers);
      }
    });
  }, 500);

  const onAdd = (search) => {
    const foundUser = mentionsUserList.find(
      (user) => user.id === parseInt(search),
    );
    setStoredUserList([...storedUserList, foundUser]);
  };

  const resetNumberOfMentions = () => {
    setNumberOfMentions(0);
  };

  // checks input against regex to count accurate number of mentions
  const checkInput = (event) => {
    const regexp = /(\[uid:[0-9]+\])/gm;
    let mentionsInInput = event.target.value.match(regexp);
    if (mentionsInInput !== null) {
      setNumberOfMentions(event.target.value.match(regexp).length);
    } else {
      setNumberOfMentions(0);
    }
    props.handleInputChange(event);
  };

  if (!!props.showInput) {
    const transformedInput = props.input.replace(/(\[uid:[0-9]+\])/gm, "");
    return (
      <div id="commentInput" className="bottompadding">
        <form className="pure-form" onSubmit={props.handleSubmit}>
          <div className="pure-u-1 relative">
            <MentionsInput
              value={props.input}
              placeholder={localize("comment_placeholder_text", props.language)}
              onChange={checkInput}
              classNames={mentionsClass}
              allowSpaceInQuery={true}
              a11ySuggestionsListLabel="Suggested mentions"
            >
              <Mention
                trigger="@"
                data={getPlayers}
                renderSuggestion={(suggestion, search, highlightedDisplay) => (
                  <div className="flex align-items-center">
                    <div className="rightmargin-20 suggestion-image-container">
                      <img
                        className="suggestion-image"
                        src={suggestion.userImage}
                        alt="pfp"
                      />
                    </div>
                    {suggestion.display}
                  </div>
                )}
                displayTransform={(id) => {
                  const foundUser = mentionsUserList.find(
                    (user) => user.id === parseInt(id),
                  );
                  const storedUser = storedUserList.find(
                    (user) => user.id === parseInt(id),
                  );
                  return foundUser
                    ? `@${foundUser.display}`
                    : `@${storedUser.display}`;
                }}
                onAdd={onAdd}
                markup="[uid:__id__]"
                className={mentionsClass.mentions__mention}
              />
            </MentionsInput>
            <CharacterCounter charLimit={props.charLimit} input={props.input} />
          </div>
          <div className="pure-u-1">
            {numberOfMentions > MENTIONS_LIMIT && (
              <span className="text-red">
                {localize(
                  "alert_exceeded_mentions_limit",
                  props.language,
                ).format(MENTIONS_LIMIT)}
              </span>
            )}
            <button
              type="submit"
              onClick={resetNumberOfMentions}
              disabled={
                props.input.trim() === "" ||
                numberOfMentions > MENTIONS_LIMIT ||
                (props.charLimit !== 0 &&
                  transformedInput.length > props.charLimit)
              }
              className={
                "button nomargin floatright " +
                (props.input.trim() === "" ||
                numberOfMentions > MENTIONS_LIMIT ||
                (props.charLimit !== 0 &&
                  transformedInput.length > props.charLimit)
                  ? "disabled"
                  : "cta")
              }
            >
              {localize("nav_bar_title_comments_text", props.language)}
            </button>
          </div>
        </form>
      </div>
    );
  } else {
    return null;
  }
};

const CommentsPage = (props) => {
  const claim = props.claims;
  const challenge = props.challengeData;
  return (
    <Fragment>
      <ChallengesCommentsNav
        id={props.id}
        type={props.type}
        language={props.language}
      />
      {challenge && (
        <div>
          <ChallengeInfo
            user={props.user}
            sessionKey={challenge.sessionKey}
            // claimform
            challengeTypeId={challenge.challengeTypeId}
            isConfirmationChallenge={
              challenge.challengeTypeId === 11 &&
              challenge.confirmation === true
            }
            // challengeresult
            id={challenge.id}
            // image
            imageMedium={challenge.imageMedium}
            // embed
            medias={challenge.medias ? challenge.medias : []}
            // text
            footnote={challenge.footnote}
            title={challenge.title}
            repeat={challenge.repeat}
            // stats
            challengeType={getChallengeType(
              challenge.challengeTypeId,
              challenge.photoOnly,
              challenge.multiSelect,
              challenge.challengeType,
            )}
            points={challenge.points}
            // language
            language={props.language}
            // project and topic information
            project={props.game}
            topic={props.quest}
            // context
            context={"comments"}
          />
          <div className="challenge-page-divider" />
        </div>
      )}
      {claim && (
        <div>
          <div className="container toppadding">
            <div className="innerblock">
              <div className="pure-u-1-24" />
              <div className="pure-u-22-24">
                <div className="claimcard-line bottompadding">
                  <Claim
                    key={claim.id}
                    projectId={claim.quest.id}
                    claimUserId={claim.userId}
                    userId={props.userId}
                    userName={claim.userName}
                    userImage={claim.userPhotoSmall}
                    userRankStatus={claim.userRankStatus}
                    userRankStatusImage={claim.userRankStatusImage}
                    createdAtFormatted={claim.createdAtFormatted}
                    points={claim.points}
                    challengeId={claim.challenge.id}
                    challengeTitle={claim.challenge.title}
                    challengeType={getChallengeType(
                      claim.challenge.challengeTypeId,
                      claim.challenge.photoOnly,
                      claim.challenge.multiSelect,
                      claim.challenge.challengeType,
                    )}
                    challengeTypeId={claim.challenge.challengeTypeId}
                    isConfirmationChallenge={
                      claim.challenge.challengeTypeId === 11 &&
                      claim.challenge.confirmation === true
                    }
                    challengePhotoOnly={claim.challenge.photoOnly}
                    correctAnswer={claim.correctAnswer}
                    privateClaim={claim.challenge.privateClaim}
                    language={props.language}
                  />
                  <ClaimResultContainer
                    id={claim.id}
                    claimUserId={claim.userId}
                    userId={props.userId}
                    type={props.type}
                    challengeTypeId={claim.challenge.challengeTypeId}
                    claimImage={claim.claimPhotoSmall}
                    claimVideo={claim.claimVideo}
                    claimVideoContainer={claim.claimVideoContainer}
                    claimVideoThumbnail={
                      claim.claimVideoThumbnail
                        ? claim.claimVideoThumbnail
                        : null
                    }
                    entries={claim.entries}
                    claimMessage={claim.message}
                    privateClaim={claim.challenge.privateClaim}
                    userName={claim.userName}
                    // context
                    context={"comments"}
                  />
                </div>
              </div>
              <div className="pure-u-1-24" />
            </div>
          </div>
          <div className="challenge-page-divider" />
        </div>
      )}
      <div
        className="comments-container container verticalpadding bottompadding-floating-extended"
        id="comments"
      >
        <div className="innerblock">
          <div className="pure-u-1-24" />
          <div className="pure-u-22-24">
            <h5>
              <i className="fa fa-comment rightmargin-10" />
              Comments
            </h5>
            <CommentInput
              projectId={props.projectId}
              sessionKey={props.sessionKey}
              showInput={props.showInput}
              input={props.input}
              handleSubmit={props.handleSubmit}
              handleInputChange={props.handleInputChange}
              language={props.language}
              charLimit={props.charLimit}
            />
            <Comments
              comments={props.comments}
              userId={props.userId}
              projectId={props.projectId}
              removeComment={props.removeComment}
              type={props.type}
              id={props.id}
              language={props.language}
            />
            <AchievementsModal
              showModal={props.showAchievements}
              handleCloseModal={props.setHideAchievements}
              achievements={props.achievements}
            />
            {props.isLoadingMore ? <Loading /> : null}
          </div>
          <div className="pure-u-1-24" />
        </div>
      </div>
    </Fragment>
  );
};

CommentsPage.propTypes = propTypes;

export default CommentsPage;
