import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import NotLoggedIn from "./NotLoggedIn";

export const mapStateToProps = (state, ownProps) => {
  return {
    projectId: state.projectId,
  };
};

const propTypes = {
  showLogin: PropTypes.bool,
  message: PropTypes.string,
  language: PropTypes.string,
  fullHeight: PropTypes.bool,
};

const defaultProps = {
  showLogin: false,
};

export class NotLoggedInContainer extends Component {
  render() {
    return (
      <NotLoggedIn
        showLogin={this.props.showLogin}
        message={this.props.message}
        language={this.props.language}
        projectId={this.props.projectId}
        fullHeight={this.props.fullHeight}
      />
    );
  }
}

NotLoggedInContainer.propTypes = propTypes;
NotLoggedInContainer.defaultProps = defaultProps;

export default connect(mapStateToProps)(NotLoggedInContainer);
