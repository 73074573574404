import React, { Component } from "react";
import { connect } from "react-redux";
import debounce from "lodash.debounce";

import BookmarksPage from "./BookmarksPage";
import Loading from "components/shared/Loading";
import NotLoggedInContainer from "components/shared/NotLoggedIn/NotLoggedInContainer";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";

import getApiGenerator from "services/getApiGenerator";
import { LOAD_MORE_COUNT } from "config";
import { GET_BOOKMARKS, GET_TOPICS } from "services/api";
import { bookmarkChallenge, likeChallenge } from "services/challengeServices";
import listenerServices from "services/listenerServices";
import {
  showAlertWithTimeout,
  addProfileDrawerPoints,
  showAwardableActionWithTimeout,
  showAchievements,
  setProject,
  setButtons,
} from "actions";
import localize from "lang/localize";

export const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    sessionKey: state.sessionKey,
    projectId: state.projectId,
    language: state.language,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showAlertWithTimeout: (alert) => {
      dispatch(showAlertWithTimeout(alert));
    },
    addProfileDrawerPoints: (points) => {
      dispatch(addProfileDrawerPoints(points));
    },
    showAwardableActionWithTimeout: (awardableAction) => {
      dispatch(showAwardableActionWithTimeout(awardableAction));
    },
    showAchievements: (achievements) => {
      dispatch(showAchievements(achievements));
    },
    setProject: (project) => {
      dispatch(setProject(project));
    },
    setButtons: (buttons) => {
      dispatch(setButtons(buttons));
    },
  };
};

export class BookmarksContainer extends Component {
  constructor() {
    super();
    this.state = {
      bookmarks: null,
      more: false,
      page: 1,
      showLoginDialog: false,
      contactEmail: null,
      topics: null,
      error: null,
      code: null,
      isLoadingMore: false,
    };

    this.handleOpenLoginDialog = this.handleOpenLoginDialog.bind(this);
    this.handleCloseLoginDialog = this.handleCloseLoginDialog.bind(this);
    this.getBookmarks = this.getBookmarks.bind(this);
    this.handleMore = this.handleMore.bind(this);
  }

  componentDidMount() {
    if (this.props.sessionKey) {
      this.getBookmarks(this.state.page);
      this.getTopics();
    }
    window.addEventListener("scroll", this.handleMore);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.sessionKey !== this.props.sessionKey) {
      this.getBookmarks(this.state.page);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleMore);
  }

  handleMore = debounce(() => {
    const {
      getBookmarks,
      state: { more, isLoadingMore, page },
    } = this;
    if (!more) {
      return;
    } else if (!isLoadingMore && more) {
      if (listenerServices.isAtScrollThreshold()) {
        this.setState(() => ({
          isLoadingMore: true,
        }));
        getBookmarks(page);
      }
    }
  }, 100);

  getBookmarks(page) {
    getApiGenerator(
      GET_BOOKMARKS.format(this.props.user.id),
      {
        page: page,
        bundle_id: this.props.projectId,
        limit: LOAD_MORE_COUNT,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            bookmarks: [],
            more: false,
            page: 1,
            isLoadingMore: false,
          });
        }
      } else {
        this.setState({
          bookmarks:
            this.state.bookmarks && page > 1
              ? this.state.bookmarks.slice().concat(res.body.data)
              : res.body.data,
          more: res.body.more,
          page: page + 1,
          isLoadingMore: false,
        });
      }
    });
  }

  getTopics() {
    getApiGenerator(
      GET_TOPICS.format(this.props.projectId),
      {
        page: 1,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            topics: [],
            code: 500,
            error: res.body.error,
          });
        }
      } else {
        /*
          Specific to BookmarksContainer, we will need
          the contact email for Challenge reporting
          purposes
        */
        this.setState({
          contactEmail: res.body.game.contactEmail,
          topics: res.body.data,
          code: res.body.code,
          error: "",
        });

        const BUTTONS =
          res.body.buttons && res.body.buttons.length > 0
            ? res.body.buttons
            : null;

        this.props.setProject(res.body.game);
        this.props.setButtons(BUTTONS);
      }
    });
  }

  handleLike = (event, id) => {
    likeChallenge(event, id, this.props.sessionKey).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error) {
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error",
          });
        }
      } else {
        if (res.body.actionPoints && res.body.actionPoints > 0) {
          this.props.addProfileDrawerPoints(res.body.actionPoints);
          this.props.showAwardableActionWithTimeout({
            numberStr: "" + res.body.actionPoints.abbreviateNumber(),
            unit: res.body.actionPoints.localize(
              "point_just_text",
              "points_just_text",
              this.props.language,
              false,
            ),
          });
        }
        if (res.body.items) {
          this.props.showAchievements(res.body.items);
        }
        const bookmarks = this.state.bookmarks.slice();
        const bookmark = bookmarks.filter((bookmark) => bookmark.id === id)[0];
        bookmark.ratedLike = res.body.rated;
        bookmark.likeNo = res.body.likeNo;
        this.setState({
          bookmarks: bookmarks,
        });
      }
    });
  };

  handleBookmark(event, id, isBookmarked) {
    bookmarkChallenge(event, id, isBookmarked, this.props.sessionKey).end(
      (err, res) => {
        if (err || res.body.code !== 200) {
          if (res.body.error) {
            this.props.showAlertWithTimeout({
              text: res.body.error,
              type: "error",
            });
          }
        } else {
          const bookmarks = this.state.bookmarks.slice();
          bookmarks.filter((bookmark) => bookmark.id === id)[0].bookmarked =
            res.body.bookmarked;
          this.setState({
            bookmarks: bookmarks,
          });
        }
      },
    );
  }

  handleOpenLoginDialog() {
    this.setState({
      showLoginDialog: true,
    });
  }

  handleCloseLoginDialog() {
    this.setState({
      showLoginDialog: false,
    });
  }

  render() {
    if (!this.props.user.id) {
      return (
        <NotLoggedInContainer
          language={this.props.language}
          showLogin={false}
          fullHeight={true}
        />
      );
    } else if (
      /* Project ID is available, but Project is private */
      this.props.projectId &&
      this.state.code === 500 &&
      this.state.error &&
      this.state.error.indexOf("private") !== -1
    ) {
      return (
        <GenericErrorPage
          message={localize(
            "bundle_private_logged_in_text",
            this.props.language,
          )}
          language={this.props.language}
        />
      );
    } else if (
      /* Project ID is available, but Project does not exist */
      this.props.projectId &&
      Array.isArray(this.state.topics) &&
      (this.state.error.indexOf("not exist") !== -1 ||
        this.state.error.indexOf("not published") !== -1)
    ) {
      return (
        <GenericErrorPage
          message={localize("bundle_not_found_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else if (this.state.bookmarks && this.state.bookmarks.length !== 0) {
      return (
        <BookmarksPage
          isLoadingMore={this.state.isLoadingMore}
          bookmarks={this.state.bookmarks}
          more={this.state.more}
          handleMore={this.handleMore}
          handleBookmark={this.handleBookmark.bind(this)}
          handleLike={this.handleLike.bind(this)}
          showLoginDialog={this.state.showLoginDialog}
          handleOpenLoginDialog={this.handleOpenLoginDialog}
          handleCloseLoginDialog={this.handleCloseLoginDialog}
          isLoggedIn={!!this.props.user.id}
          language={this.props.language}
          contactEmail={this.state.contactEmail}
        />
      );
    } else if (this.state.bookmarks) {
      return (
        <GenericErrorPage
          message={localize("empty_bookmark_table_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookmarksContainer);
