import React, { Component } from "react";
import PropTypes from "prop-types";

import "@trendmicro/react-buttons/dist/react-buttons.css";
import "@trendmicro/react-dropdown/dist/react-dropdown.css";

import { CHALLENGE } from "App/Routes";
import ClaimForm from "./ClaimForm/ClaimForm";
import ChallengeResult from "./ChallengeResult/ChallengeResult";
import LocationMap from "./LocationMap";
import Link from "components/shared/Link/Link";
import ProgressBar from "components/shared/ProgressBar/ProgressBar";
import "./Challenge.css";
import ChallengeInfo from "./ChallengeInfo";
import ChallengeMeta from "./ChallengeMeta";

import { CONTACT_EMAIL_DEFAULT } from "config";

const propTypes = {
  user: PropTypes.object.isRequired,
  sessionKey: PropTypes.string,
  challengeTypeId: PropTypes.number.isRequired,
  isConfirmationChallenge: PropTypes.bool,
  photoRequired: PropTypes.bool,
  noPhoto: PropTypes.bool,
  quizOptions: PropTypes.array,
  privateClaim: PropTypes.bool,
  fields: PropTypes.array,
  completionMessage: PropTypes.string,
  multiSelect: PropTypes.bool,
  claimed: PropTypes.bool,
  claimedBefore: PropTypes.bool,
  expired: PropTypes.bool.isRequired,
  submitted: PropTypes.bool.isRequired,
  videoDurationLimit: PropTypes.number,
  gridWidth: PropTypes.number,
  // location
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  addressFormatted: PropTypes.string,
  // challengeresult
  id: PropTypes.number.isRequired,
  correctAnswerNo: PropTypes.number,
  wrongAnswerNo: PropTypes.number,
  correctAnswerTitle: PropTypes.string,
  correctAnswer: PropTypes.bool,
  // image
  imageMedium: PropTypes.string,
  // embed
  medias: PropTypes.array,
  // text
  footnote: PropTypes.string,
  title: PropTypes.string,
  repeat: PropTypes.bool,
  repeatUntilCorrect: PropTypes.bool,
  repeatAtFormatted: PropTypes.string,
  videoDurationLimitFormatted: PropTypes.string,
  // stats
  commentNo: PropTypes.number,
  claimNo: PropTypes.number,
  previousChallengeId: PropTypes.number,
  nextChallengeId: PropTypes.number,
  challengeType: PropTypes.string.isRequired,
  points: PropTypes.number.isRequired,
  challengeReferralLink: PropTypes.string,
  isLiked: PropTypes.bool.isRequired,
  likeNo: PropTypes.number.isRequired,
  isBookmarked: PropTypes.bool.isRequired,
  handleBookmark: PropTypes.func.isRequired,
  handleLike: PropTypes.func.isRequired,
  postClaim: PropTypes.func.isRequired,
  postQRClaim: PropTypes.func.isRequired,
  showAlertWithTimeout: PropTypes.func,
  adminToClaim: PropTypes.bool.isRequired,
  // login dialogs
  showLoginDialog: PropTypes.bool.isRequired,
  handleOpenLoginDialog: PropTypes.func.isRequired,
  handleCloseLoginDialog: PropTypes.func.isRequired,
  // image dialogs
  showImageDialog: PropTypes.bool.isRequired,
  handleOpenImageDialog: PropTypes.func.isRequired,
  handleCloseImageDialog: PropTypes.func.isRequired,
  // embed dialogs
  showEmbedDialog: PropTypes.bool.isRequired,
  handleOpenEmbedDialog: PropTypes.func.isRequired,
  handleCloseEmbedDialog: PropTypes.func.isRequired,
  // qr dialogs
  showQRModal: PropTypes.bool,
  handleOpenQRModal: PropTypes.func,
  handleCloseQRModal: PropTypes.func,
  // minigame dialogs
  showExternalChallengeDialog: PropTypes.bool,
  handleOpenExternalChallengeDialog: PropTypes.func,
  handleCloseExternalChallengeDialog: PropTypes.func,
  // language
  language: PropTypes.string,
  // contact email
  contactEmail: PropTypes.string,
  // locked content handling
  locked: PropTypes.bool,
  lockedChallengeTopicId: PropTypes.number,
  lockedChallengeTopicTitle: PropTypes.string,
  // project and topic information
  project: PropTypes.object,
  topic: PropTypes.object,
  topicIdNext: PropTypes.number,
  // minigame
  challengeMinigameTitle: PropTypes.string,
  // challenge time tracking
  challengeMinViewDuration: PropTypes.number,
  // character limit
  charLimit: PropTypes.number,
};

const defaultProps = {
  contactEmail: CONTACT_EMAIL_DEFAULT,
};

class ChallengePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 1,
    };
    this.sliderRef = React.createRef();
  }

  getFirstEmbeddableItem() {
    return this.props.medias.filter((media) => media.source === "Embed")[0];
  }

  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    let bodyDOM = document.body; // <body> tag

    // Set page identifier class to body DOM
    if (!bodyDOM.classList.contains("challengePage")) {
      bodyDOM.className += " challengePage";
    }

    // Add other page classes to body DOM
    if (!bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.className += " page-loggedin";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    let bodyDOM = document.body; // <body> tag

    // Remove page identifier class from body DOM
    if (bodyDOM.classList.contains("challengePage")) {
      bodyDOM.classList.remove("challengePage");
    }

    // Remove other page classes from body DOM
    if (bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.classList.remove("page-loggedin");
    }
  }

  /**
   * Render challenge navigation buttons (previous/next challenge)
   */
  renderChallengeNavigationButtons() {
    return (
      <div className="position-sticky-offset-topbar">
        {/* Challenge navigation (prev/next challenge) on desktop views */}
        <div className="hide-below-lg">
          <div className="challenge-nav-desktop-wrap">
            {this.renderPreviousChallengeButton()}
            {this.renderNextChallengeButton()}
          </div>
        </div>

        {/* Challenge navigation (prev/next challenge) on mobile views */}
        {this.renderChallengeNavigationButtonsForMobile()}
      </div>
    );
  }

  /**
   * Render challenge navigation buttons for mobile view (previous/next challenge)
   */
  renderChallengeNavigationButtonsForMobile() {
    if (this.props.nextChallengeId) {
      return (
        <div className="pure-u-lg-0 horizontalpadding-5">
          <div className="pure-g">
            <div className="pure-u-2-24 textcenter">
              {this.renderPreviousChallengeButton()}
            </div>
            <div className="pure-u-20-24 box-sizing-border-box-all verticalpadding-5 horizontalpadding-10 progress-bar-height">
              {this.renderTopicProgressBarForChallenge()}
            </div>
            <div className="pure-u-2-24 textcenter">
              {this.renderNextChallengeButton()}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="pure-u-lg-0 horizontalpadding-5">
          <div className="pure-g">
            <div className="pure-u-2-24 textcenter">
              {this.renderPreviousChallengeButton()}
            </div>
            <div className="pure-u-22-24 box-sizing-border-box-all verticalpadding-5 horizontalpadding-10 progress-bar-height">
              {this.renderTopicProgressBarForChallenge()}
            </div>
          </div>
        </div>
      );
    }
  }

  /**
   * Render button to navigate to previous challenge
   */
  renderPreviousChallengeButton() {
    /* Previous Challenge exists, and previous Challenge is not locked */
    if (this.props.previousChallengeId) {
      return (
        <Link
          className="challenge-nav challenge-nav-prev"
          to={CHALLENGE.format(this.props.previousChallengeId)}
        >
          <i className="fas fa-chevron-circle-left"></i>
        </Link>
      );
    } else {
      /* Previous Challenge does not exist */
      return null;
    }
  }

  /**
   * Render button to navigate to next challenge
   */
  renderNextChallengeButton() {
    /* Next Challenge exists, and next Challenge is not locked */
    if (this.props.nextChallengeId) {
      return (
        <Link
          className="challenge-nav challenge-nav-next"
          to={CHALLENGE.format(this.props.nextChallengeId)}
        >
          <i className="fas fa-chevron-circle-right"></i>
        </Link>
      );
    } else {
      /* Next Challenge does not exist */
      return null;
    }
  }

  /**
   * Render topic progress bar
   */
  renderTopicProgressBarForChallenge() {
    if (this.props.topic) {
      return (
        <ProgressBar
          id="progressbar"
          className="bottommargin boxshadow"
          completed={this.props.topic.userChallengeCompletedNo}
          total={this.props.topic.challengeNo}
          language={this.props.language}
        />
      );
    } else {
      return null;
    }
  }

  /**
   * Render view
   */
  render() {
    return (
      <div className="challenge">
        {this.renderChallengeNavigationButtons()}

        <div className="container verticalpadding">
          <div className="pure-g innerblock display-block">
            <div className="pure-u-sm-1-24" />
            <div className="pure-u-sm-22-24">
              <ChallengeInfo
                user={this.props.user}
                sessionKey={this.props.sessionKey}
                // claimform
                challengeTypeId={this.props.challengeTypeId}
                isConfirmationChallenge={this.props.isConfirmationChallenge}
                videoDurationLimit={this.props.videoDurationLimit}
                // challengeresult
                id={this.props.id}
                // image
                imageMedium={this.props.imageMedium}
                // embed
                medias={this.props.medias ? this.props.medias : []}
                // text
                footnote={this.props.footnote}
                title={this.props.title}
                repeat={this.props.repeat}
                repeatUntilCorrect={!!this.props.repeatUntilCorrect}
                repeatAtFormatted={this.props.repeatAtFormatted}
                videoDurationLimitFormatted={
                  this.props.videoDurationLimitFormatted
                }
                // stats
                commentNo={this.props.commentNo}
                claimNo={this.props.claimNo}
                challengeType={this.props.challengeType}
                points={this.props.points}
                challengeReferralLink={this.props.challengeReferralLink}
                isLiked={this.props.isLiked}
                likeNo={this.props.likeNo}
                isBookmarked={this.props.isBookmarked}
                handleBookmark={this.props.handleBookmark}
                handleLike={this.props.handleLike}
                // login dialogs
                showLoginDialog={this.props.showLoginDialog}
                handleOpenLoginDialog={this.props.handleOpenLoginDialog}
                handleCloseLoginDialog={this.props.handleCloseLoginDialog}
                // image dialogs
                showImageDialog={this.props.showImageDialog}
                handleOpenImageDialog={this.props.handleOpenImageDialog}
                handleCloseImageDialog={this.props.handleCloseImageDialog}
                // embed dialogs
                showEmbedDialog={this.props.showEmbedDialog}
                handleOpenEmbedDialog={this.props.handleOpenEmbedDialog}
                handleCloseEmbedDialog={this.props.handleCloseEmbedDialog}
                // language
                language={this.props.language}
                // contact email
                contactEmail={this.props.contactEmail}
                // locked content handling
                locked={this.props.locked}
                lockedChallengeTopicId={this.props.lockedChallengeTopicId}
                lockedChallengeTopicTitle={this.props.lockedChallengeTopicTitle}
                // project and topic information
                project={this.props.project}
                topic={this.props.topic}
                // minigame
                challengeMinigameTitle={this.props.challengeMinigameTitle}
              />

              <ClaimForm
                sessionKey={this.props.sessionKey}
                isLoggedIn={!!this.props.user.id}
                challengeTypeId={this.props.challengeTypeId}
                challengeId={this.props.id}
                title={this.props.title}
                isConfirmationChallenge={this.props.isConfirmationChallenge}
                photoRequired={this.props.photoOnly}
                noPhoto={this.props.noPhoto}
                quizOptions={this.props.quizOptions}
                fields={this.props.fields}
                completionMessage={this.props.completionMessage}
                multiSelect={this.props.multiSelect}
                claimed={this.props.claimed}
                claimedBefore={this.props.claimedBefore}
                postClaim={this.props.postClaim}
                postQRClaim={this.props.postQRClaim}
                gridWidth={this.props.gridWidth}
                showAlertWithTimeout={this.props.showAlertWithTimeout}
                adminToClaim={this.props.adminToClaim}
                expired={this.props.expired}
                submitted={this.props.submitted}
                videoDurationLimit={this.props.videoDurationLimit}
                videoDurationLimitFormatted={
                  this.props.videoDurationLimitFormatted
                }
                externalEmbeddable={this.getFirstEmbeddableItem()}
                handleOpenLoginDialog={this.props.handleOpenLoginDialog}
                showQRModal={this.props.showQRModal}
                handleOpenQRModal={this.props.handleOpenQRModal}
                handleCloseQRModal={this.props.handleCloseQRModal}
                showExternalChallengeDialog={
                  this.props.showExternalChallengeDialog
                }
                handleOpenExternalChallengeDialog={
                  this.props.handleOpenExternalChallengeDialog
                }
                handleCloseExternalChallengeDialog={
                  this.props.handleCloseExternalChallengeDialog
                }
                challengeMinigameTitle={this.props.challengeMinigameTitle}
                challengeMinViewDuration={this.props.challengeMinViewDuration}
                isTimerActive={this.props.isTimerActive}
                setTimerActive={this.props.setTimerActive}
                countdownTime={this.props.countdownTime}
                language={this.props.language}
                charLimit={this.props.charLimit}
                repeat={this.props.repeat}
                repeatAtFormatted={this.props.repeatAtFormatted}
                repeatUntilCorrect={this.props.repeatUntilCorrect}
                correctAnswer={this.props.correctAnswer}
                correctAnswerTitle={this.props.correctAnswerTitle}
                nextChallengeId={this.props.nextChallengeId}
                nextTopicId={this.props.topicIdNext}
              />

              <ChallengeMeta
                user={this.props.user}
                sessionKey={this.props.sessionKey}
                challengeTypeId={this.props.challengeTypeId}
                // challengeresult
                id={this.props.id}
                // stats
                commentNo={this.props.commentNo}
                claimNo={this.props.claimNo}
                challengeType={this.props.challengeType}
                points={this.props.points}
                challengeReferralLink={this.props.challengeReferralLink}
                isLiked={this.props.isLiked}
                likeNo={this.props.likeNo}
                isBookmarked={this.props.isBookmarked}
                handleBookmark={this.props.handleBookmark}
                handleLike={this.props.handleLike}
                // login dialogs
                showLoginDialog={this.props.showLoginDialog}
                handleOpenLoginDialog={this.props.handleOpenLoginDialog}
                handleCloseLoginDialog={this.props.handleCloseLoginDialog}
                // embed dialogs
                showEmbedDialog={this.props.showEmbedDialog}
                handleOpenEmbedDialog={this.props.handleOpenEmbedDialog}
                handleCloseEmbedDialog={this.props.handleCloseEmbedDialog}
                // language
                language={this.props.language}
                // contact email
                contactEmail={this.props.contactEmail}
                // locked content handling
                locked={this.props.locked}
              />
            </div>
            <div className="pure-u-sm-1-24" />
          </div>
        </div>

        <LocationMap
          latitude={this.props.latitude}
          longitude={this.props.longitude}
          addressFormatted={this.props.addressFormatted}
          language={this.props.language}
        />
        <ChallengeResult
          id={this.props.id}
          sessionKey={this.props.sessionKey}
          userName={this.props.user.name}
          userPhoto={this.props.user.photoSmall}
          challengeTypeId={this.props.challengeTypeId}
          claimed={!!this.props.claimed}
          claimedBefore={!!this.props.claimedBefore}
          repeatUntilCorrect={this.props.repeatUntilCorrect}
          correctAnswerNo={this.props.correctAnswerNo}
          wrongAnswerNo={this.props.wrongAnswerNo}
          correctAnswerTitle={this.props.correctAnswerTitle}
          correctAnswer={this.props.correctAnswer}
          quizOptions={this.props.quizOptions}
          gridWidth={this.props.gridWidth}
          privateClaim={this.props.privateClaim}
          language={this.props.language}
        />
        {/* Added padding for floating buttons */}
        <div className="container bottompadding-floating" />
      </div>
    );
  }
}

ChallengePage.propTypes = propTypes;
ChallengePage.defaultProps = defaultProps;

export default ChallengePage;
