import React, { Component } from "react";
import { connect } from "react-redux";

import "./Tour.css";
import { SECRET_KEY } from "config";
import { HOME, LOGIN } from "App/Routes";
import TourPage from "./TourPage";

import { setProject } from "actions";
import { TOUR_SCREENS } from "services/api";
import Router from "router";
import pushApiGenerator from "services/pushApiGenerator";
import sessionStorageService from "services/sessionStorageService";

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    language: state.language,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setProject: (project) => {
      dispatch(setProject(project));
    },
  };
};

export class TourContainer extends Component {
  /**
   * Constructor - called before the component is mounted
   * @param {} props
   */
  constructor(props) {
    super(props);
    this.state = {
      redirectUrl:
        this.props.sessionKey !== null && this.props.sessionKey !== ""
          ? HOME
          : LOGIN,
      completedTour: false,
      lastSlide: false,
      tourScreens: null,
      apiCallComplete: false,
    };

    this.getTourScreens = this.getTourScreens.bind(this);
    this.setCompletedTour = this.setCompletedTour.bind(this);
    this.setSlide = this.setSlide.bind(this);
  }

  /**
   * Invoked immediately after this component is mounted
   */
  componentDidMount() {
    // loads loginRedirectUrl from sessionStorage (if available)
    // eventually loginRedirectUrl will be dumped when new page is loaded
    // (not REGISTER nor LOGIN nor RESET_PASSWORD)
    // for the aforementioned dumping, see SingleProjectApp.js
    let loginRedirectUrl = sessionStorageService.getItem("loginRedirectUrl");

    if (loginRedirectUrl) {
      this.setState({ redirectUrl: loginRedirectUrl });
    }

    this.getTourScreens();
  }

  /**
   * Informs React if this component’s output is not affected by the current change in state or props
   * @param {*} nextProps
   * @param {*} nextState
   * @returns
   */
  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.completedTour !== nextState.completedTour) {
      return true;
    }
    if (this.state.lastSlide !== nextState.lastSlide) {
      return true;
    }

    if (this.state.tourScreens !== nextState.tourScreens) {
      return true;
    } else if (this.state.apiCallComplete !== nextState.apiCallComplete) {
      return true;
    }

    return false;
  }

  /**
   * Retrieve tour screens from API
   */
  getTourScreens() {
    pushApiGenerator(
      TOUR_SCREENS,
      {
        // API key is sent twice as input parameter is different in API2
        // TODO: Change to API2
        api_key: SECRET_KEY,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      /* API success */
      if (!(err || res.body.code !== 200)) {
        this.setState({
          apiCallComplete: true,
          tourScreens: res.body.data,
        });
      } else {
        this.setState({
          apiCallComplete: true,
        });
      }
    });
  }

  /**
   * Handles the event when the user clicks the close button
   * @param {Event} e
   */
  handleCloseClick = (e) => {
    if (e != null) {
      e.preventDefault();
    }

    Router.replaceAndNavigate(this.state.redirectUrl);
  };

  /**
   * Update state: Set if user has completed the tour
   * @param {boolean} completed
   */
  setCompletedTour(completed) {
    this.setState({ completedTour: completed });
  }

  /**
   * Update state: Set if user is viewing the last slide
   * @param {boolean} completed
   */
  setSlide(isLast) {
    this.setState({ lastSlide: isLast });
  }

  /**
   * Update state: Set if API call is complete
   * @param {boolean} completed
   */
  setApiCallComplete(completed) {
    this.setState({ apiCallComplete: completed });
  }

  /**
   * Render the tour component
   */
  render() {
    return (
      <TourPage
        redirectUrl={this.state.redirectUrl}
        completedTour={this.state.completedTour}
        lastSlide={this.state.lastSlide}
        tourScreens={this.state.tourScreens}
        handleCloseClick={this.handleCloseClick}
        setCompletedTour={this.setCompletedTour}
        setSlide={this.setSlide}
        apiCallComplete={this.state.apiCallComplete}
        language={this.props.language}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TourContainer);
