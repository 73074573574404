import React, { Component } from "react";
import PropTypes from "prop-types";

import Notification from "./Notification";

import {
  CLAIM,
  CHALLENGE,
  PROJECT_USER,
  USER,
  PROJECT_PROFILE,
  PROFILE,
  PROJECT_TEAM,
  TOPIC_COMMENTS_THREAD,
  CHALLENGE_COMMENTS_THREAD,
  CLAIM_COMMENTS_THREAD,
  TOPIC_COMMENTS,
  CHALLENGE_COMMENTS,
  CLAIM_COMMENTS,
  TOPIC,
} from "App/Routes";
import Loading from "components/shared/Loading";
// import localize from "lang/localize";

const propTypes = {
  notifications: PropTypes.array.isRequired,
  more: PropTypes.bool.isRequired,
  handleMore: PropTypes.func.isRequired,
  language: PropTypes.string,
  projectId: PropTypes.number,
  userId: PropTypes.number,
  isLoadingMore: PropTypes.bool,
};

class NotificationsPage extends Component {
  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    let bodyDOM = document.body; // <body> tag

    // Set page identifier class to body DOM
    if (!bodyDOM.classList.contains("notificationsPage")) {
      bodyDOM.className += " notificationsPage";
    }

    // Add other page classes to body DOM
    if (!bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.className += " page-loggedin";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    let bodyDOM = document.body; // <body> tag

    // Remove page identifier class from body DOM
    if (bodyDOM.classList.contains("notificationsPage")) {
      bodyDOM.classList.remove("notificationsPage");
    }

    // Remove other page classes from body DOM
    if (bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.classList.remove("page-loggedin");
    }
  }

  // Due to legacy API limitations, notifications can have differing content but the same type
  // This function is to check if URL we return in getURL should be to the content comment page or profile page
  // Returns true for comment page, false for profile page
  checkRoute(content) {
    const contentLower = content ? content.toLowerCase() : "";

    if (
      contentLower.includes("comment") ||
      contentLower.includes("reply") ||
      contentLower.includes("replied")
    ) {
      return true;
    } else {
      return false;
    }
  }

  getURL(type, id, contextId, content) {
    switch (type) {
      // legacy notifications
      case "claim": {
        return this.checkRoute(content)
          ? CLAIM_COMMENTS.format(id)
          : CLAIM.format(id);
      }
      case "challenge": {
        return this.checkRoute(content)
          ? CHALLENGE_COMMENTS.format(id)
          : CHALLENGE.format(id);
      }
      case "game": {
        return this.checkRoute(content)
          ? TOPIC_COMMENTS.format(id)
          : TOPIC.format(id);
      }
      case "user":
        if (this.props.userId === id && this.props.projectId) {
          return PROJECT_PROFILE.format(this.props.projectId);
        } else if (this.props.userId === id) {
          return PROFILE;
        } else if (this.props.projectId) {
          return PROJECT_USER.format(this.props.projectId, id);
        } else {
          return USER.format(id);
        }
      case "team":
        return PROJECT_TEAM.format(this.props.projectId, id);

      // modern notifications
      case "thread_topic":
        return TOPIC_COMMENTS_THREAD.format(contextId, id);
      case "thread_challenge":
        return CHALLENGE_COMMENTS_THREAD.format(contextId, id);
      case "thread_completion":
        return CLAIM_COMMENTS_THREAD.format(contextId, id);
      case "comment_topic":
        return TOPIC_COMMENTS.format(id);
      case "comment_challenge":
        return CHALLENGE_COMMENTS.format(id);
      case "comment_completion":
        return CLAIM_COMMENTS.format(id);
      default:
        return null;
    }
  }

  renderNotifcations() {
    const notifications = this.props.notifications;
    return notifications.map((notification, index) => (
      <Notification
        key={index} // can't use id cause some have same id
        id={notification.id}
        title={notification.content}
        img={notification.photoSmall}
        time={notification.createdAtFormatted}
        type={notification.type}
        link={this.getURL(
          notification.type,
          notification.id,
          notification.contextId,
          notification.content,
        )}
        bold={!notification.read}
      />
    ));
  }

  render() {
    return (
      <div className="container verticalpadding bottompadding-floating">
        <div className="innerblock pure-g">
          <div className="pure-u-1-1 pure-u-md-1-6" />
          <div className="pure-u-1-1 pure-u-md-2-3">
            {this.renderNotifcations()}
            {this.props.isLoadingMore && <Loading />}
          </div>
          <div className="pure-u-1-1 pure-u-md-1-6" />
        </div>
      </div>
    );
  }
}

NotificationsPage.propTypes = propTypes;

export default NotificationsPage;
