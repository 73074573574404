import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import CertificatesPage from "./CertificatesPage";
import Loading from "components/shared/Loading";
import NotLoggedInContainer from "components/shared/NotLoggedIn/NotLoggedInContainer";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";

import { setProject, setButtons } from "actions";
import { GET_USER_CERTIFICATES, GET_USER } from "services/api";
import { USER, PROJECT_USER } from "App/Routes";
import getApiGenerator2 from "services/getApiGenerator2";
import getApiGenerator from "services/getApiGenerator";
import localize from "lang/localize";

export const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    sessionKey: state.sessionKey,
    projectId: state.projectId,
    language: state.language,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setProject: (project) => {
      dispatch(setProject(project));
    },
    setButtons: (buttons) => {
      dispatch(setButtons(buttons));
    },
  };
};

const propTypes = {
  id: PropTypes.string,
};

const defaultProps = {
  id: null,
};

export class CertificatesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      certificates: null,
      user: null,
      ownProfile: !props.id || props.user.id === parseInt(props.id, 10),
      id: props.id || props.user.id,
      topics: null,
      currentBoard: "individual",
      error: null,
      code: null,
    };
  }

  getPageData() {
    this.getProjectCertificates();
    this.getUserProfile();
  }

  componentDidMount() {
    if (!this.state.ownProfile || this.props.sessionKey) {
      this.getPageData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.sessionKey !== this.props.sessionKey) {
      this.getPageData();
    }
  }

  getProjectCertificates() {
    getApiGenerator2(
      GET_USER_CERTIFICATES.format(this.props.user.id),
      {
        project_id: this.props.projectId,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            certificates: [],
            certificatesLoading: false,
          });
        }
      } else {
        this.setState({
          certificates: res.body.data,
          certificatesLoading: false,
        });
      }
    });
  }

  /**
   * Calls the user profile API to retrieve user information
   */
  getUserProfile() {
    getApiGenerator(
      GET_USER.format(this.state.id),
      {
        bundle_id: this.props.projectId,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            user: false,
          });
        }
      } else {
        this.setState({
          user: res.body,
        });
      }
    });
  }

  /**
   * Get profile link
   */
  getProfileLink() {
    if (this.props.projectId) {
      return PROJECT_USER.format(this.props.projectId, this.state.id);
    } else {
      return USER.format(this.state.id);
    }
  }

  handleToggleBoard = (event) => {
    this.setState({ currentBoard: event.currentTarget.id });
  };

  render() {
    const { currentBoard, certificates, user } = this.state;

    if (this.state.ownProfile && !this.props.user.id) {
      return (
        <NotLoggedInContainer
          showLogin={false}
          language={this.props.language}
          fullHeight={true}
        />
      );
    } else if (
      /* Project ID is available, but Project is private */
      this.props.projectId &&
      this.state.code === 500 &&
      this.state.error &&
      this.state.error.indexOf("private") !== -1
    ) {
      return (
        <GenericErrorPage
          message={localize(
            "bundle_private_logged_in_text",
            this.props.language,
          )}
          language={this.props.language}
        />
      );
    } else if (
      /* Project ID is available, but Project does not exist */
      this.props.projectId &&
      Array.isArray(this.state.topics) &&
      (this.state.error.indexOf("not exist") !== -1 ||
        this.state.error.indexOf("not published") !== -1)
    ) {
      return (
        <GenericErrorPage
          message={localize("bundle_not_found_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else if (certificates && certificates.length !== 0 && user) {
      return (
        <CertificatesPage
          certificates={this.state.certificates ? this.state.certificates : []}
          language={this.props.language}
          projectId={this.props.projectId}
          board={currentBoard}
          profileLink={this.getProfileLink()}
          user={this.state.user}
          handleToggleBoard={this.handleToggleBoard}
          sessionKey={this.props.sessionKey}
        />
      );
    } else if (this.state.certificates) {
      return (
        <GenericErrorPage
          message={localize("empty_table_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }
}

CertificatesContainer.propTypes = propTypes;
CertificatesContainer.defaultProps = defaultProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CertificatesContainer);
