import React, { Component } from "react";
import PropTypes from "prop-types";
import Achievement from "./Achievement";
import Link from "components/shared/Link/Link";

import localize from "lang/localize";

const propTypes = {
  items: PropTypes.array.isRequired,
  language: PropTypes.string,
  projectId: PropTypes.number,
  board: PropTypes.oneOf(["individual", "team"]).isRequired,
  teamAvailable: PropTypes.bool.isRequired,
  profileLink: PropTypes.string,
  user: PropTypes.object,
  handleToggleBoard: PropTypes.func.isRequired,
};

class AchievementsPage extends Component {
  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    let bodyDOM = document.body; // <body> tag

    // Set page identifier class to body DOM
    if (!bodyDOM.classList.contains("achievementsPage")) {
      bodyDOM.className += " achievementsPage";
    }

    // Add other page classes to body DOM
    if (!bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.className += " page-loggedin";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    let bodyDOM = document.body; // <body> tag

    // Remove page identifier class from body DOM
    if (bodyDOM.classList.contains("achievementsPage")) {
      bodyDOM.classList.remove("achievementsPage");
    }

    // Remove other page classes from body DOM
    if (bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.classList.remove("page-loggedin");
    }
  }

  renderBoardNav() {
    return (
      <div className="container toppadding-10 bottompadding-10">
        <nav className="nav-buttons">
          <button
            id="team"
            className={
              "button " + (this.props.board === "team" ? "active" : "inactive")
            }
            onClick={this.props.handleToggleBoard}
          >
            <span>
              <i className="fas fa-users"></i>
              {localize("icon_leaderboard_mode_team", this.props.language)}
            </span>
          </button>
          <button
            id="individual"
            className={
              "button " +
              (this.props.board === "individual" ? "active" : "inactive")
            }
            onClick={this.props.handleToggleBoard}
          >
            <span>
              <i className="fas fa-user"></i>
              {localize("icon_leaderboard_mode_user", this.props.language)}
            </span>
          </button>
        </nav>
      </div>
    );
  }

  renderItems() {
    const items = this.props.items;
    return items.map((item, index) => (
      <Achievement
        key={item.id}
        id={item.id}
        title={item.name}
        description={
          item.earned ? item.descriptionAfter : item.descriptionBefore
        }
        img={item.imageSmall}
        earned={item.earned}
        language={this.props.language}
        projectId={this.props.projectId}
      />
    ));
  }

  renderProfileHeader() {
    if (this.props.user) {
      return (
        <Link to={this.props.profileLink}>
          <div className="container verticalpadding darkbg">
            <div className="innerblock">
              <div className="pure-g profile-container flex align-items-center">
                <div className="pure-u-2-24">
                  <span className="hide-below-md fas fa-chevron-left font-size-lg"></span>
                  <span className="hide-from-md fas fa-chevron-left"></span>
                </div>
                <div className="pure-u-md-1-24 pure-u-2-24">
                  <span className="square-image-wrapper">
                    <span className="square-image circle border-gray-light">
                      <img
                        src={this.props.user.photoLarge}
                        alt={this.props.user.name}
                      />
                    </span>
                  </span>
                  {this.props.user.userRankStatusImage && (
                    <div id="rankStatus" className="photo-icon-wrap">
                      <img
                        className="rank-status"
                        src={this.props.user.userRankStatusImage}
                        alt={this.props.user.userRankStatus}
                      />
                    </div>
                  )}
                </div>
                <div className="leftmargin horizontalpadding relative">
                  <h5 className="automargin">{this.props.user.name}</h5>
                </div>
              </div>
            </div>
          </div>
        </Link>
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        {/*
        If teams are available, show Team/Player as tabs.
        Otherwise, show Time (All-Time / Monthly / Daily).
      */}
        {this.renderProfileHeader()}
        {this.props.teamAvailable && this.renderBoardNav()}
        <div className="container verticalpadding bottompadding-floating">
          <div className="innerblock pure-g">
            <div className="pure-u-1 automargin cardlist">
              {this.renderItems()}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

AchievementsPage.propTypes = propTypes;

export default AchievementsPage;
