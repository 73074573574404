import React, { Component } from "react";
import PropTypes from "prop-types";

import { RECOVER_PASSWORD_PAGE_LINK } from "config";
import deviceServices from "services/deviceServices";
import urlServices from "services/urlServices";
import localize from "lang/localize";

const propTypes = {
  language: PropTypes.string,
};

class ResetPasswordPage extends Component {
  render() {
    return (
      <div
        className={
          "fullscreen-frame-wrap" +
          (deviceServices.isIOS() ? " iframe-scroll-wrapper" : "")
        }
      >
        <iframe
          src={
            RECOVER_PASSWORD_PAGE_LINK +
            urlServices.getEmbedLangParam(this.props.language, false)
          }
          title={localize(
            "login_recover_password_web_view_title",
            this.props.language,
          )}
          frameBorder="0"
          className="fullscreen-frame"
        />
      </div>
    );
  }
}

ResetPasswordPage.propTypes = propTypes;

export default ResetPasswordPage;
