import React, { Component } from "react";
import PropTypes from "prop-types";

import { TEAM_CREATE_PAGE_LINK } from "config";
import deviceServices from "services/deviceServices";
import PostIframe from "../../../shared/PostIframe/PostIframe";

import localize from "lang/localize";

const propTypes = {
  sessionKey: PropTypes.string.isRequired,
  projectId: PropTypes.number,
  language: PropTypes.string,
};

class TeamCreatePage extends Component {
  render() {
    /*
      Because Settings page's topbar uses a regular history.go(-1),
      we need a framing method such that the frames will not affect
      the browser history. Thus, <object> will be better than <iframe>.
      See: https://stackoverflow.com/questions/924946/use-of-iframe-or-object-tag-to-embed-web-pages-in-another/32854398#32854398
    */
    return (
      <div
        className={
          "fullscreen-frame-wrap" +
          (deviceServices.isIOS() ? " iframe-scroll-wrapper" : "")
        }
      >
        <PostIframe
          title={localize("new_team_text", this.props.language)}
          hiddenFields={{
            session_key: this.props.sessionKey,
            project_id: this.props.projectId,
            lang: this.props.language,
          }}
          url={TEAM_CREATE_PAGE_LINK}
        />
      </div>
    );
  }
}

TeamCreatePage.propTypes = propTypes;

export default TeamCreatePage;
